import React, { useContext } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

import {
    Modal,
} from 'components';
import { AuthContext, HomeContext, OrganizationContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const ConfirmCancelRequest = ({ open = false, onCancel, onSubmit }) => {
    const [t] = useTranslation();
    const { userInfo } = useContext(AuthContext);
    const { setNotificationMessage } = useContext(HomeContext);
    const { organization, doCancelJoinRequest, doGetOrganization } = useContext(OrganizationContext);
    const handleCancelRequest = () => {
        doCancelJoinRequest(organization._id, userInfo._id, () => {
            
            onCancel();
            doGetOrganization(organization._id);
            setNotificationMessage(`<p> ${t("organization.message_cancel_request")}</p>`)
        }, false)
    }
    return (
        <Modal
            open={open}
            className="tr__cancel-request__organization"
            onCancel={onCancel}
            title={t("organization.cancel_join_request")}
            btnClasses="justify-content-between"
            submitButton
            submitButtonLabel={t("member.yes")}
            submitButtonClassNames="w-100"
            onSubmitClick={handleCancelRequest}
            cancelButton={true}
            cancelButtonLabel={t("member.no")}
            cancelButtonType="outline"
            cancelButtonClassNames="outline secondary w-100"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">{t("organization.notification_cancel_request")}</h6>
            </div>
        </Modal>
    )
};

ConfirmCancelRequest.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
};