import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import {
    Modal,
    Table,
    DocumentHeader,
    Checkbox,
    TableFooter,
    Tooltip
} from 'components';
import moment from 'moment';
import { DocumentsContext, HomeContext, ModalContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import { BC, DEFAULT, DOCUMENT_PAGE_SIZE, GET_FILE, HBL, INVOICE, MBL, OTHER, PERMISSIONS_LIST } from 'actions';
import { useTranslation } from 'react-i18next';
export const ViewAllDocuments = ({
    open = false,
    onCancel,
    onSubmit = () => { },
}) => {
    const [t] = useTranslation();
    const { taskId, setFileId, isArchive, setIsDocumentTab, typeDocumentTask } = useContext(HomeContext);
    const { setViewExtractedInfo } = useContext(ModalContext);
    const {
        documentsOfTask,
        doRemoveDocumentsTask,
        doRemoveDocumentsDefault,
        doGetDocuments,
        setDocuments,
        setDocumentPermission,
        doGenerateTradeTrust,
        doGetTradeTrust,
        setDocumentsOfTask,
        functionsTask,
    } = useContext(DocumentsContext);
    const [docList, setDocList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 2];

    // console.log(typeDocumentTask, 'documentsOfTask');

    /*eslint-disable */
    useEffect(() => {
        if (documentsOfTask && docList) {
            setSelectAll(!(docList.length < documentsOfTask.length));
        }
        if (documentsOfTask && docList && docList.length === 0 && documentsOfTask.length === 0) setSelectAll(false)
    }, [docList])
    /*eslint-enable */

    const setSelectAllMembers = isAll => {
        const filteredDocuments = documentsOfTask;
        setSelectAll(isAll && documentsOfTask.length > 0);
        setDocList(() => isAll ? filteredDocuments.map(doc => doc.fileName) : []);
    }

    const handleSelectDoc = id => {
        let values = [];
        const isExist = docList.includes(id);
        if (isExist) {
            values = docList.filter(doc => doc !== id);
        } else {
            values = [
                ...docList,
                id
            ]
        };
        setDocList(values);

    }

    const generateType = (type) => {
        switch (type) {
            case BC:
                return 'Booking Confirmation';
            case MBL:
                return 'Master BL';
            case HBL:
                return 'House BL';
            case INVOICE:
                return 'Invoice';
            case OTHER:
                return 'Other';
            default:
                return ''
        }
    }

    const generateTradeTrust = (id) => {
        doGenerateTradeTrust(taskId, id, shipmentId, true, status => {
            if (status) {
                // get trade trust data
                doGetTradeTrust(taskId, documentsOfTask.map(doc => doc.awsId), true, data => {
                    // map response data to documents
                    documentsOfTask.forEach(doc => {
                        doc.tradeTrust = data.find(e => e.id === doc.awsId);
                    });

                    setDocumentsOfTask(old => {
                        old.forEach(doc => {
                            doc.tradeTrust = data.find(e => e.id === doc.awsId);
                        })
                        return [...old];
                    });
                });
            }
        });
    }

    const generateTradeTrustColumn = (id, tradeTrust) => {
        if (!tradeTrust)
            return '';
        switch (tradeTrust.status) {
            case undefined:
            case 'non-exists':
                return (
                    <button type="button" class="tr__button primary icon outline" onClick={() => {
                        generateTradeTrust(id)
                    }}>
                        {t("home.active-shipment.details.tabs.document-tab.Generate-tt-doc")}
                    </button>
                );
            case 'issuing-failed':
                return (
                    <button type="button" class="failed-generate f-medium" onClick={() => {
                        generateTradeTrust(id)
                    }}>
                        {t("home.active-shipment.details.tabs.document-tab.Retry-generate-TT-doc")}
                    </button>
                );
            default:
                return (<a class="tr__link" href={tradeTrust?.interfaceURI}>{tradeTrust?.status}</a>)
        }
    }

    const columns = [
        {
            dataIndex: 'awsId,documentLink,fileName',
            render: (awsId, documentLink, fileName) => (
                <div className="tr__doc-title">
                    {isArchive || !checkPermission(typeDocumentTask === DEFAULT ? PERMISSIONS_LIST.DOCUMENT_REMOVE_DEFAULT : PERMISSIONS_LIST.DOCUMENT_REMOVE_TASK_DOCUMENT) ? <>{fileName}</> : <Checkbox
                        type='checkbox'
                        value={fileName}
                        checked={docList.includes(fileName)}
                        label={fileName}
                        onChange={() => handleSelectDoc(fileName)}
                    />}
                    {/* <h5>{}</h5> */}
                </div>
            )
        },
        {
            dataIndex: 'uploadedBy,uploadedTime',
            render: (uploadedBy, uploadedTime) => (
                <div className="tr__doc-date">
                    <p className="date">{moment(uploadedTime).format('DD MMM YYYY | hh:mm A')}</p>
                </div>
            )
        },
        {
            dataIndex: 'typeOfDocument',
            render: typeOfDocument => (
                <div className="tr__doc-type">
                    <p className="mbx1 text-capitalize">{generateType(typeOfDocument)}</p>
                </div>
            )
        },
        {
            dataIndex: 'awsId,tradeTrust',
            render: (awsId, tradeTrust) => (
                <div className="tr__doc-tradeTrust">
                    {generateTradeTrustColumn(awsId, tradeTrust)}
                </div>
            )
        },
        {
            dataIndex: 'awsId,documentLink,tradeTrust',
            render: (awsId, documentLink, tradeTrust) => (
                <div className={`tr__view-documents--action ${isArchive ? "text-center" : "d-flex justify-content-between "}`}>
                    {(!isArchive && checkPermission(PERMISSIONS_LIST.DOCUMENT_DOWNLOAD_TASK_DOCUMENT)) && <a
                        download
                        href={awsId ? (GET_FILE + awsId) : documentLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Tooltip
                            content={t("home.active-shipment.details.tabs.document-tab.icon-download")}
                        >
                            <i className="icon-download"></i>
                        </Tooltip>

                    </a>}


                    {checkPermission(typeDocumentTask === DEFAULT ? PERMISSIONS_LIST.DOCUMENT_VIEW_DEFAULT_DETAILS : PERMISSIONS_LIST.DOCUMENT_VIEW_TASK_DETAILS) && (
                        <Tooltip
                            content={t("home.active-shipment.details.tabs.document-tab.icon-eye")}
                        >
                            <i
                                className="icon-eye outline"
                                onClick={() => {
                                    setFileId(awsId);
                                    setViewExtractedInfo(true);
                                    setIsDocumentTab(true);
                                    setDocumentPermission(true);
                                }}
                            ></i>
                        </Tooltip>
                    )

                    }


                    {tradeTrust?.status === 'issued' && (
                        <a href={`${window.location.origin.replace('platform', 'enterprise')}/tt-management?dltId=${awsId}`}
                            rel="noreferrer"
                            target="_blank">
                            <Tooltip
                                content="Go to TT Management"
                            >
                                <i className="icon-chain"></i>
                            </Tooltip>
                        </a>
                    )}
                </div>
            )
        }
    ];

    const handleRemoveDocuments = () => {
        const payload = docList;

        if (typeDocumentTask === DEFAULT) {
            doRemoveDocumentsDefault(taskId, payload, () => {
                setSelectAll(false);
            })
        } else {
            doRemoveDocumentsTask(taskId, payload, () => {
                setSelectAll(false);
            })
        }
    }

    const checkPermission = (action) => {
        return functionsTask.includes(action)
    }

    return (
        <Modal
            open={open}
            className="tr__view-documents"
            onCancel={() => {
                onCancel();
                setDocList([]);
                setDocuments([]);
                setSelectAll(false);
                doGetDocuments(shipmentId, {
                    page: 0,
                    limit: DOCUMENT_PAGE_SIZE
                }, () => {
                })
            }}
            title={t("home.active-shipment.details.tabs.document-tab.shipment-information")}
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="tr__view-documents page-box">
                <div className="wrapper box">
                    <Table
                        className="tr__view-documents--table"
                        dataSource={documentsOfTask}
                        columns={columns}
                    >
                        <DocumentHeader
                            isDocuments={true}
                        />
                    </Table>
                    {documentsOfTask && documentsOfTask.length > 0 && (!isArchive && checkPermission(typeDocumentTask === DEFAULT ? PERMISSIONS_LIST.DOCUMENT_REMOVE_DEFAULT : PERMISSIONS_LIST.DOCUMENT_REMOVE_TASK_DOCUMENT)) && (
                        <TableFooter
                            onlyPagination={false}
                            // onlyPagination={isMember()}
                            // currentPage={currentPage}
                            // totalPages={totalPages}
                            // setCurrentPage={handlePageChange}
                            selectAll={selectAll}
                            setSelectAll={setSelectAllMembers}
                            selectedMembers={docList}
                            // onRemove={() => setRemoveMembers(true)}
                            onRemove={handleRemoveDocuments}
                        />
                    )}

                </div>
            </div>
        </Modal>
    )
}
