import { GET_FILE } from "actions";
import avatar from 'assets/images/avatar.png';
import { Modal, MultiSelect } from "components/common";
import { CollaboratorsContext, HomeContext } from "contexts";
import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

export const CollectSignatures = ({ open, onCancel }) => {
    const [values, setValues] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const { doGetCollaborators } = useContext(CollaboratorsContext);
    const [members, setMembers] = useState([]);
    const { signer, setSigner } = useContext(HomeContext);

    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 2];

    /*eslint-disable */
    useEffect(() => {
        if (open) {
            setValues(oldValue => []);
            setSigner(null);
        }
    }, [open]);
    /*eslint-enable */

    const getMembersTeams = (index, value) => {
        setSearchLoading(true);
        doGetCollaborators(shipmentId, {
            page: 0,
            limit: 9999
        }, data => {
            setMembers(data);
        });
    }

    const updateRole = (value, item) => {
        item.role = value.value;
        setSigner(item);
    }

    const handleRemove = (value) => {
        setSigner(null);
    }

    const handleConfirm = async () => {
        onCancel();
    }

    const handleCancel = () => {
        // setValues([]);
        setSigner(null);
        onCancel();
    }

    return (
        <Modal
            open={open}
            className=""
            onCancel={() => handleCancel()}
            title={'Choose signer'}
            btnClasses="justify-content-end"
            submitButton
            submitButtonLabel={"Comfirm"}
            onSubmitClick={handleConfirm}
        >
            <MultiSelect
                className="mbx4"
                value={values}
                name="consignee"
                mode="list"
                onInputChange={value => getMembersTeams(0, value)}
                options={members?.map((item, index) => {
                    return {
                        email: item?.email,
                        id: item?._id || new Date().getTime().toString(),
                        icon: item?.avatar ? GET_FILE + item?.avatar : avatar,
                        title: item?._id ? item?.name : item?.email,
                        memberId: item?._id
                    }
                })}
                searchLoading={searchLoading}
                placeholder={'You can search by name, email, organization name or can invite new people...'}
                onChange={items => {
                    setSigner(items[0]);
                    if (![...values].some(x => x._id === items[0]._id)) {
                        const item = items[0];
                        item.role = items[0].role;
                        setValues(oldValue => [...oldValue, item]);
                    }
                }}
                renderList={members => members.length > 0 && (
                    <div className='tr__dropdown--list'>
                        {
                            signer &&
                            <MemberSelected
                                key={signer.id}
                                item={signer}
                                handleRemove={handleRemove}
                                updateRole={updateRole}
                            />
                        }
                    </div>
                )}
            />

        </Modal>
    )
}

export const MemberSelected = ({
    type = 'member',
    cardOnly,
    removeLabel,
    item,
    orgDropdownOptions = [{ key: 1, value: 'Owner' }, { key: 2, value: 'Editor' }, { key: 3, value: 'Viewer' }, { key: 4, value: 'Signer' }],
    isMember = true,
    register = () => { },
    errors = [],
    isRemovable = true,
    updateRole = () => { },
    handleRemove = () => { }
}) => {


    const isRoleType = () => type === 'role';

    return (
        <div style={{ marginTop: '20px' }} className={`tr__item-card ${isRoleType() ? 'role' : ''} d-flex align-items-center justify-content-between flex-wrap`}>
            <div className="left-column d-flex align-items-center">
                <div className='info d-flex align-items-center'>
                    {isRemovable && (
                        <>
                            {removeLabel ? (
                                <p className="remove-btn" onClick={() => handleRemove(item)}>{removeLabel}</p>
                            ) : (
                                <i className="remove-btn icon-times" onClick={() => handleRemove(item)} />
                            )}
                        </>
                    )}

                    {item.icon && (item.icon.indexOf('icon') > -1 ? (
                        <i className={item.icon} />
                    ) : (
                        <img src={item.icon} alt={item.title} />
                    ))}
                </div>
                {/* {item.title && ( */}
                <div className="info-member">
                    <h6 className="f-medium h6 text-left plrx2">{item.title}</h6>
                    <p className="plrx2">{item.email}</p>
                </div>
                {/* )} */}
            </div>

            {/* {!cardOnly && (
                <>
                    <div className="right-column">
                        {isMember && !teamMember && (
                            <Dropdown
                                mode="select"
                                className="list-dropdown"
                                name={`orgRole${item.id}`}
                                icon="icon-chevron-down"
                                iconPosition="right"
                                defaultValue={item.role ? item.role : orgDropdownOptions[orgDropdownOptions.length - 1]}
                                options={orgDropdownOptions}
                                dropdownPosition="center"
                                placeholder="Role in Organization"
                                refs={register({ required: true })}
                                error={!!errors[`orgRole${item.id}`]}
                                errorMessage={getErrorMessage(errors[`orgRole${item.id}`], "Organization role")}
                                // disabled={item.role === 'Owner'}
                                onChange={(value) => updateRole(value, item)}
                            />
                        )}
                    </div>
                </>
            )} */}
        </div>
    )
}
