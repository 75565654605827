import { Dropdown, Input, Modal } from "components/common"
import { ActiveShipmentsContext, HomeContext } from "contexts";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import './index.scss';

export const VerifyWalletModal = ({ open, onCancel }) => {
    const { handleSubmit } = useForm();
    const [pwdToggle, setPwdToggle] = useState(false);
    const { wallets } = useContext(ActiveShipmentsContext);
    const { shipment } = useContext(HomeContext);
    const [walletSelected, setWalletSelected] = useState();
    const { setCurrentWallest } = useContext(HomeContext);

    const hanldConfirm = () => {
        setCurrentWallest(walletSelected);
        onCancel();
    }

    const hanldSelectWallet = (option) => {
        setWalletSelected(option);
    }

    const handlChangePassword = (event) => {
        console.log(event.target.value);
    }

    const hanldOnClickIcon = () => {
        setPwdToggle(!pwdToggle);
    }

    const handleSearchWallet = () => {

    }

    // console.log(wallets);

    // console.log(wallets?.fillter(x => x.orgId === shipment?.orgId));

    return (
        <Modal
            open={open}
            className="tr__confirmWallet"
            onCancel={onCancel}
            title="Select wallet"
            submitButton
            submitButtonLabel="Confirm"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(hanldConfirm)}>
                    {children}
                </form>
            )}
        >
            <div className="dropdow_account">
                <Dropdown
                    label={"Invoking party account *"}
                    options={wallets.filter(x => x.orgId === shipment?.orgId).map(type => {
                        return {
                            _id: type._id,
                            address: type.encWallet.address,
                            value: type.accountName,
                            orgId: type.orgId
                        }
                    })}
                    placeholder="Invoking party account"
                    iconPosition='right'
                    icon='icon-chevron-down'
                    defaultValue=''
                    dropdownPosition="center"
                    onInputChange={handleSearchWallet}
                    onChange={option => hanldSelectWallet(option)}
                    mode="input"
                />
            </div>
            <div className="input_address">
                <Input
                    label={"Invoking party account address *"}
                    placeholder="Invoking party account address"
                    defaultValue={walletSelected?.address}
                    name="wallet_address"
                    disabled={true}
                />
            </div>
            <div className="input_password">
                <Input
                    label={"Invoking party wallets password *"}
                    placeholder="Invoking party wallets password"
                    name="wallet_password"
                    type={!pwdToggle ? 'password' : 'text'}
                    onChange={handlChangePassword}
                    onIconClick={hanldOnClickIcon}
                    icon={pwdToggle ? "icon-eye-slash" : "icon-eye"}
                    iconPosition="right"
                />
            </div>
        </Modal>
    )
}