import { Dropdown, Input, Modal } from "components/common"
import { useState } from "react";
import { useForm } from "react-hook-form";
import './index.scss';

export const TransferHolderShipModal = ({open, onCancel}) => {
    const {handleSubmit} = useForm();
    const [memberOptions] = useState([{key:1, value: 'Hanh'}, {key:2, value: 'Nguyen'}]);
    const [loading] = useState(true);
    const handlTransfer = () => {
        console.log('Hanh Nguyen');
    }

    const hanldSelectOptionTransfer = (option) => {
        console.log(option);
    }

    return (
        <Modal
            open={open}
            className="tr__transfer_holder_ship"
            onCancel={onCancel}
            title="Transfer HolderShip"
            submitButton
            submitButtonLabel="Transfer"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(handlTransfer)}>
                    {children}
                </form>
            )}
        >
            <div className="tr__transfer_dropdow">
                <Dropdown
                    label="Select account *"
                    options={memberOptions.map(type => {
                        return {
                            key: type.key,
                            value: (type.value)
                        }
                    })}
                    placeholder="Select account"
                    iconPosition = 'right'
                    icon='icon-chevron-down'
                    defaultValue=''
                    onChange={option => hanldSelectOptionTransfer(option)}
                    mode="input"
                    loading={loading}
                />
                <Input
                    label="Account address *"
                    placeholder="Account address *"
                    disabled={true}
                    defaultValue="0x42f4D648b4731B5CDE8dddD87BaD2B9B3854fB8b"
                    className="input_value_default"
                />
            </div>
        </Modal>
    )
}
