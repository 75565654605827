import React, { useContext, useState, useRef } from 'react';
import moment from 'moment';
import {
    Datepicker,
    Input,
    Collaborators,
    Dropdown,
    Pie,
    Tooltip,
    UploadDocuments,
} from 'components';
import {  CollaboratorsContext, DocumentsContext, HomeContext, ManagementContext, ModalContext, ShipmentInfoContext } from 'contexts';
import { statusOptions } from 'utils';
import { CARD, ALL, COLLABORATORS_PAGE_SIZE, DONE, MYTASK, VDL } from 'actions';
import { CONSIGNEE, EXPORT_CUSTOMS, EXPORT_LOGISTICS, FREIGHT, GET_FILE, IMPORT_CUSTOMS, IMPORT_LOGISTICS, OTHER, PERMISSIONS_LIST, SHIPMENT_ADMIN, SHIPMENT_STATUS } from 'actions/constants';
import avatar from 'assets/images/avatar.png';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';

export const CardDocument = ({ task, index, moveCard, currentTab, type, section, tasks }) => {
    const { t } = useTranslation();
    // const { userInfo } = useContext(AuthContext);
    const {
        setAddTask,
        setAssignCollaborators,
        setLinkIssueList
    } = useContext(ModalContext);
    const {
        setIsEditTask,
        isArchive,
        setTaskId,
        setEventTask,
        setSelectTask,
        setTaskName,
        setTaskRoles,
        setCurrentTabManagement,
        setIsTemplate,
        permission,
        setPermissionEditTask,
        setIsRemovePartners
    } = useContext(HomeContext);
    const { doGetCollaborators } = useContext(CollaboratorsContext);
    const {
        // setDocuments,
        doUploadTaskDashboard,
    } = useContext(DocumentsContext);
    const {
        doGetTaskPartners,
        doChangeStatusTask,
        doGetTaskManagements,
        setIsDone,
        setLinkIssues,
        permissionTask,doEditOrderTaskActiveShipment
    } = useContext(ManagementContext);
    const { shipmentInfo } = useContext(ShipmentInfoContext);
    const [limit] = useState(4);
    const { shipmentId } = useParams();
    const ref = useRef(null)
    const handleAssignTask = (taskId, roles, name, status) => {
        setIsRemovePartners(checkPermissionTasks(PERMISSIONS_LIST.TASK_REMOVE_PARTNERS, taskId))
        doGetTaskPartners(taskId, () => {
            setAssignCollaborators(true);
        });
        setTaskId(taskId);
        setTaskName(name);
        setTaskRoles(roles);
        setIsDone(status && status.toLowerCase() === DONE);
        doGetCollaborators(shipmentId, {
            page: 0,
            limit: COLLABORATORS_PAGE_SIZE,
            role: roles.map(role => role.role)
        });

    }
    const handleChangeStatus = (option, taskId) => {
        const payload = {
            status: option.value.toUpperCase()
        }
        if (currentTab === ALL) {
            doChangeStatusTask(taskId, payload, () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: ALL
                })
            });
        } else {
            doChangeStatusTask(taskId, payload, () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: MYTASK
                })
            });
        }

    }


    const handleUploadFile = (document, files) => {
        let payload = [];
        files.forEach(file => {
            payload = [
                ...payload,
                {
                    awsId: file.id,
                    fileName: file.name,
                    documentLink: file.url,
                    typeOfDocument: OTHER
                }
            ]
        });
        doUploadTaskDashboard(document.id, payload, () => {
            doChangeStatusTask(document.id, {
                status: DONE.toUpperCase()
            }, () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: (currentTab === ALL) ? ALL : MYTASK
                })
            });
            // if (currentTab === ALL) {
            // doGetTaskManagements(shipmentId, {
            //     page: 0,
            //     tab: (currentTab === ALL) ? ALL : MYTASK
            // })
            // } else {
            // doGetTaskManagements(shipmentId, {
            // page: 0,
            // tab: MYTASK
            // })
            // }
        })
    }


    const checkPermissionTasks = (action, taskId) => {
        return permission?.shipmentFunctions.includes(action) || permissionTask.find(task => task.id === taskId)?.functions.includes(action);
    }

    const checkRolesPartner = (role) => {
        let rolePartner = "";
        switch (role) {
            case EXPORT_CUSTOMS:
                rolePartner = "home.role.export-customs"
                break;
            case IMPORT_CUSTOMS:
                rolePartner = "home.role.import-customs"
                break;
            case FREIGHT:
                rolePartner = "home.role.freight"
                break;
            case IMPORT_LOGISTICS:
                rolePartner = "home.role.import-logistics"
                break;
            case EXPORT_LOGISTICS:
                rolePartner = "home.role.export-logistics"
                break;
            case CONSIGNEE:
                rolePartner = "home.role.consignee"
                break;
            case SHIPMENT_ADMIN:
                rolePartner = "home.role.shipment-admin"
                break;
            default:
                rolePartner = "home.role.shipper"
                break;
        }
        return rolePartner;
    }
    const checkStatus = (status) => {
        let taskStatus = "";
        switch (status) {
            case SHIPMENT_STATUS.PENDING:
                taskStatus = "home.active-shipment.details.tabs.management-tab.status.pending"
                break;
            case SHIPMENT_STATUS.INPROGRESS:
                taskStatus = "home.active-shipment.details.tabs.management-tab.status.inprogress"
                    break;
            case SHIPMENT_STATUS.STUCK:
                taskStatus = "home.active-shipment.details.tabs.management-tab.status.stuck"
                break;
            case SHIPMENT_STATUS.DONE:
                taskStatus = "home.active-shipment.details.tabs.management-tab.status.done"
                break;
            default:
                taskStatus = ""
                break;
        }
        return taskStatus;
    }
    const [{ isOver }, drop] = useDrop({
        accept: CARD,
        collect: (monitor) => ({
            // handlerId: monitor.getHandlerId(),
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver()
        }),
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            // console.log("handlerId", handlerId)
            // debugger
            const dragIndex = item.index;
            const hoverIndex = index;
            const dragType = item.typeEvent;
            const hoverType = type;
            // console.log("item", item);
            // console.log("item task", item.task)

            // console.log("dragType", dragType)
            // console.log("hoverType", hoverType);
            if (dragIndex === hoverIndex) {
                return;
            }
            if (dragType !== hoverType) {
                return;
            }
            // if((dragIndex !== hoverIndex) && (item.typeEvent !== ){
            //     return;
            // }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        drop: () => doEditOrderTaskActiveShipment(shipmentId, tasks.map((task, taskIndex) => {
            return {
                id: task.id,
                order: taskIndex
            }
        }), () => {
            // if (currentTab === ALL) {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: currentTab === ALL ? ALL : MYTASK
                })
            // } else {
            //     doGetTaskManagements(shipmentId, {
            //         page: 0,
            //         tab: MYTASK
            //     })
            // }
        })
    });
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: CARD,
            id: task.id,
            index,
            typeEvent: task.type
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    let backgroundColor = "";
    if(isOver){
        backgroundColor =  "#bbf";
    }
    if(isDragging){
        backgroundColor = '#fbb';
    }


    return (
            <div ref={ref} style={{ backgroundColor }} className={`table--body d-flex table-body justify-content-between ${task?.status?.taskStatus.toLowerCase() === DONE ? 'done' : ''}`} >
                <div className="column name d-flex justify-content-between align-items-center">
                    <h4 className="h4 f-medium">
                        <i className="icon-check" />
                        <span className="short-name" title={task?.name}>{task?.name}</span>
                    </h4>
                    {task?.issuesLink?.length > 0 && (
                        <i className="tr__link-task-pin icon-chain" onClick={() => {
                            setLinkIssueList(true);
                            setLinkIssues(task?.issuesLink);
                        }
                        } />
                    )}
                </div>
                <div className="column partner">
                    <div className="partners">
                        {task.roles && task.roles.length > 0 && (
                            <>
                                {task.roles.slice(0, limit).map((role, partnerIndex) => (
                                    <Tooltip
                                        content={role && role.role
                                            ? t(checkRolesPartner(role.role))
                                            : role}
                                        key={partnerIndex}
                                    >
                                        {role && role.role && role.role === EXPORT_CUSTOMS ? <i className={`icon-export`} /> : ''}
                                        {role && role.role && role.role === IMPORT_CUSTOMS ? <i className={`icon-import`} /> : ''}
                                        {role && role.role && role.role === FREIGHT ? <i className={`icon-truck`} /> : ''}
                                        {role && role.role && role.role === IMPORT_LOGISTICS ? <i className={`icon-box-import`} /> : ''}
                                        {role && role.role && role.role === EXPORT_LOGISTICS ? <i className={`icon-box-export`} /> : ''}
                                        {role && role.role && role.role === CONSIGNEE ? <i className={`icon-box`} /> : ''}
                                        {role && role.role && role.role === SHIPMENT_ADMIN ? <i className={`icon-admin`} /> : ''}
                                        <i className={`icon-${role && role.role ? role.role.toLowerCase() : ''}`} />
                                    </Tooltip>
                                ))}

                                {limit < task.roles.length && (
                                    <Tooltip
                                        renderContent={() => (
                                            <div className="tags d-flex flex-column">
                                                {task.roles.slice(limit, task.roles.length).map((partner, partnerIndex) => (
                                                    <span key={partnerIndex}>{partner && partner.role ? partner.role.replace('_', ' ').toLowerCase() : ''}</span>
                                                ))}
                                            </div>
                                        )}
                                    >
                                        <span className="f-medium">+{task.roles.length - limit}</span>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="column assignee">
                    <Collaborators
                        members={task.partners}
                        skip={5}
                        onAdd={() => handleAssignTask(task.id, task.roles, task.name)}
                        onAvatarsClick={() => handleAssignTask(task.id, task.roles, task.name, task.status.taskStatus)}
                        noneButton={!checkPermissionTasks(PERMISSIONS_LIST.TASK_ASSIGN_PARTNERS, task.id)}
                        done={task.status.taskStatus.toLowerCase() === DONE ? true : false}
                        label={t("template.my-template.detail.assign")}
                    />
                </div>
                <div className="column status">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        dropdownPosition="center"
                        placeholder="Choose status"
                        // disabled={!task.partners.some(partner => partner.collaboratorId === userInfo._id)}
                        options={statusOptions.map(type => {
                            return {
                                key: type.key,
                                value: type.value
                            }
                        })}
                        defaultValue="asdasd"
                        value={statusOptions[Math.floor(Math.random() * statusOptions.length)]}
                        renderLabel={selectedValue => (
                            <div className="status-option">
                                <p className={`task-status text-capitalize ${task.status.taskStatus.toLowerCase()}`}>
                                    {t(checkStatus(task.status.taskStatus))}
                                </p>
                                <Tooltip
                                    renderContent={() => (
                                        <div className="tags d-flex flex-column">
                                            <span>{task.status.userUpdateName}</span>
                                            <small>{task.status.userRole ? task.status.userRole.replace('_', ' ').toLowerCase() : ''}</small>
                                        </div>
                                    )}
                                >
                                    <img src={task.status.userUpdateAvatar === '' ? avatar : GET_FILE + task.status.userUpdateAvatar} alt={task.status.userUpdateName} />
                                </Tooltip>
                            </div>
                        )}
                        render={(options, handleChange, selectedValue) => (
                            <>
                                {(task.status.taskStatus.toLowerCase() !== DONE) && !isArchive ? (
                                    <div className={`tr__status-dropdown ${checkPermissionTasks(PERMISSIONS_LIST.TASK_UPDATE_STATUS_DATE, task.id) ? '' : 'disabled'}`}>
                                        {options.map((option, optionIndex) => (
                                            <div
                                                className={`status-option ${task.status.taskStatus === option.key ? 'active' : ''}`}
                                                key={optionIndex}
                                                onClick={() => {
                                                    handleChangeStatus(option, task.id)
                                                    handleChange(option)
                                                }}
                                            >
                                                <p className={`task-status text-capitalize ${option.key.toLowerCase()}`}>{t(option.value)}</p>
                                                <i className="icon-check" />
                                            </div>
                                        ))}
                                    </div>
                                ) : ''}
                            </>
                        )}
                    />
                </div>
                <div className="column date">
                    {task.status.taskStatus.toLowerCase() !== DONE && (
                        <>
                            {task.dueDate.specificDate ? (
                                <>
                                    {new Date().getTime() - task.dueDate.specificDate > 0 ? (
                                        <Tooltip
                                            content={`${Math.abs(Math.floor((new Date().getTime() - task.dueDate.specificDate) / 86400000)) + t("home.active-shipment.shipmenttaskitem.days-overdue")}`}
                                        >
                                            <i className="icon-warning" />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            content={`${Math.abs(Math.floor((new Date().getTime() - task.dueDate.specificDate) / 86400000)) + t("home.active-shipment.shipmenttaskitem.notification-left")}`}
                                        >
                                            <Pie
                                                width={18}
                                                height={18}
                                                percentage={80}
                                            />
                                        </Tooltip>
                                    )}

                                </>
                            ) : (task.dueDate.eventBasedDate.typeOfEvent !== null ? (
                                <>
                                    {task.dueDate.eventBasedDate && new Date().getTime() - ((task.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + task.dueDate.eventBasedDate.adjustDays * 86400000) > 0 ? (
                                        <Tooltip
                                            content={`${Math.abs(Math.floor((new Date().getTime() - ((task.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + task.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000)) + t("home.active-shipment.shipmenttaskitem.days-overdue")}`}
                                        >
                                            <i className="icon-warning" />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            content={`${Math.abs(Math.floor((new Date().getTime() - ((task.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + task.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000)) + t("home.active-shipment.shipmenttaskitem.notification-left")}`}
                                        >
                                            <Pie
                                                width={18}
                                                height={18}
                                                percentage={80}
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            ) : (''))
                            }


                        </>
                    )}
                    {task.status.taskStatus.toLowerCase() === DONE && (
                        <>
                            {task.dueDate.specificDate ? (
                                <Tooltip
                                    content={`${(new Date().getTime() - task.dueDate.specificDate) > 0
                                        ? t("home.active-shipment.shipmenttaskitem.done-on-time")
                                        : Math.abs(Math.floor((new Date().getTime() - task.dueDate.specificDate) / 86400000)) + t("home.active-shipment.shipmenttaskitem.notification-completed")}`}
                                >
                                    <i className="icon-check"></i>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    content={`${(new Date().getTime() - (task.dueDate.eventBasedDate.dateOfEvent + task.dueDate.eventBasedDate.adjustDays * 86400000)) > 0
                                        ? t("home.active-shipment.shipmenttaskitem.done-on-time")
                                        : Math.abs(Math.floor((new Date().getTime() - (task.dueDate.eventBasedDate.dateOfEvent + task.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000)) + t("home.active-shipment.shipmenttaskitem.notification-completed")}`}
                                >
                                    <i className="icon-check"></i>
                                </Tooltip>
                            )}
                        </>
                    )}
                    <Datepicker
                        // start={new Date()}
                        render={(value, onClick) => (
                            <div className="tr__booking-date">
                                <Input
                                    iconPosition="right"
                                    placeholder="Booking Date"
                                    value={task.dueDate.specificDate
                                        ? moment(task.dueDate.specificDate).format('DD MMM YYYY')
                                        // : moment(task.dueDate.eventBasedDate.dateOfEvent + task.dueDate.eventBasedDate.adjustDays * 86400000).format('DD MM YYYY')}
                                        : (task.dueDate.eventBasedDate.typeOfEvent !== null
                                            ? (task.dueDate.eventBasedDate.typeOfEvent === VDL ? moment(shipmentInfo.departureDate + (task.dueDate.eventBasedDate.adjustDays * 86400000)).format('DD MMM YYYY') : moment(shipmentInfo.arrivalDate + (task.dueDate.eventBasedDate.adjustDays * 86400000)).format('DD MMM YYYY'))
                                            : ''
                                        )}
                                    disabled={true}
                                />
                                {task.dueDate.eventBasedDate && task.dueDate.eventBasedDate.typeOfEvent !== null ? (
                                    <p className="tr__badge">{`${task.dueDate.eventBasedDate.typeOfEvent} ${task.dueDate.eventBasedDate.adjustDays > 0 ? "+" : ''} ${task.dueDate.eventBasedDate.adjustDays}`}</p>
                                ) : ''}
                            </div>
                        )}
                    />
                </div>
                <div className="column actions">
                    <>
                        <Dropdown
                            disabled={task.status.taskStatus.toLowerCase() === DONE || isArchive || !checkPermissionTasks(PERMISSIONS_LIST.TASK_ADD_DOCUMENT, task.id)}
                            className="select upload-doc"
                            mode="select"
                            icon="icon-plus"
                            iconPosition="left"
                            dropdownPosition="right"
                            defaultValue={(task.documents && task.documents.length > 0) ? task.documents.length + `${task.documents.length > 1 ? t("home.active-shipment.shipmenttaskitem.documents") : t("home.active-shipment.shipmenttaskitem.document")}` : (isArchive ? '' : t("home.active-shipment.shipmenttaskitem.upload-documents"))}
                            placeholder=""
                            render={(options, handleChange, selectedValue, handleClose) => (
                                <>
                                    {checkPermissionTasks(PERMISSIONS_LIST.TASK_ADD_DOCUMENT, task.id) && (
                                        <UploadDocuments
                                            name={`upload-document-${index}`}
                                            className="modal"
                                            mode="list"
                                            label="Upload documents"
                                            onCancel={handleClose}
                                            onFilesUpload={files => { handleUploadFile(task, files) }}
                                        />
                                    )}
                                </>

                            )}
                        />
                        {!isArchive && checkPermissionTasks(PERMISSIONS_LIST.TASK_VIEW_DETAILS, task.id) &&
                            (
                                <p
                                    className={`tr__link info ${task.status.taskStatus.toLowerCase() === DONE ? 'disabled' : ''}`}
                                    onClick={() => {
                                        setAddTask(true);
                                        setIsEditTask(true);
                                        setEventTask(task);
                                        setSelectTask(section);
                                        setIsTemplate(false);
                                        setCurrentTabManagement(currentTab)
                                        setPermissionEditTask(checkPermissionTasks(PERMISSIONS_LIST.TASK_UPDATE_DETAILS, task.id))
                                    }}
                                >
                                    <i className="icon-pencil" />
                                </p>
                            )

                        }
                    </>
                </div>
            </div>

    )
}
