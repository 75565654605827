import { Modal } from 'components/common';
import { ModalContext, HomeContext, UploadFileContext, ActiveShipmentsContext } from 'contexts';
import React, { useContext, useEffect, useState } from 'react';
import './index.scss';


// const PDFImage = require("pdf-image").PDFImage;

export const PreviewSignatureDocument = ({ open, onCancel }) => {
    const { setCollectSignatures, collectSignatures, previewSignatureDocument } = useContext(ModalContext);
    // const { fileData, setFileData } = useContext(UploadFileContext);
    const { fileId, idDocumentSignature, signer, setNotificationMessage } = useContext(HomeContext);
    const { doGetFileData } = useContext(UploadFileContext);
    const { doPostToDocuSign } = useContext(ActiveShipmentsContext);


    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(0);
    const [positionForSignature, setPositionForSignature] = useState(0);
    const [signers, setSigners] = useState([]);
    const [fileData, setFileData] = useState(null);


    /*eslint-disable */
    useEffect(() => {
        if (previewSignatureDocument && fileId && idDocumentSignature) {
            setSigners(oldValue => []);
            setPage(0);
            setFileData(null);
            doGetFileData(fileId, data => setFileData(data));
        }
    }, [idDocumentSignature, fileId, previewSignatureDocument]);

    useEffect(() => {
        if (previewSignatureDocument && fileData) {
            setPage(1);
        }
    }, [fileData, previewSignatureDocument]);

    useEffect(() => {
        if (page > 0) {
            var loadingTask = pdfjsLib.getDocument(fileData.base64Url);
            loadingTask.promise.then(
                function (pdf) {
                    // pageCount = pdf._pdfInfo.numPages;
                    setPageCount(pdf._pdfInfo.numPages)

                    // Load information from the first page.
                    pdf.getPage(page).then(function (page) {
                        const viewport = page.getViewport(1);
                        // Apply page dimensions to the <canvas> element.
                        const canvas = document.getElementById('pdf');
                        const context = canvas.getContext('2d');
                        context.rotate(Math.PI);
                        canvas.height = 700;
                        canvas.width = 600;

                        // Render the page into the <canvas> element.
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };
                        page.render(renderContext);
                        context.transform(1, 0, 0, -1, 0, canvas.height + 65)
                    });
                },
                function (reason) {
                    console.error(reason);
                }
            );
        }
    }, [page])

    useEffect(() => {
        if (!collectSignatures && signer) {
            setSigners(oldValue => [...oldValue, {
                memberId: signer.memberId,
                email: signer.email,
                name: signer.title,
                icon: signer.icon,
                signPosition: {
                    pageNumber: page,
                    x: positionForSignature.x,
                    y: positionForSignature.y
                }
            }]);
        }
    }, [collectSignatures, signer]);
    /*eslint-enable */

    const handleSubmit = () => {
        if (signers.length > 0) {
            const payload = {
                file: {
                    base64: fileData.base64,
                    name: fileData.fileName,
                    extension: fileData.fileExtension
                },
                emailConfig: {
                    subject: "Please sign the document"
                },
                signers: signers.map(e => {
                    delete e.icon;
                    return e;
                })
            };
            doPostToDocuSign(idDocumentSignature, payload, (data) => {
                if (data && data.success) {
                    setNotificationMessage('Successfully!')
                }
            });
        }
    }

    const hanldCancel = () => {
        onCancel();
    }

    return (
        <Modal
            open={open}
            onCancel={hanldCancel}
            title={"Request signature"}
            className="preview_documents"
            submitButton={true}
            submitButtonLabel={"Request to sign"}
            onSubmitClick={handleSubmit}
        >

            <div className="preview">
                <canvas
                    onClick={event => {
                        const canvas = document.getElementById('pdf');
                        const rect = canvas.getBoundingClientRect();
                        setPositionForSignature({
                            x: parseInt(event.clientX - rect.left, 10),
                            y: parseInt(event.clientY - rect.top, 10)
                        });

                        setCollectSignatures(true);
                    }}
                    style={{ border: '1px solid #000000' }}
                    id='pdf'></canvas>
                <div>
                    <button className="page__button" type="button"
                        onClick={() => {
                            if (page > 1) {
                                setPage(page - 1);
                            }
                        }}>
                        previous
                    </button>
                    <span style={{ margin: 'auto 10px' }}>{page}</span>
                    <button className="page__button" type="button"
                        onClick={() => {
                            if (page < pageCount) {
                                setPage(page + 1);
                            }
                        }}>
                        next
                    </button>
                </div>
            </div>
            <div className="signers">
                {
                    signers.map((signer, index) =>
                        <div style={{ marginTop: '20px' }} className={`tr__item-card d-flex align-items-center justify-content-between flex-wrap`}>
                            <div className="left-column d-flex align-items-center">
                                <div className='info d-flex align-items-center'>
                                    <i className="remove-btn icon-times" onClick={() => {
                                        setSigners(oldValue => {
                                            oldValue.splice(index, 1);
                                            return [...oldValue];
                                        });
                                    }} />
                                    <img src={signer.icon} alt={signer.name} />
                                </div>
                                <div className="info-member">
                                    <h6 className="f-medium h6 text-left plrx2">{signer.name}</h6>
                                    <p className="plrx2">{signer.email}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </Modal>
    )
};
