import React, { useContext } from 'react';

import './index.scss';
import { ShipmentTaskHeader, ShipmentTaskItem } from 'components';
import { shipmentTasksFCL, shipmentTasksLCL } from 'utils';
import { HomeContext } from 'contexts';
import { PERMISSIONS_LIST } from 'actions';
import { useTranslation } from 'react-i18next';

export const ShipmentTasks = ({ type, tasks, collaborators, currentTab }) => {
    const { t } = useTranslation();
    const  { permission } = useContext(HomeContext);
    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }
    return (
        <div className="tr__shipment-task">
            <ShipmentTaskHeader className="mbx1" />
            <div className="tr__shipment-task-list">
                {checkPermission(PERMISSIONS_LIST.TASK_VIEW_LIST) ? (
                <ShipmentTaskItem 
                    initialTasks={type === 'FCL' ? shipmentTasksFCL : shipmentTasksLCL} 
                    tasks={tasks}
                    collaborators={collaborators}
                    currentTab={currentTab}
                />
                ) : (
                    <h3 className="tr__fta-advisor--content__permission-message h2 text-center mtx4"> {t("home.active-shipment.details.tabs.fta-tab.permission-message")}</h3>
                )}
            </div>
        </div>
    )
};
