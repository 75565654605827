import React, { useState, useCallback, useEffect } from 'react';
// import PropTypes from 'prop-types';

import './index.scss';
// import { ManagementContext } from 'contexts';
// import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import {
    CardEvent
} from 'components';

export const CardEventItem = (props) => {

    const { section, task, currentTab } = props;
    const [tasksEvent, setTasksEvent] = useState(task); 
    // const { doEditOrderTaskActiveShipment, doGetTaskManagements } = useContext(ManagementContext);
    // const {shipmentId} = useParams();
    useEffect(() => {
        setTasksEvent(task)
    },[task])

    const moveCardEvent = useCallback((dragIndex, hoverIndex,) => {
        const dragCard = tasksEvent[dragIndex];
        let newTasks = cloneDeep(tasksEvent)
        const temp = cloneDeep(dragCard)
        newTasks[dragIndex] = cloneDeep(newTasks[hoverIndex])
        newTasks[hoverIndex] = temp
        // let payload = [...newTasks];
        // doEditOrderTaskActiveShipment(shipmentId, payload.map((task, taskIndex) => {
        //     return {
        //         id: task.id,
        //         order: taskIndex
        //     }
        // }), () => {
        //     doGetTaskManagements(shipmentId, {
        //         page: 0,
        //         tab: currentTab === ALL ? ALL : MYTASK
        //     })
        // })
        // setTasksEvent(payload);
        setTasksEvent(newTasks);
    }, [tasksEvent]);

    // console.log(task)
    return (
        <>
            {
                tasksEvent.map((taskEvent, taskEventIndex) => (
                    <CardEvent moveCard={moveCardEvent}
                        index={taskEventIndex}
                        key={taskEventIndex}
                        section={section}
                        type={taskEvent.type}
                        task={taskEvent}
                        tasks={tasksEvent}
                        currentTab={currentTab}
                        />
                ))
            }
        </>
    )
}
