import React, { createContext, useContext, useState } from 'react';

import {
    addLinkedShipmentTask,
    addStarShipment,
    getActiveShipments,
    getActiveShipmentsDetails,
    getBookingDetails,
    getPartners,
    getSearchParams,
    OPERATION_FAILED_MESSAGE,
    removeStarShipment,
    saveSearchParams,
    NEED_ATTENTION,
    OTHER_ACTIVE,
    STAR,
    PERMISSION_ERROR,
    getValueFormActiveShipment,
    getAirPort,
    getAirLine,
    ORIGIN,
    DESTINATION,
    postToDocuSign,
    createDocumentSignature,
    getWallets
} from 'actions';
import { HomeContext, AuthContext } from 'contexts';
import { CollaboratorsContextProvider } from './CollaboratorsContext';
import { ManagementContextProvider } from './ManagementContext';
import { ShipmentInfoContextProvider } from './ShipmentInfoContext';
import { IssueContextProvider } from './IssueContext';
import { TemplateContextProvider } from './TemplateContext';
import { ExtractedContextProvider } from './ExtractedContext';
import { FtaAdvisorContextProvider } from './FtaAdvisorContext';
import { DocumentsContextProvider } from './DocumentsContext';
import { isEmail } from 'utils';
import { generateDocument, generateDocumentDetail } from 'actions/documents';

export const ActiveShipmentsContext = createContext();

export const ActiveShipmentsContextProvider = ({ children }) => {
    const { setLoading, setNotificationMessage, setNotificationType, setPermission } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [activeShipments, setActiveShipments] = useState([]);
    const [needActiveShipments, setNeedActiveShipments] = useState([]);
    const [needAttentionActiveShipments, setNeedAttentionActiveShipments] = useState([]);
    const [starActiveShipments, setStarActiveShipments] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [linkedShipment, setLinkedShipment] = useState();
    const [hasSearchData, setHasSearchData] = useState(false);
    const [bookingDetailsNumber, setBookingDetailsNumber] = useState();
    const [partners, setPartners] = useState([]);
    const [airLines, setAirLines] = useState([]);
    const [originAirPorts, setOriginAirPorts] = useState([]);
    const [destinationAirPorts, setDestinationAirPorts] = useState([]);
    const [firstAirPorts, setFirstAirPorts] = useState([]);

    const [currentPageStar, setCurrentPageStar] = useState(0);
    const [totalPagesStar, setTotalPagesStar] = useState(0);
    const [currentPageAttention, setCurrentPageAttention] = useState(0);
    const [totalPagesAttention, setTotalPagesAttention] = useState(0);
    const [totalItemsStar, settotalItemsStar] = useState(0);
    const [totalItemsAttention, settotalItemsAttention] = useState(0);
    const [shipmentDetails, setShipmentDetails] = useState();
    const [shipmentFilter, setShipmentFilter] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [loadingBlockChain, setLoadingBlockChain] = useState(false);

    const handleException = error => {
        const { data } = (error.response || {});
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetValueByShipmentInf = async (param, callback) => {
        try {
            const response = await getValueFormActiveShipment(param, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                if (callback) callback(data);
            }
        } catch (e) {
            handleException(e);
        }
    }

    const doPostToDocuSign = async (docId, payload, callback) => {
        try {
            setLoading(true);
            const response = await postToDocuSign(docId, payload, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                if (callback) callback(data);
            }
        } catch (error) {
            handleException(error);
        }
        setLoading(false);
    }

    const doCreateDocumentSignature = async (payload, callback) => {
        try {
            setLoading(true);
            const response = await createDocumentSignature(payload, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                if (callback) callback(data);
            }
        } catch (error) {
            handleException(error);
        }
        setLoading(false);
    }

    const doGetActiveShipments = async (params, callback, hasLoading = true) => {
        try {
            if (hasLoading) setLoading(true);

            const response = await getActiveShipments(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const { items, currentPage, totalPage, totalItems } = data;
                switch (params.tabType) {
                    case NEED_ATTENTION:
                        setNeedActiveShipments(items || []);
                        setCurrentPageAttention(currentPage);
                        setTotalPagesAttention(totalPage);
                        settotalItemsAttention(totalItems);
                        break;
                    case OTHER_ACTIVE:
                        setActiveShipments(items || []);
                        setTotalPages(totalPage);
                        setCurrentPage(currentPage);
                        setTotalItems(totalItems);
                        break;
                    case STAR:
                        setStarActiveShipments(items || []);
                        setTotalPagesStar(totalPage);
                        setCurrentPageStar(currentPage);
                        settotalItemsStar(totalItems);
                        break;
                    default:
                        setActiveShipments(items || []);
                        setTotalPages(totalPage);
                        setCurrentPage(currentPage);
                        setTotalItems(totalItems);
                        break;
                }
                if (callback) callback();
            }

            if (hasLoading) setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doGenerateEthereum = async (documentId, payload, partyAccount, type, callback) => {
        try {
            setLoading(true);
            setLoadingBlockChain(true);
            const response = await generateDocument(documentId, payload, partyAccount, type, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                if (callback) {
                    callback(data);
                }
            }
            setLoadingBlockChain(false);
            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doGetEthereumDetail = async (documentId, partyAccount, type, orgId, callback) => {
        try {
            setLoading(true);
            const response = await generateDocumentDetail(documentId, partyAccount, type, orgId, loggedInUser);
            const { data, status } = response;
            if(status === 200) {
                if(callback) callback(data);
            } 
        } catch (error) {
            handleException(error);
        }
    }

    const doGetAllEthereumDetail = async (documentIds, partyAccount, type, orgId, callback) => {
        try {
            setLoading(true);
            const response = await Promise.all(documentIds.map(item => {
                return generateDocumentDetail(item.documentId, partyAccount, type, orgId, loggedInUser);
            }));
            if (response.length > 0) {
                const documentDetails = response?.map(item => {
                    return item.data.ret;
                });
                if(callback) callback(documentDetails);
            }
        } catch (error) {
            handleException(error);
        }
    }

    const doGetwallets = async (callback) => {
        try {
            setLoading(true);
            const response = await getWallets(loggedInUser);
            const { data } = response;
            if (callback) callback(data);
            setWallets(data);
            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doCreateLinkedShipment = async (shipmentId, payload, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);

                const response = await addLinkedShipmentTask(shipmentId, payload, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    if (callback) callback(data);
                    setLinkedShipment(data || {})
                }

                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    const doSaveSearchParams = async (shipmentId, payload, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);

                const response = await saveSearchParams(shipmentId, payload, loggedInUser);
                const { status } = response;

                if (status === 200) {
                    if (callback) callback();
                }

                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetSearchParams = async (shipmentId, callback, hasLoading = false) => {
        if (shipmentId) {
            try {
                if (hasLoading) setLoading(true);

                const response = await getSearchParams(shipmentId, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    if (data === "") {
                        setHasSearchData(false)
                    } else {
                        setHasSearchData(true)
                    }
                    // (data !== "" ? setHasSearchData(true) : setHasSearchData(false));
                    if (callback) callback(data);
                }

                if (hasLoading) setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetBookingDetails = async () => {
        try {
            setLoading(true);

            const response = await getBookingDetails(loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                // const { bookingNumbers, blNumbers } = data;
                setBookingDetailsNumber(data);
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doAddStarShipment = async (shipmentId, currentTab, callback) => {
        try {
            setLoading(true);

            const response = await addStarShipment(shipmentId, currentTab, loggedInUser);
            const { status } = response;

            if (status === 200) {
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doRemoveStarShipment = async (shipmentId, currentTab, callback) => {
        try {
            setLoading(true);

            const response = await removeStarShipment(shipmentId, currentTab, loggedInUser);
            const { status } = response;

            if (status === 200) {
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doGetPartners = async (params, callback, hasLoading = false) => {
        try {
            if (hasLoading) setLoading(true);
            const response = await getPartners(params, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                if (params && params.name && isEmail(params.name) && [...data.items].findIndex(e => e.email === params.name) < 0) {
                    // for inviting new member
                    data.items = [...[{
                        email: params.name
                    }],
                    ...data.items];
                }
                setPartners(data.items);
                if (callback) callback();
            }
            if (hasLoading) setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doGetActiveShipmentsDetails = async (shipmentId, callback, permissionCallback) => {
        if (shipmentId) {
            try {
                setLoading(true);

                const response = await getActiveShipmentsDetails(shipmentId, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    setShipmentDetails(data || {});
                    setPermission(data.permission)
                    if (callback) callback(data);
                }

                setLoading(false);
            } catch (error) {
                handleException(error);
                setPermission(null);
                const { data } = (error?.response || {});
                if (data?.error?.status === 403 && data?.error?.message === PERMISSION_ERROR) {
                    if (permissionCallback) permissionCallback();
                }
            }
        }
    }

    const doGetAirLine = async (params, callback, errorCallback, hasLoading = false) => {
        if (params) {
            try {
                if (hasLoading) setLoading(true);

                // const newParams = { ...params };
                // delete newParams.isSearching;

                const response = await getAirLine(params, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    setAirLines(data.data || []);
                    if (callback) callback();
                }

                if (hasLoading) setLoading(false);
            } catch (error) {
                // handleException(error)
                if (errorCallback) errorCallback();
            }
        }
    }

    const doGetAirPort = async (params, callback, errorCallback, type = ORIGIN, hasLoading = false) => {
        if ((type === ORIGIN && originAirPorts.length === 0) || (type === DESTINATION && destinationAirPorts.length === 0) || params.isSearching) {
            try {
                if (hasLoading) setLoading(true);
                const newParams = { ...params };
                delete newParams.isSearching;

                const response = await getAirPort(newParams, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    if (params.hasOwnProperty('search')) {
                        if (type === ORIGIN) {
                            if (callback) callback(data.data || []);
                            setOriginAirPorts(data.data || []);
                        } else {
                            if (callback) callback(data.data || []);
                            setDestinationAirPorts(data.data || []);
                        }
                    } else {
                        setFirstAirPorts(data.data || []);
                        setOriginAirPorts(data.data || []);
                        setDestinationAirPorts(data.data || []);
                    }

                }

                if (hasLoading) setLoading(false);
            } catch (error) {
                // handleException(error)
                if (errorCallback) errorCallback();
            }
        }
    }

    return (
        <ActiveShipmentsContext.Provider
            value={{
                activeShipments,
                loadingBlockChain,
                wallets,
                setLoadingBlockChain,
                shipmentFilter,
                needActiveShipments,
                starActiveShipments,
                hasSearchData,
                needAttentionActiveShipments,
                linkedShipment,
                currentPage,
                totalPages,
                totalItems,
                bookingDetailsNumber,
                partners,
                doGetPartners,
                doGetAirLine,
                firstAirPorts,
                originAirPorts,
                destinationAirPorts,
                airLines,
                doGetAirPort,
                currentPageStar,
                totalPagesStar,
                currentPageAttention,
                totalPagesAttention,
                totalItemsStar,
                setShipmentDetails,
                totalItemsAttention,
                shipmentDetails,
                doCreateLinkedShipment,
                doGetActiveShipments,
                doGetEthereumDetail,
                doGetValueByShipmentInf,
                setNeedAttentionActiveShipments,
                setActiveShipments,
                setShipmentFilter,
                doSaveSearchParams,
                doGetSearchParams,
                doGetBookingDetails,
                doGetwallets,
                doAddStarShipment,
                doGenerateEthereum,
                doRemoveStarShipment,
                doPostToDocuSign,
                doCreateDocumentSignature,
                doGetActiveShipmentsDetails,
                doGetAllEthereumDetail,
            }}
        >
            <TemplateContextProvider>
                <IssueContextProvider>
                    <CollaboratorsContextProvider>
                        <ShipmentInfoContextProvider>
                            <ManagementContextProvider>
                                <ExtractedContextProvider>
                                    <FtaAdvisorContextProvider>
                                        <DocumentsContextProvider>
                                            {children}
                                        </DocumentsContextProvider>
                                    </FtaAdvisorContextProvider>
                                </ExtractedContextProvider>
                            </ManagementContextProvider>
                        </ShipmentInfoContextProvider>
                    </CollaboratorsContextProvider>
                </IssueContextProvider>
            </TemplateContextProvider>
        </ActiveShipmentsContext.Provider>
    );
};
