// API Constants
export const MAP_API_URL                    = process.env.REACT_APP_MAP_API_URL;
export const KENOBI_API_URL                 = 'https://kenobi-dev.services.trames-engineering.com/api';
export const BLOCK_CHAIN                    = 'https://falcon-dev.blockchain.trames-engineering.com/v2';

// Routes
export const TRACK_SHIPMENT_URL             = '/track-shipment';
export const VESSEL_SCHEDULE_URL            = '/vessel-schedule';
export const VESSEL_SCHEDULE_BASE_URL       = '/vessel-schedule/results';
export const VESSEL_SCHEDULE_RESULTS_URL    = `${VESSEL_SCHEDULE_BASE_URL}/:type/:origin/:destination/:dates`;
export const ACTIVE_SHIPMENTS_URL           = '/active-shipments';
export const ACTIVE_SHIPMENT_DETAILS_URL    = '/active-shipments/:shipmentId';
export const ISSUES_URL                     = '/active-shipments/:shipmentId/issues';
export const ISSUE_DETAILS_URL              = '/active-shipments/:shipmentId/issues/:issueId';
export const DOCUMENTS_URL                  = '/active-shipments/:shipmentId/documents';
export const FTA_URL                        = '/active-shipments/:shipmentId/fta';
export const SHIPMENT_INFO_URL              = '/active-shipments/:shipmentId/shipment-info';
export const SHIPMENT_LOG_URL               = '/active-shipments/:shipmentId/shipment-log';
export const ACTIVE_SHIPMENT_DOCUMENT_SIGNATURE_URL   = '/active-shipments/:shipmentId/document-signature';
export const ACTIVE_SHIPMENT_DOCUMENT_DETAILS_URL     = '/active-shipments/:shipmentId/document/:documentId';
export const ARCHIVE_URL                    = '/archive';
export const ARCHIVE_DETAILS_URL            = '/archive/:shipmentId';
export const DOCUMENTS_URL_ARCHIVE          = '/archive/:shipmentId/documents';
export const ISSUE_URL_ARCHIVE              = '/archive/:shipmentId/issues';
export const ISSUE_URL_ARCHIVE_DETAILS      = '/archive/:shipmentId/issues/:issueId';
export const ARCHIVE_SHIPMENT_INFO_URL      = '/archive/:shipmentId/shipment-info';
export const ARCHIVE_SHIPMENT_LOG_URL       = '/archive/:shipmentId/shipment-log';
export const ARCHIVE_FTA_URL                = '/archive/:shipmentId/fta';
export const ARCHIVE_DOCUMENT_SIGNATURE_URL = '/archive/:shipmentId/document-signature';
export const ARCHIVE_DOCUMENT_DETAILS_URL   = '/archive/:shipmentId/document/:documentId';
export const BOOKING_REQUEST_URL            = '/booking-request';
export const ALL_ISSUES_URL                 = '/all-issues';
export const ADD_NEW_REQUEST_URL            = '/booking-request/add-new-request';
export const VIEW_DOCUMENTS_URL             = '/view-documents';
export const EDIT_REQUEST_URL               = '/booking-request/:brId';
export const SHIPMENT_DETAILS               = '/shipping-details';
export const SHIPMENT_DETAILS_TEMPLATE      = '/shipping-details/:type';
export const NOT_FOUND_URL                  = '/not-found';

export const PERSONAL_SETTINGS_URL         = '/personal-settings';

export const ORGANIZATIONS_URL              = '/organizations';
export const ORGANIZATIONS_LIST_URL         = '/organizations/list';
export const ORGANIZATIONS_DETAILS_URL      = '/organizations/list/:orgId';
export const ORGANIZATIONS_INFO_URL         = '/organizations/info/:orgId';
export const TEAMS_URL                      = '/organizations/teams';
export const TEAM_DETAILS_URL               = '/organizations/teams/:teamId';
export const TEMPLATES_URL                  = '/organizations/templates';
export const TEMPLATE_DETAILS_URL           = '/organizations/templates/:templateId';
export const OTHER_TEMPLATES_URL            = '/organizations/other-templates';
export const OTHER_TEMPLATE_DETAILS_URL     = '/organizations/other-templates/:templateId';

export const AUTHENTICATION_URL             = '/authentication';
export const LOGIN_URL                      = '/authentication/login';
export const SIGNUP_URL                     = '/authentication/signup';
export const CHANGE_PASSWORD_URL            = '/authentication/change-password';
export const FORGET_PASSWORD_URL            = '/authentication/forget-password';
export const RESET_PASSWORD_URL             = '/authentication/reset-password';
export const NOTIFICATION_URL               = '/authentication/notification';

// API Endpoints
const API_BASE_URL                          = `/api`;

export const ORGANIZATIONS                  = `${API_BASE_URL}/organizations`;
export const GET_ORGANIZATION               = `${API_BASE_URL}/organizations/:orgId`;
export const JOIN_ORGANIZATION              = `${API_BASE_URL}/organizations/:orgId/join`;
export const JOIN_REQUEST_ORGANIZATION      = `${API_BASE_URL}/organizations/:orgId/join-requests/:memberId`;
export const GET_LOGS_ORGANIZATION          = `${API_BASE_URL}/organizations/:orgId/logs`;
export const GET_SHIPMENT_BY_ORG            = `${API_BASE_URL}/organizations/:orgId/shipments`;
export const COUPON_ORGANIZATION            = `${API_BASE_URL}/organizations/:orgId/extra-month`;
export const GET_SHIPMENT_PERMISSION        = `${API_BASE_URL}/organizations/shipment-permission/shipmentId`;

export const TEAMS                          = `${API_BASE_URL}/teams`;
export const GET_ORG_TEAMS                  = `${API_BASE_URL}/organizations/:orgId/teams`;
export const GET_TEAM                       = `${API_BASE_URL}/teams/:teamId`;
export const GET_LOGS_TEAM                  = `${API_BASE_URL}/teams/:teamId/logs`;
export const EXPORT_CSV_TEAM                = `${API_BASE_URL}/teams/:teamId/logs/csv`;

export const GET_TEAM_MEMBERS               = `${API_BASE_URL}/teams/:teamId/members`;
export const GET_ORG_MEMBERS                = `${API_BASE_URL}/organizations/:orgId/members`;
export const GET_MEMBER                     = `${API_BASE_URL}/members/:memId`;
export const MEMBERS                        = `${API_BASE_URL}/members`;
export const DELETE_ME                      = `${API_BASE_URL}/members/delete-me`;
export const CHECK_USER_EXIST               = `${API_BASE_URL}/members/sync-cognito`;

export const GET_LOCODE                     = `${API_BASE_URL}/locode`;
export const GET_VESSEL_SCHEDULE            = `${API_BASE_URL}/vessel-schedule`;
export const SHIPMENT_PERMISSION            = `${API_BASE_URL}/authorities/shipment/:orgId`

const AUTH                                  = `${API_BASE_URL}/auth`;
export const AUTH_LOGIN                     = `${AUTH}/login`;
export const AUTH_REGISTER                  = `${AUTH}/register`;
export const AUTH_CHANGE_PASSWORD           = `${AUTH}/changepassword`;
export const AUTH_GET_USER_INFO             = `${AUTH}/getuserinfo`;
export const AUTH_GENERATE_TOKEN            = `${AUTH}/generateToken`;
export const AUTH_FORGOT_PASSWORD           = `${AUTH}/forgotpassword`;
export const AUTH_CONFIRM_FORGOT_PASSWORD   = `${AUTH}/confirmforgotpassword`;

export const PERSONAL_UPDATE                = `${API_BASE_URL}/personal/update`;
export const PERSONAL_LEAVE                 = `${API_BASE_URL}/personal/leave`;

export const GET_CARRIERS                   = `${API_BASE_URL}/carrier`;
export const GET_LOCODES                    = `${API_BASE_URL}/locode`;
export const GET_TRACKERS                   = `${API_BASE_URL}/tracker`;
export const GET_COURIES                    = `${API_BASE_URL}/couriers`;
export const GET_TRACKERS_V4                = `${process.env.REACT_APP_API_HERMIT_API}/tracker`;
export const GET_ROUTES_V4                  = `${process.env.REACT_APP_API_HERMIT_API}/tracker/vessel-route`;
export const GET_ROUTES                     = `${API_BASE_URL}/route`;
export const TRACKER_MARK_ACTIVE_SHIPMENT   = `${API_BASE_URL}/tracker/mark-active-shipment`;
export const GET_TRACKERS_EVENT             = `${process.env.REACT_APP_API_HERMIT_API}/tracker/event`;

export const BOOKINGS                       = `${API_BASE_URL}/bookings`;
export const BOOKING                        = `${API_BASE_URL}/bookings/:bookingId`;
export const ACTIVITY_LOGS                  = `${API_BASE_URL}/bookings/:bookingId/logs`;
export const BOOKING_DRAFT                  = `${API_BASE_URL}/bookings/draft`;
export const BOOKING_STATUS                 = `${API_BASE_URL}/bookings/:bookingId/status`;
export const BOOKING_COLLABORATORS          = `${API_BASE_URL}/bookings/:bookingId/add-collaborators`;
export const BOOKING_COMMENTS               = `${API_BASE_URL}/bookings/:bookingId/comment`;

export const TEMPLATES_DETAILS              = `${API_BASE_URL}/templates/:templateId`;
export const TEMPLATES_DETAILS_CLONE        = `${API_BASE_URL}/templates/:templateId/clone`;
export const TEMPLATES                      = `${API_BASE_URL}/templates`;
export const TEMPLATE                      = `${API_BASE_URL}/template`;
export const SECTION                        = `${API_BASE_URL}/templates/:templateId/section`;
export const TEMPLATE_CREATE_TASK           = `${API_BASE_URL}/templates/section/:sectionId/task`;
export const TEMPLATE_TASK                  = `${API_BASE_URL}/templates/task/:taskId`;
export const TEMPLATE_COLLABORATORS         = `${API_BASE_URL}/templates/:templateId/collaborators`;
export const TEMPLATE_SECTION               = `${API_BASE_URL}/templates/section/:sectionId`;
export const TEMPLATE_ASSIGNEES             = `${API_BASE_URL}/templates/task/:taskId/assignees`;
export const TEMPLATE_SECTION_TASK_ORDER    = `${API_BASE_URL}/template/:templateId/task/order`;
export const NOTIFICATION_LANGUAGE          = `${API_BASE_URL}/change-language`;
/**
 * Upload files.
 */
export const DEFAULT_MAX_SIZE_UPLOAD        = 5242880;
export const IMAGE_FILE_EXTENSION           = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
export const WORD_FILE_EXTENSION            = ['doc', 'docx'];
export const EXCEL_FILE_EXTENSION           = ['xls', 'xlsx'];
export const PDF_FILE_EXTENSION             = ['pdf'];
export const FILE_TYPE_USER_FILES           = 'UserFiles';
export const FILE_SUBTYPE_USER_AVATAR       = 'UserAvatar';
export const FILE_TYPE_ORG_FILES            = 'OrgFiles';
export const FILE_SUBTYPE_ORG_LOGO          = 'OrgLogo';
export const FILE_TYPE_ISSUE_CMT_DOCS       = 'IssueCommentDocs';
export const FILE_TYPE_BR_CMT_DOCS          = 'BrCommentDocs';
export const MAX_FILE_CMT_DOCS              = 9;
export const MAX_SIZE_CMT_DOC               = 26214400;
export const FILE_TYPE_SHIPMENT_DETAIL_DOCS = 'shipmentDetailsDocs';
export const FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS = 'booking-confirmation';
export const MAX_FILE_SHIPMENT_DETAIL_DOCS  = 1;
export const MAX_SIZE_SHIPMENT_DETAIL_DOC   = 5242880;
export const MAX_FILE_FTA_ADVISOR_DOCS      = 3;
export const GET_UPLOAD_URL_SIGNED_FILE     = `${API_BASE_URL}/storage/uploadSignedURL`;
export const GET_UPLOADED_SIGNED_FILE_URL   = `${API_BASE_URL}/storage/signedURL`;
export const GET_FILE                       = `${API_BASE_URL}/storage/file?fileName=`;
export const GENERATE_TRADE_TRUST           = `${API_BASE_URL}/storage/generateTradeTrust/:taskId`;
export const GET_TRADE_TRUST                = `${API_BASE_URL}/storage/getTradeTrust/:taskId`;
export const GET_DOCUMENT_TASK_DEFAULT      = `${API_BASE_URL}/active-shipment/:shipmentId/documentTasks-default`;
export const ACTIVE_SHIPMENT_DOCUMENTS_DEFAULT_TASK = `${API_BASE_URL}/active-shipment/task/:taskId/documents-default`;

export const ACTIVE_SHIPMENTS               = `${API_BASE_URL}/active-shipments`;
export const ACTIVE_SHIPMENT_ISSUES         = `${API_BASE_URL}/active-shipment/:shipmentId/issues`;
export const ACTIVE_SHIPMENT_ISSUE          = `${API_BASE_URL}/active-shipment/:shipmentId/issues/:issueId`;
export const TASKS_ISSUE                    = `${API_BASE_URL}/active-shipment/:shipmentId/tasksIssue`;
export const ACTIVE_SHIPMENT_CLOSE_ISSUE    = `${API_BASE_URL}/active-shipment/:shipmentId/issues/:issueId/status-close`;
export const ACTIVE_SHIPMENT_OPEN_ISSUE     = `${API_BASE_URL}/active-shipment/:shipmentId/issues/:issueId/status-reopen`;
export const ACTIVE_SHIPMENT_ISSUE_PARTNERS = `${API_BASE_URL}/active-shipment/:shipmentId/issues/:issueId/partners`;
export const ACTIVE_SHIPMENT_ISSUE_COMMENTS = `${API_BASE_URL}/active-shipment/:shipmentId/issues/:issueId/comments`;
export const ACTIVE_SHIPMENT_COLLABORATORS  = `${API_BASE_URL}/active-shipment/:shipmentId/collaborators`;
export const ACTIVE_SHIPMENT_ADD_SECTION    = `${API_BASE_URL}/active-shipment/:shipmentId/section`;
export const ACTIVE_SHIPMENT_EDIT_SECTION   = `${API_BASE_URL}/active-shipment/section/:sectionId`;
export const ACTIVE_SHIPMENT_TASKS          = `${API_BASE_URL}/active-shipment/:shipmentId/tasks`;
export const ACTIVE_SHIPMENT_TASK_PARTNERS  = `${API_BASE_URL}/active-shipment/task/:taskId/partners`;
export const ACTIVE_SHIPMENT_TASK_STATUS    = `${API_BASE_URL}/active-shipment/section/task/:taskId/status`;
export const ACTIVE_SHIPMENT_SAVE_TEMPLATE  = `${API_BASE_URL}/active-shipment/:taskId/save-template`;
export const ACTIVE_SHIPMENT_ADD_TASK       = `${ACTIVE_SHIPMENT_EDIT_SECTION}/task`;
export const SHIPMENT_MARK_AS_COMPLETE      = `${API_BASE_URL}/active-shipment/:shipmentId/mark-as-complete`;
export const SHIPMENT_CANCEL                = `${API_BASE_URL}/active-shipment/:shipmentId/cancel`;
export const ACTIVE_SHIPMENT_UPDATE_TASK    = `${API_BASE_URL}/active-shipment/section/task/:taskId/edit-task`;
export const ACTIVE_SHIPMENT_DELETE_TASK    = `${API_BASE_URL}//active-shipment/section/task/:taskId`
export const ACTIVE_SHIPMENT_ADD_EVENT = `${API_BASE_URL}/active-shipment/section/task/:taskId/add-event`;
export const ACTIVE_SHIPMENT_INFO           = `${API_BASE_URL}/active-shipment/:shipmentId/info`;
export const ACTIVE_SHIPMENT_NOTE           = `${API_BASE_URL}/active-shipment/:shipmentId/note`;
export const ACTIVE_SHIPMENT_AS_TEMPLATE    = `${API_BASE_URL}/templates/:shipmentId`;
export const ACTIVE_SHIPMENT_HSCODE         = `${API_BASE_URL}/active-shipment/:shipmentId/fta/hscodes`;
export const ACTIVE_SHIPMENT_FTA_DETAILS    = `${API_BASE_URL}/active-shipment/:shipmentId/fta/fta-result`;
export const ACTIVE_SHIPMENT_ADD_TASK_FTA   = `${API_BASE_URL}/active-shipment/:shipmentId/fta/task`;
export const ACTIVE_SHIPMENT_LINKED         = `${API_BASE_URL}/active-shipment/:shipmentId/linked-shipment`;
export const ACTIVE_SHIPMENT_DOCUMENTS      = `${API_BASE_URL}/active-shipment/:shipmentId/documentTasks`;
export const ACTIVE_SHIPMENT_DOCUMENTS_TASK = `${API_BASE_URL}/active-shipment/task/:taskId/documents`;
export const ACTIVE_SHIPMENT_DOCUMENTS_REMOVE_TASK    = `${API_BASE_URL}/active-shipment/task/:taskId/documents`;
export const ACTIVE_SHIPMENT_DOCUMENTS_REMOVE_DEFAULT = `${API_BASE_URL}/active-shipment/task/:taskId/documents-default`;
export const ACTIVE_SHIPMENT_SEARCH_PARAMS  = `${API_BASE_URL}/active-shipment/mspa/:shipmentId`;
export const ACTIVE_SHIPMENT_LOGS           = `${API_BASE_URL}/active-shipment/:shipmentId/logs`;
export const ACTIVE_SHIPMENT_BK_DETAILS     = `${API_BASE_URL}/active-shipments/booking-details`;
export const ACTIVE_SHIPMENT_STAR           = `${API_BASE_URL}/active-shipments/:shipmentId/star`;
export const ACTIVE_SHIPMENT_FIELDS         = `${API_BASE_URL}/active-shipments/fields`;
export const ACTIVE_SHIPMENT_PARTNERS       = `${API_BASE_URL}/active-shipments/partners`;
export const ACTIVE_SHIPMENT_DEATILS        = `${API_BASE_URL}/active-shipment/:shipmentId/detail`;
export const ACTIVE_SHIPMENT_TASK_ORDER     = `${API_BASE_URL}/active-shipment/:shipmentId/task/order`;
export const ACTIVE_SHIPMENT_AIR_PORT       = `${API_BASE_URL}/active-shipments/air/airport`;
export const ACTIVE_SHIPMENT_AIR_LINE       = `${API_BASE_URL}/active-shipments/air/airline`;
export const WALLETS                        = `${KENOBI_API_URL}/eth-wallets`;
export const ETHEREUM                       = `${process.env.REACT_APP_DOCUMENT_API_URL}falcon/file-records/:documentId/generate`;
export const ETHEREUM_DETAIL                = `${process.env.REACT_APP_DOCUMENT_API_URL}falcon/file-records/:documentId`;
export const DOCUSIGN                       = `${process.env.REACT_APP_DOCUMENT_API_URL}docu-sign/:docId/requestSign`;

export const DOCUMENT_SIGNATURE_CREATE      = `${process.env.REACT_APP_DOCUMENT_API_URL}document-signature`;
export const ACTIVE_SHIPMENT_DOCUMENT_SIGNATURE  = `${process.env.REACT_APP_DOCUMENT_API_URL}document-signature/find`;
export const ACTIVE_SHIPMENT_DOCUMENT_SINGATURE_STATUS = `${process.env.REACT_APP_DOCUMENT_API_URL}falcon/file-records/:documentId`;
export const PROJECT_FIELD                  = `${API_BASE_URL}/active-shipments/dropdown`;

export const NOTIFICATION_GET_CONFIG        = `${process.env.REACT_APP_API_KENOBI_URL}/notify/config`;
export const NOTIFICATION_AUTH              = `${API_BASE_URL}/notify/auth`;
export const NOTIFICATIONS_GET              = `${API_BASE_URL}/notify`;
export const NOTIFICATIONS_MARK_AS_READ     = `${API_BASE_URL}/notify/maskAsRead`;
export const NOTIFICATIONS_MARK_ALL_AS_READ = `${API_BASE_URL}/notify/maskAllAsRead`;
export const NOTIFICATIONS_DELETE           = `${API_BASE_URL}/notify`;
export const NOTIFICATIONS_DISABLE          = `${API_BASE_URL}/notify/:id/disable`;

export const ALL_ISSUES                     = `${API_BASE_URL}/all-issues`;

// OCR APIS
export const OCR                            = `${API_BASE_URL}/ocr/:docId`;

// Others
export const SITE_NAME                      = 'Trames';

export const TAB_CONTAINER                  = 'Container';
export const TAB_BL_NUMBER                  = 'BL number';
export const TAB_BOOKING_NO                 = 'Booking number';
export const TAB_AIRWAY_BILL                = 'Airway bill';
export const TAB_COURIER_NO                 = 'courier number';

export const COMMENTS                       = 'Comments';
export const ACTIVITIES_LOG                 = 'Activities Log';

export const LOGIN                          = 'authentication.logIn';
export const SIGNUP                         = 'authentication.signUp';

export const DATE_FORMAT                    = 'DD MMM YYYY';
export const EXPIRED_DATE_FORMAT            = 'MM/YYYY';

export const OPERATION_FAILED_MESSAGE       = 'Operation failed. Please check again!';

export const PATH_NAME_AFTER_LOGIN          = 'PATH_NAME_AFTER_LOGIN';

export const ORGANIZATION_PAGE_SIZE         = 6;
export const LOCODE_PAGE_SIZE               = 20;
export const TEAM_PAGE_SIZE                 = 6;
export const MEMBER_PAGE_SIZE               = 6;
export const CARRIER_PAGE_SIZE              = 6;
export const BOOKING_REQUESTS_PAGE_SIZE     = 6;
export const TEMPLATE_PAGE_SIZE             = 6;
export const ISSUE_PAGE_SIZE                = 6;
export const BR_COMMENTS_PAGE_SIZE          = 6;
export const SERVICE_PROVIDERS_PAGE_SIZE    = 6;
export const COMMENT_PAGE_SIZE              = 6;
export const COLLABORATORS_PAGE_SIZE        = 6;
export const TASK_PAGE_SIZE                 = 6;
export const ACTIVE_SHIPMENT_PAGE_SIZE      = 6;
export const DOCUMENT_PAGE_SIZE             = 9999;
export const LOG_PAGE_SIZE                  = 6;
export const DEBOUNCE_TIME                  = 500;
export const ALL_ISSUES_PAGE_SIZE           = 6;

export const ACCESS_TOKEN                   = 'ACCESS_TOKEN';
export const REFRESH_TOKEN                  = 'REFRESH_TOKEN';
export const ACCESS_TOKEN_EXPIRY            = 'ACCESS_TOKEN_EXPIRY';
export const REFRESH_TOKEN_EXPIRY           = 'REFRESH_TOKEN_EXPIRY';

export const OWNER                          = 'owner';
export const ADMIN                          = 'admin';
export const MEMBER                         = 'member';

export const TEAM                           = 'TEAM';
export const ORGANIZATION                   = 'ORGANIZATION';

export const TRACKER_BOOKING_BC             = 'BC';
export const TRACKER_BOOKING_BK             = 'BK';
export const TRACKER_BOOKING_BL             = 'BL';

export const SHIPMENT_ADMIN                 = 'SHIPMENT_ADMIN';
export const SHIPPER                        = 'SHIPPER'
export const CONSIGNEE                      = 'CONSIGNEE';
export const EXPORT_CUSTOMS                 = 'EXPORT_CUSTOMS';
export const FREIGHT                        = 'FREIGHT';
export const EXPORT_LOGISTICS               = 'EXPORT_LOGISTICS';
export const IMPORT_LOGISTICS               = 'IMPORT_LOGISTICS';
export const IMPORT_CUSTOMS                 = 'IMPORT_CUSTOMS';

export const ORIGIN                         = 'origin';
export const DESTINATION                    = 'destination';
export const ERROR                          = 'error';
export const SUCCESS                        = 'success';
export const DEPARTURE                      = 'departure';
export const ARRIVAL                        = 'arrival';
export const EMPTY                          = 'empty';
export const DONE                           = 'done';
export const ORTHER                         = 'ORTHER'

export const CREATED                        = 'CREATED';
export const RECEIVED                       = 'RECEIVED';

export const MINE                           = 'mine';
export const OTHERS                         = 'others';

export const ACTIVE                         = 'ACTIVE';
export const CREATOR                        = 'CREATOR';
export const FREIGHT_PARTNER                = 'FREIGHT_PARTNER';
export const COLLABORATOR                   = 'COLLABORATOR';

export const SUBMITTED                      = 'SUBMITTED';
export const DRAFT                          = 'DRAFT';
export const CONFIRMED                      = 'CONFIRMED';
export const CANCELLED                      = 'CANCELLED';
export const REJECTED                       = 'REJECTED';
export const DELETED                        = 'DELETED';
export const MARK_AS_ACTIVE_SHIPMENT        = 'MARK_AS_ACTIVE_SHIPMENT';
export const MARKED                         = 'MARKED';

export const FAILED                         = 'FAILED';

export const ALL                            = 'ALL';
export const OPEN                           = 'OPEN';
export const CLOSED                         = 'CLOSED';
export const CANCELED                       = 'CANCELED';
export const COMPLETE                       = 'COMPLETE';

export const MARK_NEW                       = 'MARK_NEW';
export const MARK_TRACK_SHIPMENT            = 'MARK_TRACK_SHIPMENT';
export const MARK_BOOKING_REQUEST           = 'MARK_BOOKING_REQUEST';
export const CARD                           = 'CARD';

export const ISSUES                         = 'Issues';
export const TASK_MANAGEMENT                = 'TaskManagement';
export const DOCUMENTS                      = 'Documents';
export const FTA                            = 'Fta-advisor';
export const SHIPMENT_INFO                  = 'Shipment-info';
export const SHIPMENT_LOG                   = 'Shipment-log';
export const DOCUMENT_SIGNATURE             = 'Document-signature';

export const SHIPPING_BOOKING_REQUEST       = 'br';
export const SHIPPING_TRACKER               = 'ts';
export const EVENT                          = 'EVENT';
export const DOCUMENT                       = 'DOCUMENT';

export const PENDING                        = 'PENDING';

export const SHIPMENT_STATUS = {
  INPROGRESS: 'INPROGRESS',
  PENDING: 'PENDING',
  STUCK: 'STUCK',
  DONE: 'DONE',
};

export const VAD                             = 'VAD';
export const VDL                             = 'VDL';

export const ACTIVE_SHIPMENT_TABS = [
  {
    id: 'ALL',
    name: 'home.active-shipment.tab.all'
  },
  {
    id: 'MY_TASK',
    name: 'home.active-shipment.tab.my-task'
  },
]

export const ARCHIVE_SHIPMENT_TABS = [
  {
    id: 'ALL',
    name: 'home.active-shipment.tab.all'
  },
  {
    id: 'COMPLETE',
    name: 'home.active-shipment.tab.completed'
  },
  {
    id: 'CANCELED',
    name: 'home.active-shipment.tab.canceled'
  }
]

export const ORGANIZATIONS_TABS = [
  {
    id: 'My Org',
    name: 'organization.myOrg'
  },
  {
    id: 'All',
    name: 'organization.all'
  },
]

export const MYTASK                         = 'MY_TASK';
export const COMPLETED                      = 'COMPLETE';

export const DEFAULT_AVATAR                 = 'assets/images/default-avatar.png';
export const DEFAULT                        = 'DEFAULT';

export const BC                             = 'BC';
export const MBL                            = 'MBL';
export const HBL                            = 'HBL';
export const INVOICE                        = 'INVOICE';
export const OTHER                          = 'OTHER';

export const TEAM_LOGS                      = 'TEAM_LOGS';
export const ORG_LOGS                       = 'ORG_LOGS';
export const SHIPMENT_LOGS                  = 'SHIPMENT_LOGS';
export const DOCUMENT_DEFAULT_FTA           = 'Upload Invoices for FTA';

export const LANGUAGE                       = 'LANGUAGE';

export const OCEAN                          = 'OCEAN';
export const AIR                            = 'AIR';
export const ULD                            = 'ULD';
export const SEA                            = 'SEA';
export const NEED_ATTENTION                 = 'NEED_ATTENTION';
export const OTHER_ACTIVE                   = 'OTHER_ACTIVE';
export const STAR                           = 'STAR';

export const FCL                            = 'FCL';

export const PERMISSIONS_LIST = {
    SHIPMENT_VIEW_DETAILS: "SHIPMENT_VIEW_DETAILS",
    SHIPMENT_CREATE_LINKED_SHIPMENT: "SHIPMENT_CREATE_LINKED_SHIPMENT",
    SHIPMENT_VIEW_LINKED_SHIPMENT: "SHIPMENT_VIEW_LINKED_SHIPMENT",
    SHIPMENT_VIEW_SHIPMENT_ON_MAP: "SHIPMENT_VIEW_SHIPMENT_ON_MAP",
    SHIPMENT_SEARCH_AGAIN: "SHIPMENT_SEARCH_AGAIN",
    SHIPMENT_APPLY_SEARCH_AGAIN: "SHIPMENT_APPLY_SEARCH_AGAIN",
    SHIPMENT_SAVE_AS_TEMPLATE: "SHIPMENT_SAVE_AS_TEMPLATE",
    SHIPMENT_MARK_AS_COMPLETE: "SHIPMENT_MARK_AS_COMPLETE",
    SHIPMENT_CANCEL: "SHIPMENT_CANCEL",
    SHIPMENT_ADD_COLLABORATORS: "SHIPMENT_ADD_COLLABORATORS",
    SHIPMENT_ADD_SHIPMENT_ADMIN: "SHIPMENT_ADD_SHIPMENT_ADMIN",
    SHIPMENT_VIEW_COLLABORATORS: "SHIPMENT_VIEW_COLLABORATORS",
    SHIPMENT_REMOVE_COLLABORATORS: "SHIPMENT_REMOVE_COLLABORATORS",
    SHIPMENT_SEARCH_COLLABORATORS: "SHIPMENT_SEARCH_COLLABORATORS",
    SECTION_CREATE: "SECTION_CREATE",
    SECTION_UPDATE: "SECTION_UPDATE",
    SECTION_DELETE: "SECTION_DELETE",
    TASK_CREATE: "TASK_CREATE",
    TASK_VIEW_DETAILS: "TASK_VIEW_DETAILS",
    TASK_UPDATE_DETAILS: "TASK_UPDATE_DETAILS",
    TASK_UPDATE_STATUS_DATE: "TASK_UPDATE_STATUS_DATE",
    TASK_ASSIGN_PARTNERS: "TASK_ASSIGN_PARTNERS",
    TASK_REMOVE_PARTNERS: "TASK_REMOVE_PARTNERS",
    TASK_DELETE: "TASK_DELETE",
    TASK_ADD_EVENT: "TASK_ADD_EVENT",
    TASK_ADD_DOCUMENT: "TASK_ADD_DOCUMENT",
    TASK_VIEW_LIST: "TASK_VIEW_LIST",
    TASK_VIEW_ASSIGNEE_LIST: "TASK_VIEW_ASSIGNEE_LIST",
    ISSUE_VIEW_LIST: "ISSUE_VIEW_LIST",
    ISSUE_VIEW_DETAIL: "ISSUE_VIEW_DETAIL",
    ISSUE_ASSIGN_PARTNER: "ISSUE_ASSIGN_PARTNER",
    ISSUE_REMOVE_PARTNER: "ISSUE_REMOVE_PARTNER",
    ISSUE_CREATE: "ISSUE_CREATE",
    ISSUE_UPDATE: "ISSUE_UPDATE",
    ISSUE_CLOSE: "ISSUE_CLOSE",
    ISSUE_REOPEN: "ISSUE_REOPEN",
    ISSUE_VIEW_COMMENTS: "ISSUE_VIEW_COMMENTS",
    ISSUE_COMMENT: "ISSUE_COMMENT",
    DOCUMENT_VIEW_DEFAULT_LIST: "DOCUMENT_VIEW_DEFAULT_LIST",
    DOCUMENT_DOWNLOAD_DEFAULT: "DOCUMENT_DOWNLOAD_DEFAULT",
    DOCUMENT_UPLOAD_DEFAULT: "DOCUMENT_UPLOAD_DEFAULT",
    DOCUMENT_REMOVE_DEFAULT: "DOCUMENT_REMOVE_DEFAULT",
    DOCUMENT_VIEW_TASK_DOCUMENT_LIST: "DOCUMENT_VIEW_TASK_DOCUMENT_LIST",
    DOCUMENT_DOWNLOAD_TASK_DOCUMENT: "DOCUMENT_DOWNLOAD_TASK_DOCUMENT",
    DOCUMENT_UPLOAD_TASK_DOCUMENT: "DOCUMENT_UPLOAD_TASK_DOCUMENT",
    DOCUMENT_REMOVE_TASK_DOCUMENT: "DOCUMENT_REMOVE_TASK_DOCUMENT",
    DOCUMENT_BLOCKCHAIN: "DOCUMENT_BLOCKCHAIN",
    DOCUMENT_VIEW_TASK_DETAILS: "DOCUMENT_VIEW_TASK_DETAILS",
    DOCUMENT_VIEW_DEFAULT_DETAILS: "DOCUMENT_VIEW_DEFAULT_DETAILS",
    SHIPMENT_EXPORT_CSV: "SHIPMENT_EXPORT_CSV",
    SHIPMENT_VIEW_LOG: "SHIPMENT_VIEW_LOG",
    SHIPMENT_EDIT_INFO: "SHIPMENT_EDIT_INFO",
    SHIPMENT_RE_UPLOAD_BC_BL: "SHIPMENT_RE_UPLOAD_BC_BL",
    SHIPMENT_VIEW_INFO: "SHIPMENT_VIEW_INFO",
    FTA_UPLOAD_INVOICES: "FTA_UPLOAD_INVOICES",
    FTA_VIEW_AND_EDIT_EXTRACT_INFO: "FTA_VIEW_AND_EDIT_EXTRACT_INFO",
    FTA_DELETE_INVOICE: "FTA_DELETE_INVOICE",
    FTA_ADD_TO_TASK: "FTA_ADD_TO_TASK",
    FTA_VIEW_SAMPLE_DOC: "FTA_VIEW_SAMPLE_DOC",
    FTA_VIEW_DETAILS: "FTA_VIEW_DETAILS",
    FTA_SEARCH_AGAIN: "FTA_SEARCH_AGAIN",
};

export const PERMISSIONS_LABEL_LIST = {
    SHIPMENT_VIEW_DETAILS: 'organization.shipments.edit-access-permission.view-shipment-detail',
    SHIPMENT_CREATE_LINKED_SHIPMENT: 'organization.shipments.edit-access-permission.create-linked-shipment',
    SHIPMENT_VIEW_LINKED_SHIPMENT :'View linked shipment',
    SHIPMENT_VIEW_SHIPMENT_ON_MAP: 'organization.shipments.edit-access-permission.view-shipment-on-map',
    SHIPMENT_SEARCH_AGAIN: 'organization.shipments.edit-access-permission.search-again',
    SHIPMENT_APPLY_SEARCH_AGAIN: 'organization.shipments.edit-access-permission.apply-search-again',
    SHIPMENT_SAVE_AS_TEMPLATE: 'organization.shipments.edit-access-permission.save-as-template',
    SHIPMENT_MARK_AS_COMPLETE: 'organization.shipments.edit-access-permission.mark-as-complete',
    SHIPMENT_CANCEL: 'organization.shipments.edit-access-permission.cancel-this-shipment',
    SHIPMENT_ADD_COLLABORATORS: 'organization.shipments.edit-access-permission.add-collaborator-shipment',
    SHIPMENT_ADD_SHIPMENT_ADMIN: 'organization.shipments.edit-access-permission.add_shipment_admin',
    SHIPMENT_VIEW_COLLABORATORS: 'organization.shipments.edit-access-permission.view_collaborator',
    SHIPMENT_REMOVE_COLLABORATORS: 'organization.shipments.edit-access-permission.remove_collaborator',
    SHIPMENT_SEARCH_COLLABORATORS: 'organization.shipments.edit-access-permission.search_collaborator',
    SECTION_CREATE: 'organization.shipments.edit-access-permission.create_section',
    SECTION_UPDATE: 'organization.shipments.edit-access-permission.Update_section',
    SECTION_DELETE: 'organization.shipments.edit-access-permission.delete_section',
    TASK_CREATE: 'organization.shipments.edit-access-permission.create_task',
    TASK_VIEW_DETAILS: 'organization.shipments.edit-access-permission.view_task_detail',
    TASK_UPDATE_DETAILS: 'organization.shipments.edit-access-permission.update_task_detail',
    TASK_UPDATE_STATUS_DATE: 'organization.shipments.edit-access-permission.update_status_and_date_task',
    TASK_ASSIGN_PARTNERS: 'organization.shipments.edit-access-permission.assign_partner_to_task',
    TASK_REMOVE_PARTNERS: 'organization.shipments.edit-access-permission.remove_assign_from_task',
    TASK_DELETE: 'organization.shipments.edit-access-permission.delete_task',
    TASK_ADD_EVENT: 'organization.shipments.edit-access-permission.add_event_to_task',
    TASK_ADD_DOCUMENT: 'organization.shipments.edit-access-permission.add_document_to_task',
    TASK_VIEW_LIST: 'organization.shipments.edit-access-permission.view_task_list',
    TASK_VIEW_ASSIGNEE_LIST: 'organization.shipments.edit-access-permission.view_list_assignee',
    ISSUE_VIEW_LIST: 'organization.shipments.edit-access-permission.view_list_issue',
    ISSUE_VIEW_DETAIL: 'organization.shipments.edit-access-permission.view_issue_detail',
    ISSUE_ASSIGN_PARTNER: 'organization.shipments.edit-access-permission.assign_partner_to_issue',
    ISSUE_REMOVE_PARTNER: 'organization.shipments.edit-access-permission.remove_partner_from_issue',
    ISSUE_CREATE: 'organization.shipments.edit-access-permission.create_issue',
    ISSUE_UPDATE: 'organization.shipments.edit-access-permission.update_issue',
    ISSUE_CLOSE: 'organization.shipments.edit-access-permission.close_issue',
    ISSUE_REOPEN: 'organization.shipments.edit-access-permission.re_open_issue',
    ISSUE_VIEW_COMMENTS: 'organization.shipments.edit-access-permission.view_comment_in_issue',
    ISSUE_COMMENT: 'organization.shipments.edit-access-permission.comment_in_issue',
    DOCUMENT_VIEW_DEFAULT_LIST: 'organization.shipments.edit-access-permission.view_default_document_list',
    DOCUMENT_DOWNLOAD_DEFAULT: 'organization.shipments.edit-access-permission.download_default_document',
    DOCUMENT_UPLOAD_DEFAULT: 'organization.shipments.edit-access-permission.update_default_document',
    DOCUMENT_REMOVE_DEFAULT: 'organization.shipments.edit-access-permission.remove_default_document',
    DOCUMENT_VIEW_TASK_DOCUMENT_LIST: 'organization.shipments.edit-access-permission.view_task_document_list',
    DOCUMENT_DOWNLOAD_TASK_DOCUMENT: 'organization.shipments.edit-access-permission.download_task_document',
    DOCUMENT_UPLOAD_TASK_DOCUMENT: 'organization.shipments.edit-access-permission.upload_task_document',
    DOCUMENT_REMOVE_TASK_DOCUMENT: 'organization.shipments.edit-access-permission.remove_task_document',
    DOCUMENT_BLOCKCHAIN: 'organization.shipments.edit-access-permission.blockchain',
    DOCUMENT_VIEW_TASK_DETAILS: 'organization.shipments.edit-access-permission.view_document_task_detail',
    DOCUMENT_VIEW_DEFAULT_DETAILS: 'organization.shipments.edit-access-permission.view_default_document_detail',
    SHIPMENT_EXPORT_CSV: 'organization.shipments.edit-access-permission.export_csv_file',
    SHIPMENT_VIEW_LOG: 'organization.shipments.edit-access-permission.view_shipment_log',
    SHIPMENT_EDIT_INFO: 'organization.shipments.edit-access-permission.edit_shipment_information',
    SHIPMENT_RE_UPLOAD_BC_BL: 'organization.shipments.edit-access-permission.re_upload_BC/BL',
    SHIPMENT_VIEW_INFO: 'organization.shipments.edit-access-permission.view_shipment_information',
    FTA_UPLOAD_INVOICES: 'organization.shipments.edit-access-permission.upload_invoices',
    FTA_VIEW_AND_EDIT_EXTRACT_INFO: 'organization.shipments.edit-access-permission.view_and_edit_extract_info',
    FTA_DELETE_INVOICE: 'organization.shipments.edit-access-permission.delete_invoice',
    FTA_ADD_TO_TASK: 'organization.shipments.edit-access-permission.add_to_task',
    FTA_VIEW_SAMPLE_DOC: 'organization.shipments.edit-access-permission.view_sample_doc',
    FTA_VIEW_DETAILS: 'organization.shipments.edit-access-permission.view_detail',
    FTA_SEARCH_AGAIN: 'organization.shipments.edit-access-permission.search_again',
}

export const USER_ROLES = {
    MANAGER: 'TASK_MANAGER',
    EDITOR: 'TASK_EDITOR',
    VIEWER: "TASK_VIEWER",
    OPERATOR: "TASK_OPERATOR",
    CREATOR: "ISSUE_CREATOR",
    ASSIGNEE: "ISSUE_ASSIGNEE",
    ORG_OWNER: "ORG_OWNER",
    ORG_ADMIN: "ORG_ADMIN",
    SHIPMENT_ADMIN: "SHIPMENT_ADMIN",
    SHIPPER: "SHIPPER",
    CONSIGNEE: "CONSIGNEE",
    FREIGHT: "FREIGHT",
    EXPORT_LOGISTICS: "EXPORT_LOGISTICS",
    IMPORT_LOGISTICS: "IMPORT_LOGISTICS",
    EXPORT_CUSTOMS: "EXPORT_CUSTOMS",
    IMPORT_CUSTOMS: "IMPORT_CUSTOMS"
}

export const documentsSubTypes = [
  {
    value: 'booking-confirmation',
  },
  {
    value: 'notice-of-arrival',
  },
  {
    value: 'master-bill-of-lading',
  },
  {
    value: 'house-bill-of-lading',
  },
  {
    value: 'shipping-invoice',
  },
  {
    value: 'packing-list',
  },
  {
    value: 'custom-clearance-permit',
  },
  {
    value: 'certificate-of-origin',
  },
  {
    value: 'photo-of-Cargo',
  },
  {
    value: 'forwarding-agent-node',
  },
  {
    value: 'proof-of-delivery',
  },
  {
    value: 'delivery-order',
  },
  {
    value: 'custom-document-type',
  },
]

export const PERMISSIONS_ROLE_DISABLED = [
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_VIEW_DETAILS' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_VIEW_DETAILS' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_VIEW_DETAILS' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_VIEW_DETAILS' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_VIEW_DETAILS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_VIEW_DETAILS' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_CREATE_LINKED_SHIPMENT' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_CREATE_LINKED_SHIPMENT' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_CREATE_LINKED_SHIPMENT' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_CREATE_LINKED_SHIPMENT' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_CREATE_LINKED_SHIPMENT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_CREATE_LINKED_SHIPMENT' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_VIEW_LINKED_SHIPMENT' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_VIEW_LINKED_SHIPMENT' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_VIEW_LINKED_SHIPMENT' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_VIEW_LINKED_SHIPMENT' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_VIEW_LINKED_SHIPMENT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_VIEW_LINKED_SHIPMENT' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_VIEW_SHIPMENT_ON_MAP' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_VIEW_SHIPMENT_ON_MAP' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_VIEW_SHIPMENT_ON_MAP' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_VIEW_SHIPMENT_ON_MAP' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_VIEW_SHIPMENT_ON_MAP' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_VIEW_SHIPMENT_ON_MAP' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_SEARCH_AGAIN' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_SEARCH_AGAIN' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_SEARCH_AGAIN' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_SEARCH_AGAIN' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_SEARCH_AGAIN' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_SEARCH_AGAIN' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_APPLY_SEARCH_AGAIN' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_APPLY_SEARCH_AGAIN' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_APPLY_SEARCH_AGAIN' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_APPLY_SEARCH_AGAIN' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_APPLY_SEARCH_AGAIN' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_APPLY_SEARCH_AGAIN' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_SAVE_AS_TEMPLATE' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_SAVE_AS_TEMPLATE' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_SAVE_AS_TEMPLATE' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_SAVE_AS_TEMPLATE' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_SAVE_AS_TEMPLATE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_SAVE_AS_TEMPLATE' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_MARK_AS_COMPLETE' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_MARK_AS_COMPLETE' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_MARK_AS_COMPLETE' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_MARK_AS_COMPLETE' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_MARK_AS_COMPLETE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_MARK_AS_COMPLETE' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_CANCEL' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_CANCEL' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_CANCEL' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_CANCEL' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_CANCEL' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_CANCEL' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_ADD_COLLABORATORS' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_ADD_COLLABORATORS' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_ADD_COLLABORATORS' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_ADD_COLLABORATORS' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_ADD_COLLABORATORS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_ADD_COLLABORATORS' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_ADD_SHIPMENT_ADMIN' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_ADD_SHIPMENT_ADMIN' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_ADD_SHIPMENT_ADMIN' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_ADD_SHIPMENT_ADMIN' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_ADD_SHIPMENT_ADMIN' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_ADD_SHIPMENT_ADMIN' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_VIEW_COLLABORATORS' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_VIEW_COLLABORATORS' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_VIEW_COLLABORATORS' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_VIEW_COLLABORATORS' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_VIEW_COLLABORATORS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_VIEW_COLLABORATORS' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_REMOVE_COLLABORATORS' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_REMOVE_COLLABORATORS' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_REMOVE_COLLABORATORS' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_REMOVE_COLLABORATORS' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_REMOVE_COLLABORATORS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_REMOVE_COLLABORATORS' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_SEARCH_COLLABORATORS' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_SEARCH_COLLABORATORS' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_SEARCH_COLLABORATORS' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_SEARCH_COLLABORATORS' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_SEARCH_COLLABORATORS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_SEARCH_COLLABORATORS' },
    { COL: 'TASK_MANAGER', ROW : 'SECTION_CREATE' },
    { COL: 'TASK_EDITOR', ROW : 'SECTION_CREATE' },
    { COL: 'TASK_VIEWER', ROW : 'SECTION_CREATE' },
    { COL: 'TASK_OPERATOR', ROW : 'SECTION_CREATE' },
    { COL: 'ISSUE_CREATOR', ROW : 'SECTION_CREATE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SECTION_CREATE' },
    { COL: 'TASK_MANAGER', ROW : 'SECTION_UPDATE' },
    { COL: 'TASK_EDITOR', ROW : 'SECTION_UPDATE' },
    { COL: 'TASK_VIEWER', ROW : 'SECTION_UPDATE' },
    { COL: 'TASK_OPERATOR', ROW : 'SECTION_UPDATE' },
    { COL: 'ISSUE_CREATOR', ROW : 'SECTION_UPDATE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SECTION_UPDATE' },
    { COL: 'TASK_MANAGER', ROW : 'SECTION_DELETE' },
    { COL: 'TASK_EDITOR', ROW : 'SECTION_DELETE' },
    { COL: 'TASK_VIEWER', ROW : 'SECTION_DELETE' },
    { COL: 'TASK_OPERATOR', ROW : 'SECTION_DELETE' },
    { COL: 'ISSUE_CREATOR', ROW : 'SECTION_DELETE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SECTION_DELETE' },
    { COL: 'TASK_MANAGER', ROW : 'TASK_CREATE' },
    { COL: 'TASK_EDITOR', ROW : 'TASK_CREATE' },
    { COL: 'TASK_VIEWER', ROW : 'TASK_CREATE' },
    { COL: 'TASK_OPERATOR', ROW : 'TASK_CREATE' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_CREATE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_CREATE' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_VIEW_DETAILS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_VIEW_DETAILS' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_UPDATE_DETAILS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_UPDATE_DETAILS' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_UPDATE_STATUS_DATE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_UPDATE_STATUS_DATE' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_ASSIGN_PARTNERS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_ASSIGN_PARTNERS' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_REMOVE_PARTNERS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_REMOVE_PARTNERS' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_DELETE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_DELETE' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_ADD_EVENT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_ADD_EVENT' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_ADD_DOCUMENT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_ADD_DOCUMENT' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_VIEW_LIST' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_VIEW_LIST' },
    { COL: 'ISSUE_CREATOR', ROW : 'TASK_VIEW_ASSIGNEE_LIST' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'TASK_VIEW_ASSIGNEE_LIST' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_VIEW_LIST' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_VIEW_LIST' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_VIEW_LIST' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_VIEW_LIST' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_VIEW_DETAIL' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_VIEW_DETAIL' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_VIEW_DETAIL' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_VIEW_DETAIL' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_ASSIGN_PARTNER' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_ASSIGN_PARTNER' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_ASSIGN_PARTNER' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_ASSIGN_PARTNER' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_REMOVE_PARTNER' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_REMOVE_PARTNER' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_REMOVE_PARTNER' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_REMOVE_PARTNER' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_CREATE' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_CREATE' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_CREATE' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_CREATE' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_UPDATE' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_UPDATE' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_UPDATE' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_UPDATE' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_CLOSE' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_CLOSE' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_CLOSE' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_CLOSE' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_REOPEN' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_REOPEN' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_REOPEN' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_REOPEN' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_VIEW_COMMENTS' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_VIEW_COMMENTS' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_VIEW_COMMENTS' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_VIEW_COMMENTS' },
    { COL: 'TASK_MANAGER', ROW : 'ISSUE_COMMENT' },
    { COL: 'TASK_EDITOR', ROW : 'ISSUE_COMMENT' },
    { COL: 'TASK_VIEWER', ROW : 'ISSUE_COMMENT' },
    { COL: 'TASK_OPERATOR', ROW : 'ISSUE_COMMENT' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_VIEW_DEFAULT_LIST' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_VIEW_DEFAULT_LIST' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_DOWNLOAD_DEFAULT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_DOWNLOAD_DEFAULT' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_UPLOAD_DEFAULT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_UPLOAD_DEFAULT' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_REMOVE_DEFAULT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_REMOVE_DEFAULT' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_VIEW_TASK_DOCUMENT_LIST' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_VIEW_TASK_DOCUMENT_LIST' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_DOWNLOAD_TASK_DOCUMENT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_DOWNLOAD_TASK_DOCUMENT' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_UPLOAD_TASK_DOCUMENT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_UPLOAD_TASK_DOCUMENT' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_REMOVE_TASK_DOCUMENT' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_REMOVE_TASK_DOCUMENT' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_BLOCKCHAIN' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_BLOCKCHAIN' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_VIEW_TASK_DETAILS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_VIEW_TASK_DETAILS' },
    { COL: 'ISSUE_CREATOR', ROW : 'DOCUMENT_VIEW_DEFAULT_DETAILS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'DOCUMENT_VIEW_DEFAULT_DETAILS' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_EXPORT_CSV' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_EXPORT_CSV' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_EXPORT_CSV' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_EXPORT_CSV' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_EXPORT_CSV' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_EXPORT_CSV' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_VIEW_LOG' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_VIEW_LOG' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_VIEW_LOG' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_VIEW_LOG' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_VIEW_LOG' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_VIEW_LOG' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_VIEW_INFO' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_VIEW_INFO' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_VIEW_INFO' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_VIEW_INFO' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_VIEW_INFO' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_VIEW_INFO' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_RE_UPLOAD_BC_BL' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_RE_UPLOAD_BC_BL' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_RE_UPLOAD_BC_BL' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_RE_UPLOAD_BC_BL' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_RE_UPLOAD_BC_BL' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_RE_UPLOAD_BC_BL' },
    { COL: 'TASK_MANAGER', ROW : 'SHIPMENT_EDIT_INFO' },
    { COL: 'TASK_EDITOR', ROW : 'SHIPMENT_EDIT_INFO' },
    { COL: 'TASK_VIEWER', ROW : 'SHIPMENT_EDIT_INFO' },
    { COL: 'TASK_OPERATOR', ROW : 'SHIPMENT_EDIT_INFO' },
    { COL: 'ISSUE_CREATOR', ROW : 'SHIPMENT_EDIT_INFO' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'SHIPMENT_EDIT_INFO' },
    { COL: 'TASK_MANAGER', ROW : 'FTA_UPLOAD_INVOICES' },
    { COL: 'TASK_EDITOR', ROW : 'FTA_UPLOAD_INVOICES' },
    { COL: 'TASK_VIEWER', ROW : 'FTA_UPLOAD_INVOICES' },
    { COL: 'TASK_OPERATOR', ROW : 'FTA_UPLOAD_INVOICES' },
    { COL: 'ISSUE_CREATOR', ROW : 'FTA_UPLOAD_INVOICES' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'FTA_UPLOAD_INVOICES' },
    { COL: 'TASK_MANAGER', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'TASK_EDITOR', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'TASK_VIEWER', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'TASK_OPERATOR', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'ISSUE_CREATOR', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'TASK_MANAGER', ROW : 'FTA_VIEW_AND_EDIT_EXTRACT_INFO' },
    { COL: 'TASK_EDITOR', ROW : 'FTA_VIEW_AND_EDIT_EXTRACT_INFO' },
    { COL: 'TASK_VIEWER', ROW : 'FTA_VIEW_AND_EDIT_EXTRACT_INFO' },
    { COL: 'TASK_OPERATOR', ROW : 'FTA_VIEW_AND_EDIT_EXTRACT_INFO' },
    { COL: 'ISSUE_CREATOR', ROW : 'FTA_VIEW_AND_EDIT_EXTRACT_INFO' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'FTA_VIEW_AND_EDIT_EXTRACT_INFO' },
    { COL: 'TASK_MANAGER', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'TASK_EDITOR', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'TASK_VIEWER', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'TASK_OPERATOR', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'ISSUE_CREATOR', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'FTA_DELETE_INVOICE' },
    { COL: 'TASK_MANAGER', ROW : 'FTA_ADD_TO_TASK' },
    { COL: 'TASK_EDITOR', ROW : 'FTA_ADD_TO_TASK' },
    { COL: 'TASK_VIEWER', ROW : 'FTA_ADD_TO_TASK' },
    { COL: 'TASK_OPERATOR', ROW : 'FTA_ADD_TO_TASK' },
    { COL: 'ISSUE_CREATOR', ROW : 'FTA_ADD_TO_TASK' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'FTA_ADD_TO_TASK' },
    { COL: 'TASK_MANAGER', ROW : 'FTA_VIEW_SAMPLE_DOC' },
    { COL: 'TASK_EDITOR', ROW : 'FTA_VIEW_SAMPLE_DOC' },
    { COL: 'TASK_VIEWER', ROW : 'FTA_VIEW_SAMPLE_DOC' },
    { COL: 'TASK_OPERATOR', ROW : 'FTA_VIEW_SAMPLE_DOC' },
    { COL: 'ISSUE_CREATOR', ROW : 'FTA_VIEW_SAMPLE_DOC' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'FTA_VIEW_SAMPLE_DOC' },
    { COL: 'TASK_MANAGER', ROW : 'FTA_VIEW_DETAILS' },
    { COL: 'TASK_EDITOR', ROW : 'FTA_VIEW_DETAILS' },
    { COL: 'TASK_VIEWER', ROW : 'FTA_VIEW_DETAILS' },
    { COL: 'TASK_OPERATOR', ROW : 'FTA_VIEW_DETAILS' },
    { COL: 'ISSUE_CREATOR', ROW : 'FTA_VIEW_DETAILS' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'FTA_VIEW_DETAILS' },
    { COL: 'TASK_MANAGER', ROW : 'FTA_SEARCH_AGAIN' },
    { COL: 'TASK_EDITOR', ROW : 'FTA_SEARCH_AGAIN' },
    { COL: 'TASK_VIEWER', ROW : 'FTA_SEARCH_AGAIN' },
    { COL: 'TASK_OPERATOR', ROW : 'FTA_SEARCH_AGAIN' },
    { COL: 'ISSUE_CREATOR', ROW : 'FTA_SEARCH_AGAIN' },
    { COL: 'ISSUE_ASSIGNEE', ROW : 'FTA_SEARCH_AGAIN' },
]

export const PERMISSION_ERROR = "You don't have permission to perform this action";
