import { Input, Modal } from "components/common"
import { useForm } from "react-hook-form"

export const EndorseOwnership = ({open, onCancel}) => {

    const { handleSubmit } = useForm();

    const handlConfirm = () => {
        console.log("Hanh Nguyen");
    }

    return (
        <Modal
            title="Endorse Ownership"
            open={open}
            className="tr__Endorse_Ownership"
            onCancel={onCancel}
            submitButton
            submitButtonLabel="Comfirm"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(handlConfirm)}>
                    {children}
                </form>
            )}

        >
            <Input
                label="Endorsing ownership to this account *"
                defaultValue="string"
                disabled={true}
                name="owner_account_address"
            />

        </Modal>
    )
}

