import React, { useContext, useEffect } from 'react';
import moment from 'moment';

import './index.scss';
import {
    Collapse,
    Button,
    Dropdown,
    ShipmentStep
} from 'components';
import { useHistory, useParams } from 'react-router-dom';
import {
    MARK_TRACK_SHIPMENT,
    SHIPMENT_DETAILS_TEMPLATE,
    SHIPPING_TRACKER,
    TRACKER_BOOKING_BK,
    TRACKER_BOOKING_BL,
    TRACKER_BOOKING_BC,
    AIR
} from 'actions';
import { HomeContext, TrackShipmentContext, ActiveShipmentsContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const SearchResults = ({ searchParams, setSearched, result, setIsTrackerSubmitted, className, shipmentInfo, setSearchType, typeShipment }) => {
    const history = useHistory();
    const { shipmentId } = useParams();
    const { setTrackerTemplate, setMarkAsShipmentType, typeOfShipment } = useContext(HomeContext);
    const { t } = useTranslation();
    const {
        trackers,
        selectedTracker,
        setCarriers,
        firstCarriers,
        trackerParams,
        setSelectedTracker,
        doGetRoute,
        setTrackers,
        setRoutes,
        doGetTrackAndTraceV2,
        tracker,
    } = useContext(TrackShipmentContext);
    const { doGetSearchParams } = useContext(ActiveShipmentsContext);
    const handleChangeOptions = (value) => {
        if (trackers && trackers.length > 0) {
            const option = trackers.find(result => result.containerId === value.key);
            setSelectedTracker(option);

            doGetRoute({
                imo: option.vessels[0].imo,
                originPort: option.prePol.location.locode,
                destinationPort: option.postPod.location.locode
            })
        }
    }

    const handleSearchAgain = () => {
        if (setIsTrackerSubmitted) setIsTrackerSubmitted(false);
        setTrackers([]);
        setRoutes(null);
        setCarriers(firstCarriers);
        setSearched(false);
    }

    const handleMarkAsActiveShipment = () => {
        if (selectedTracker) {
            setMarkAsShipmentType(MARK_TRACK_SHIPMENT);
            setTrackerTemplate(selectedTracker);
            history.push({
                pathname: SHIPMENT_DETAILS_TEMPLATE.replace(':type', SHIPPING_TRACKER),
                state: { typeOfShipment }
            });
        }
    }

    /*eslint-disable */
    useEffect(() => {
            doGetSearchParams(shipmentId, (data) => {
                
                if (!data) {
                    let payload = {
                    }
                    switch (shipmentInfo?.searchParameter?.type) {
                        case TRACKER_BOOKING_BK:
                            payload = {
                                type: TRACKER_BOOKING_BK,
                                scac: shipmentInfo?.searchParameter?.parameter?.carrierNo,
                                trackingNo: shipmentInfo?.searchParameter?.parameter?.bkNum,
                            }
                            break;
                        case TRACKER_BOOKING_BL:
                            payload = {
                                type: TRACKER_BOOKING_BL,
                                scac: shipmentInfo?.searchParameter?.parameter?.carrierNo,
                                trackingNo: shipmentInfo?.searchParameter?.parameter?.blNum,
                            }
                            break;
                        case TRACKER_BOOKING_BC:
                            payload = {
                                type: TRACKER_BOOKING_BC,
                                scac: shipmentInfo?.searchParameter?.parameter?.carrierNo,
                                trackingNo: shipmentInfo?.searchParameter?.parameter?.carrierNo,
                            }
                            break;    
                        default:
                            payload = {}
                            break;
                    }
                    doGetTrackAndTraceV2(payload
                            , () => {}
                            , () => {}
                            , false);
                } else {
                    const { requestType, billOfLadingNumber, bookingConfirmNumber, carrier, containerNumber} = data;
                    setSearchType(data);
                    let params = {}
                    switch (requestType) {
                        case TRACKER_BOOKING_BK:
                            params = {
                                type: TRACKER_BOOKING_BK,
                                scac: carrier,
                                trackingNo: bookingConfirmNumber,
                            }
                            break;
                        case TRACKER_BOOKING_BL:
                            params = {
                                type: TRACKER_BOOKING_BL,
                                scac: carrier,
                                trackingNo: billOfLadingNumber
        
                            }
                            break;
                        case TRACKER_BOOKING_BC:
                            params = {
                                type: TRACKER_BOOKING_BC,
                                scac: carrier,
                                trackingNo: containerNumber,
                                // originCode: origin,
                                // destCode: destination
                            }
                            break;
                        default:
                            params = {}
                            break;
                    }
                    doGetTrackAndTraceV2(params
                        , () => { }
                        , () => { }
                        , false);
                }
            }, false);        
    }, [shipmentInfo])
    /*eslint-enable */

   

    return (
        <div className={`${className} tr__search-result`}>
            <div className="form-group">
                {searchParams && (
                    <>
                        <div className='tr__search-result--header mbx2 d-flex align-center justify-content-between'>
                            <p className="tr__link" onClick={handleSearchAgain}>{t('trackSearchResult.searchAgain')}</p>
                            <i className="icon-times" onClick={handleSearchAgain} />
                        </div>
                        <Collapse title={t('trackSearchResult.searchFor')}>
                            <div className="tr__search-params">
                                <div className="param-row d-flex flex-wrap">
                                    {searchParams.slice(0, 1).map((param, paramIndex) => (
                                        <div
                                            key={paramIndex}
                                            className={`tr__search-params--item ${param.block ? 'block' : ''}`}
                                        >
                                            <p className="f-medium mbx1">{typeShipment?.key === AIR ? 'MAWB no' :result}</p>
                                            <h6 className="h6">{ trackers[0]?.containerId || trackerParams[2]}</h6>
                                        </div>
                                    ))}
                                    {searchParams.slice(1, 2).map((param, paramIndex) => (
                                        <div
                                            key={paramIndex}
                                            className={`tr__search-params--item ${param.block ? 'block' : ''}`}
                                        >
                                            <p className="f-medium mbx1">{typeShipment?.key === AIR ? t('trackSearchResult.airLineName') : t('trackSearchResult.carrier')}</p>
                                            <h6 className="h6">{typeShipment?.key === AIR ? (tracker?.otherData?.airline_info?.name) : trackerParams[1]}</h6>
                                        </div>
                                    ))}
                                </div>
                                <div className="param-row d-flex flex-wrap">
                                    {searchParams.slice(2, 3).map((param, paramIndex) => (
                                        <div
                                            key={paramIndex}
                                            className={`tr__search-params--item ${param.block ? 'block' : ''}`}
                                        >
                                            <p className="f-medium mbx1">{typeShipment?.key === AIR ? t('trackSearchResult.airPorts') : t('trackSearchResult.port')}</p>
                                            {trackers && trackers.length > 0 && (
                                                <h6 className="h6">{`${trackers[0].pol.title} - ${trackers[0].pod.title}`}</h6>
                                            )}

                                        </div>
                                    ))}
                                </div>
                                {trackers && trackers.length > 0 && trackers[0].pol.date && <div className="param-row d-flex">
                                    <div
                                        className={`tr__search-params--item block`}
                                    >
                                        <p className="f-medium mbx1">{t('trackSearchResult.vesselETD')}</p>
                                        {trackers && trackers.length > 0 && trackers[0].pol.date && (
                                            <h6 className="h6">{`${moment(trackers[0].pol.date).format('DD MMM YYYY | hh:mm A')}`}</h6>
                                        )}
                                    </div>
                                    <div
                                        className={`tr__search-params--item block`}
                                    >
                                        <p className="f-medium mbx1">{t('trackSearchResult.vesselETA')}</p>
                                        {trackers && trackers.length > 0 && trackers[0].pol.date && (
                                            <h6 className="h6">{`${moment(trackers[0].pod.date).format('DD MMM YYYY | hh:mm A')}`}</h6>
                                        )}
                                    </div>
                                </div>}
                            </div>
                        </Collapse>
                    </>
                )}
                {result !== "Container no" && typeShipment?.key !== AIR && (
                    <>
                        <p className="f-medium mbx1 mtx3 plx1">{`${trackers.length} ${trackers.length > 1 ? t('trackshipment.containers') : t('trackshipment.container')} `}</p>
                        <div className="tr__search-result--containers mtbx2">
                            <Dropdown
                                className="select"
                                icon="icon-chevron-down"
                                iconPosition="right"
                                mode="select"
                                name="container"
                                label={t('trackSearchResult.selectContainer')}
                                placeholder={t('trackSearchResult.select.placehoder')}
                                options={trackers && trackers.length > 0 ? trackers.map(container => {
                                    return {
                                        key: container.containerId,
                                        value: `${container.containerId}`
                                    }
                                }) : []}
                                defaultValue={selectedTracker ? selectedTracker.containerId : ''}
                                dropdownPosition="center"
                                onChange={handleChangeOptions}
                            />
                        </div>
                    </>
                )}

                <div className="tr__search-result--process mtbx2">
                    {selectedTracker && (
                        <>
                            <ShipmentStep step={selectedTracker.prePol} />
                            <ShipmentStep step={selectedTracker.pol} titleAirPosts="AIRPORTS"/>
                            <ShipmentStep step={selectedTracker.pod} titleAirPosts="AIRPORTS"/>
                            <ShipmentStep step={selectedTracker.postPod} />
                        </>
                    )}
                </div>
                
                <div>

                </div>
            </div>
            {searchParams && (
                <div className="form-btns">
                    <Button
                        isSubmitBtn={true}
                        onClick={handleMarkAsActiveShipment}
                        disabled={typeOfShipment.key === AIR}
                    >
                        <span>{t('trackSearchResult.markActive')}</span>
                    </Button>
                    
                </div>
            )}
        </div>
    )
}
