import { Dropdown, Input } from "components/common";
import { HomeContext } from "contexts";
import { useContext, useState } from "react";
import './index.scss';


export const EthereumForm = ({wallets, setPayload, payload, hanldSearchAccount, handleSearchOwner, handleSearchHolder, setWallets, walletsBackUp}) => {
    const [partyAccount, setPartyAccount] = useState();
    const [ownerAccount, setOwnerAccount] = useState();
    const [holderAccount, setHolderAccount] = useState();
    const [pwdToggle, setPwdToggle] = useState(false);
    const { shipment } = useContext(HomeContext);
    // const { shipment } = useContext(HomeContext);
    // console.log(shipment);

    const setAccountInvokingParty = (option) => {
        setPartyAccount(option);
        setPayload({...payload, partyAccount: option})
    }

    const handleSearchAccountInvokingParty = (value) => {
        hanldSearchAccount(value);
    }

    const handlChangePassword = (e) => {
        setPayload({...payload, password: e.target.value})
    }

    const hanldOnClickIcon = (e) => {
        setPwdToggle(!pwdToggle);
    }

    return (
        <div className="tr__ethereunForm">
            <div className="row">
                <div className="col-12">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="invoking_paty_account"
                        label={"Invoking party account *"}
                        options={wallets.filter(x => x.orgId === shipment?.orgId).map(type => {
                            return {
                                _id: type._id,
                                address: type.address,
                                value: type.value
                            }
                        })}
                        defaultValue={''}
                        onChange={option => {
                            setAccountInvokingParty(option);
                            setWallets(walletsBackUp);
                        }}
                        onInputChange={handleSearchAccountInvokingParty}
                        placeholder="Invoking party account"
                        dropdownPosition="center"
                    />
                </div>
                <div className="col-12">
                    <Input
                        label="Invoking party account address *"
                        defaultValue={partyAccount ? partyAccount.address : "string"}
                        disabled={true}
                        name="invoking_paty_account_address"
                    />
                </div>
                <div className="col-12">
                    <Input
                        label="Invoking party wallets password *"
                        type={!pwdToggle ? 'password' : 'text'}
                        onChange={handlChangePassword}
                        name="invoking_paty_account_password"
                        icon={pwdToggle ? "icon-eye-slash" : "icon-eye"}
                        iconPosition="right"
                        onIconClick={hanldOnClickIcon}
                        defaultValue=""
                    />
                </div>
                <div className="col-12">
                    <Dropdown
                        icon="icon-chevron-down"
                        iconPosition="right"
                        label="Owner account*"
                        defaultValue=""
                        mode="input"
                        dropdownPosition="center"
                        options={wallets.map(type => {
                            return {
                                _id: type._id,
                                address: type.address,
                                value: type.value
                            }
                        })}
                        onChange={option => {
                            setOwnerAccount(option);
                            setPayload({...payload, ownerAccount: option});
                            setWallets(walletsBackUp);
                        }}
                        onInputChange={handleSearchOwner}
                        placeholder="Owner account"
                        name="owner_account"
                    />
                </div>
                <div className="col-12">
                    <Input
                        label="Owner account address *"
                        defaultValue={ownerAccount ? ownerAccount.address : "string"}
                        disabled={true}
                        name="owner_account_address"
                    />
                </div>
                <div className="col-12">
                    <Dropdown
                        icon="icon-chevron-down"
                        iconPosition="right"
                        label="Holder account*"
                        defaultValue=""
                        mode="input"
                        dropdownPosition="center"
                        options={wallets.map(type => {
                            return {
                                _id: type._id,
                                address: type.address,
                                value: type.value
                            }
                        })}
                        onChange={option => {
                            setHolderAccount(option);
                            setPayload({...payload, holderAccount: option});
                            setWallets(walletsBackUp);
                        }}
                        onInputChange={handleSearchHolder}
                        placeholder="Holder account"
                        account="holder_account"
                    />
                </div>
                <div className="col-12">
                    <Input
                        label="Holder account address *"
                        defaultValue={holderAccount ? holderAccount.address : "string"}
                        disabled={true}
                        name="holder_account_address"
                    />
                </div>
            </div>
        </div>
    )
}