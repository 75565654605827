import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { useForm } from 'react-hook-form';

import {
    Modal,
    InviteMembers
} from 'components';
import {
    ActiveShipmentsContext,
    HomeContext,
    MemberContext,
    ModalContext,
    OrganizationContext,
    TeamContext
} from 'contexts';
import { TEAM } from 'actions';
import { useTranslation } from 'react-i18next';

export const AddMember = ({ open = false, onCancel }) => {
	const [t] = useTranslation();
    const { setNotificationMessage } = useContext(HomeContext);
    const { setAddMember } = useContext(ModalContext);
    const { organization } = useContext(OrganizationContext);
    const { team } = useContext(TeamContext);
    const { partners, doGetPartners } = useContext(ActiveShipmentsContext);
    const { allMembers, doAddMember, setAllMembers, members } = useContext(MemberContext);

    const { handleSubmit, register, errors, formState, reset, getValues } = useForm();
    const [mems, setMems] = useState([]);
    const [memberLoading] = useState(false);
    const [tempAllMembers, setTempAllMembers] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        if (open) doGetPartners();
    }, [open])

    useEffect(() => {
        if (!tempAllMembers.length) {
            setTempAllMembers(allMembers);
        }
    }, [allMembers])

    useEffect(() => {
        if (tempAllMembers.length > 0) setAllMembers(tempAllMembers);
    }, [mems])
    /*eslint-enable */

    const handleAddTeam = memId => {
        setMems(oldMems => [...oldMems.map(mem => {
            if (mem.id === memId) {
                return {
                    ...mem,
                    teams: [
                        ...mem.teams,
                        {
                            id: null,
                            role: null
                        }
                    ]
                }
            }
            return mem;
        })]);
    }

    const addMember = data => {
        if (!mems.length) return;

        if (organization && !team) {
            const payload = {
                orgId: organization._id,
                members: mems.map(mem => {
                    const newMem = {
                        email: mem.id ? mem.description : mem.title,
                        orgRole: data[`orgRole${mem.id}`]
                    }
                    if (!mem.isInviteEmail) {
                        newMem['memberId'] = mem.id
                    }
                    if (mem.teams && mem.teams.length > 0) {
                        newMem.teamRoles = mem.teams.map((team, teamIndex) => {
                            return {
                                teamId: data[`team${teamIndex}${mem.id}`],
                                roleName: data[`teamRole${teamIndex}${mem.id}`]
                            }
                        })
                    }
                    return newMem
                })
            }
    
            doAddMember(payload, () => {
                reset();
                setAddMember(false);
                setMems([]);
                setNotificationMessage(`
                    <p>${t("member.mess_add_member")}</p>
                `);
            });
        }

        if (team) {
            const payload = {
                members: mems.map(mem => {
                    const newMem = {
                        email:  mem.description,
                        teamRoles: [
                            {
                                teamId: team._id,
                                roleName: data[`teamRole${mem.id}`]
                            }
                        ]
                    }
                    if(!mem.isInviteEmail) {
                        newMem['memberId'] = mem.id
                    }
                    return newMem;
                })
            }
    
            doAddMember(payload, () => {
                reset();
                setAddMember(false);
                setMems([]);
                setNotificationMessage(`
                    <p>${t("member.mess_add_member")}</p>
                `);
            });
        }
    }

    const onHideAddMemberModal = () => {
        reset();
        setMems([]);
        onCancel();
    }

    const getMembers = value => {
        doGetPartners({name : value.trim()});
        // setMemberLoading(true);

        // doGetMembers({
        //     term: value,
        //     page: 0,
        //     limit: MEMBER_PAGE_SIZE,
        //     isSearching: true,
        //     sort: 'name_asc',
        //     listing: true
        // }, () => {
        //     setMemberLoading(false);
        // }, false, false);
        
    };

    const handleAddItem = () => {
        if (tempAllMembers.length > 0) setAllMembers(tempAllMembers);
    }

    return (
        <Modal
            open={open}
            className="tr__add-member"
            onCancel={onHideAddMemberModal}
            title={`${t("member.addNewMemberTo")} ${organization ? `"${organization.name}"` : (team ? `"${team.name}"` : '')}`}
            submitButton
            submitButtonLabel={t("organization.add")}
            footerLabel={t("team.titleFooterTeam")}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(addMember)}>
                    {children}
                </form>
            )}
        >
            <InviteMembers
                title={t("team.inviteMembers")}
                isMember={true}
                register={register}
                members={partners.filter(mem => (!(members.some(m => m._id === mem._id) || mems.some(m => m.id === mem._id))) && mem !== TEAM)}
                // members={allMembers}
                errors={errors}
                selectedMembers={mems}
                setSelectedMembers={setMems}
                onInputChange={getMembers}
                onAddItem={handleAddItem}
                searchLoading={memberLoading}
                formState={formState}
                handleAddTeam={handleAddTeam}
                getValues={getValues}
            />
        </Modal>
    )
};