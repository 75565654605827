import {
    Modal,
} from 'components';
import { ActiveShipmentsContext, AuthContext, HomeContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EthereumForm } from '../components';
export const EthereumDocument = ({ open, onCancel }) => {
    const [listWallets, setListWallets] = useState([]);
    const [walletsBackUp, setWalletsBackUp] = useState([]);
    const [payload, setPayload] = useState({});
    const { shipment, idDocumentSignature, setReloadDocument } = useContext(HomeContext);
    const { doGetwallets, doGenerateEthereum, doGetEthereumDetail, wallets } = useContext(ActiveShipmentsContext);
    const { userInfo } = useContext(AuthContext);
    const { handleSubmit } = useForm();

    /*eslint-disable */
    useEffect(() => {
        doGetwallets((data) => {
            console.log(data);
            const dataNew = data?.map(item => {
                let itemNew = {};
                itemNew._id = item._id;
                itemNew.value = item.accountName;
                itemNew.address = item.encWallet.address;
                itemNew.orgId = item.orgId;
                return itemNew;
            })
            setListWallets(dataNew);
            setWalletsBackUp(dataNew);
        })
    }, [open === true]);
    /*eslint-enable */

    const hanldSearchAccount = (value) => {
        setListWallets(walletsBackUp.filter(x => x?.value?.toLowerCase().includes(value?.toLowerCase())));
    }

    const handleSearchOwner = (value) => {
        setListWallets(walletsBackUp.filter(x => x?.value?.toLowerCase().includes(value?.toLowerCase())));
    }

    const handleSearchHolder = (value) => {
        setListWallets(walletsBackUp.filter(x => x?.value?.toLowerCase().includes(value?.toLowerCase())));
    }

    const handleComfirm = async () => {
        const mainPayload = {};
        mainPayload.beneficiaryOrganizationId = payload?.ownerAccount?.address;
        mainPayload.holderOrganizationId = payload?.holderAccount?.address;
        mainPayload.organizationId = shipment?.orgId;
        mainPayload.shipmentId = shipment?._id;
        mainPayload.walletPassword = payload?.password;
        mainPayload.userSubId = userInfo?.email;
        if (idDocumentSignature) {
            doGenerateEthereum(idDocumentSignature, mainPayload, payload.partyAccount.address, 'Ethereum', (data) => {
                if(data){
                    onCancel();
                    doGetEthereumDetail(idDocumentSignature, wallets.filter(x => x.orgId === shipment?.orgId)[0]?.address, 'Ethereum', shipment?.orgId, (data) => {
                        if (data) {
                            setReloadDocument(data);
                        }
                    })
                }

            });
        }
    }

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            title="Generate TT via Ethereum"
            onCancelClick={onCancel}
            submitButton={true}
            submitButtonLabel={"Generate "}
            renderForm={children => (
                <form onSubmit={handleSubmit(handleComfirm)}>
                    {children}
                </form>
            )}
        >
            <EthereumForm
                wallets={listWallets}
                setWallets={setListWallets}
                setPayload={setPayload}
                payload={payload}
                hanldSearchAccount={hanldSearchAccount}
                handleSearchOwner={handleSearchOwner}
                handleSearchHolder={handleSearchHolder}
                walletsBackUp={walletsBackUp}
            />
        </Modal>
    )
}
