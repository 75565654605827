import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import { useForm } from 'react-hook-form';
import { Input, Dropdown, Button } from 'components';
import { getErrorMessage } from 'utils';
import { TrackShipmentContext } from 'contexts';
import {
    CARRIER_PAGE_SIZE,
    // DESTINATION,
    LOCODE_PAGE_SIZE,
    // ORIGIN,
    TAB_CONTAINER,
    TRACKER_BOOKING_BC,
} from 'actions';
import { useTranslation } from 'react-i18next';

export const ContainerForm = ({ onSubmit, trackerParams }) => {
    const [loading, setLoading] = useState(false);
    const [carrierLoading, setCarrierLoading] = useState(false);
    // const [originLoading, setOriginLoading] = useState(false);
    // const [destinationLoading, setDestinationLoading] = useState(false);
    const { t } = useTranslation();

    const { handleSubmit, register, errors, reset } = useForm();
    const { 
        carriers, 
        // origins, 
        // destinations, 
        doGetCarriers, 
        doGetLocodes, 
        doGetTrackAndTraceV2
    } = useContext(TrackShipmentContext);
    
    /*eslint-disable */
    useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
    }, [])
    /*eslint-enable */

    const handleSearch = (data) => {
        setLoading(true);
        const { containerNo, scac } = data;

        doGetTrackAndTraceV2({
            type: TRACKER_BOOKING_BC,
            trackingNo: containerNo,
            scac: scac
        }, () => {
            setLoading(false);
            onSubmit(TAB_CONTAINER);
        }, () => {
            setLoading(false);
            reset();
        }, false);
    }

    const onSearchCarrier = (value) => {
        setCarrierLoading(true);
        doGetCarriers({
            limit: CARRIER_PAGE_SIZE,
            nameOrCode: value
        }, () => {
            setCarrierLoading(false);
        }, () => {
            setCarrierLoading(false);
        });
    }

    // const onSearchOriginLocode = value => {
    //     setOriginLoading(true);
    //     doGetLocodes({
    //         limit: LOCODE_PAGE_SIZE,
    //         cop: value,
    //         isSearching: true
    //     }, () => {
    //         setOriginLoading(false);
    //     }, () => {
    //         setOriginLoading(false);
    //     }, ORIGIN);
    // }

    // const onSearchDestLocode = (value) => {
    //     setDestinationLoading(true);

    //     doGetLocodes({
    //         limit: LOCODE_PAGE_SIZE,
    //         cop: value,
    //         isSearching: true
    //     }, () => {
    //         setDestinationLoading(false);
    //     }, () => {
    //         setDestinationLoading(false);
    //     }, DESTINATION);
    // }

    return (
        <form className="tr__container-form" onSubmit={handleSubmit(handleSearch)}>
            <div className="form-group">
                <div className="tr__container-form--group">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="scac"
                        label={t('trackshipment.selectCarrier')}
                        required
                        error={!!errors.scac}
                        errorMessage={getErrorMessage(errors.scac, "carrier")}
                        refs={register({ required: true })}
                        options={carriers && carriers.length > 0 ? carriers.map(carrier => {
                            return {
                                key: carrier.scacCode,
                                value: `${carrier.carrierName} (${carrier.scacCode})`
                            }
                        }) : []}
                        placeholder="You can select any of the carrier..."
                        dropdownPosition="center"
                        onInputChange={onSearchCarrier}
                        searchLoading={carrierLoading}
                        defaultValue={trackerParams && trackerParams.carrier ? trackerParams.carrier : ""}
                    />
                </div>
                <div className="tr__container-form--group">
                    <Input
                        label={t('trackshipment.labelContainerNum')}
                        required
                        name="containerNo"
                        placeholder="e.g. HMDU284299242"
                        refs={register({ required: true })}
                        error={!!errors.containerNo}
                        errorMessage={getErrorMessage(errors.containerNo, "Container Number")}
                        defaultValue={trackerParams && trackerParams.containerNumber ? trackerParams.containerNumber : ""}
                    />
                </div>
                {/* <div className="tr__container-form--group route">
                    <span className="route-icon">
                        <i className="icon-location" />
                        <i className="icon-location" />
                    </span>
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="origin"
                        label={t('vesselSchedule.searchFrom.vesselRoute')}
                        horizontalLabel={t('vesselSchedule.searchFrom.origin')}
                        star
                        error={!!errors.origin}
                        errorMessage=" "
                        refs={register({ required: true })}
                        options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder={t('vesselSchedule.searchFrom.placeHolderSearch')}
                        dropdownPosition="center"
                        onInputChange={onSearchOriginLocode}
                        searchLoading={originLoading}
                        defaultValue={trackerParams && trackerParams.origin ? trackerParams.origin : ""}
                    />
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destination"
                        horizontalLabel={t('vesselSchedule.searchFrom.destination')}
                        star
                        error={!!errors.destination}
                        errorMessage=" "
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        refs={register({ required: true })}
                        placeholder={t('vesselSchedule.searchFrom.placeHolderSearch')}
                        dropdownPosition="center"
                        onInputChange={onSearchDestLocode}
                        searchLoading={destinationLoading}
                        defaultValue={trackerParams && trackerParams.destination ? trackerParams.destination : ""}
                    />
                </div> */}
            </div>

            <div className="form-btns">
                <Button
                    isSubmitBtn={true}
                    loading={loading}
                >
                    {t('trackshipment.searchForm')}
                </Button>
            </div>
        </form>
    )
};
