import React, { useState, useCallback, useEffect } from 'react';

import './index.scss';
// import { ManagementContext } from 'contexts';
// import { ALL, MYTASK } from 'actions';
// import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import {
    CardDocument
} from 'components';

export const CardDocumentItem = (props) => {
    
    const { section, task, currentTab } = props;
    const [taskDocument, setTasksDocument] = useState(task);
    // const {  doGetTaskManagements, doEditOrderTaskActiveShipment } = useContext(ManagementContext)
    // const {shipmentId} = useParams();
    useEffect(() => {
        setTasksDocument(task);
    }, [task])

    const moveCardEvent = useCallback((dragIndex, hoverIndex,) => {
        const dragCard = taskDocument[dragIndex];
        let newTasks = cloneDeep(taskDocument)
        const temp = cloneDeep(dragCard)
        newTasks[dragIndex] = cloneDeep(newTasks[hoverIndex])
        newTasks[hoverIndex] = temp
        // let payload = [...newTasks];
        // setTasksDocument(payload);
        setTasksDocument(newTasks);
        // setTasksDocument(newTasks);
    }, [taskDocument]);

    return (taskDocument.map((taskEvent, taskEventIndex) => (
                    <CardDocument moveCard={moveCardEvent}
                        index={taskEventIndex}
                        key={taskEventIndex}
                        section={section}
                        type={taskEvent.type}
                        task={taskEvent} 
                        tasks={taskDocument}
                        currentTab={currentTab}
                        />
                ))
            
    )
}
