import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import { 
    ActiveShipmentsContext,
    CollaboratorsContext, 
    HomeContext, 
    ManagementContext, 
    // MemberContext, 
    // TeamContext, 
    TemplateContext
} from 'contexts';

import {
    MultiSelect,
    Button,
    ItemCard
} from 'components';
import {
    CONSIGNEE,
    EXPORT_CUSTOMS,
    EXPORT_LOGISTICS,
    FREIGHT,
    IMPORT_CUSTOMS,
    IMPORT_LOGISTICS,
    MARK_BOOKING_REQUEST,
    MARK_NEW,
    MARK_TRACK_SHIPMENT,
    MEMBER,
    SHIPPER,
    TEAM
} from 'actions';
import avatar from 'assets/images/avatar.png';
import { ALL, GET_FILE, MYTASK, PERMISSIONS_LIST, SHIPMENT_ADMIN } from 'actions/constants';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AddCollaborator = ({ 
    goBack,
 }) => {
    const { t } = useTranslation();
    const [selectedValues, setSelectedValues] = useState([]);
    const [shipmentAdmin, setShipmentAdmin] = useState([]);
    const [shipper, setShipper] = useState([])
    const [consignee, setConsignee] = useState([]);
    const [exportCustom, setExportCustom] = useState([]);
    const [freightPartner, setFreightPartner] = useState([]);
    const [exportLogistics, setExportLogistics] = useState([]);
    const [importCustom, setImportCustom] = useState([]);
    const [importLogistics, setImportLogistics] = useState([]);
    const [selectedOptionAdmin, setSelectedOptionAdmin] = useState([]);
    const [selectedOptionShipper, setSelectedOptionShipper] = useState([]);
    const [selectedOptionConsignee, setSelectedOptionConsignee] = useState([]);
    const [selectedOptionExCustom, setSelectedOptionExCustom] = useState([]);
    const [selectedOptionFreight, setSelectedOptionFreight] = useState([]);
    const [selectedOptionExLogistic, setSelectedOptionExLogistic] = useState([]);
    const [selectedOptionImCustom, setSelectedOptionImCustom] = useState([]);
    const [selectedOptionImLogistic, setSelectedOptionImLogistic] = useState([]);
    const { isTemplate, permission } = useContext(HomeContext);
    const { collaborators, doCreateCollaborators, doGetCollaborators } = useContext(CollaboratorsContext);
    const { doAddCollabratorsTemplate, doGetTemplatesDetails, templatesDetails, collaboratorsTemp } = useContext(TemplateContext);
    // const { doGetShipmentInfo } = useContext(ShipmentInfoContext);
    const { partners, doGetPartners } = useContext(ActiveShipmentsContext);
    const { doGetActiveShipmentsDetails } = useContext(ActiveShipmentsContext);
    const { doGetTaskManagements } = useContext(ManagementContext);
    const {
        markAsShipmentType,
        markAsShipmentTemplate,
        trackerTemplate,
        setNotificationMessage,
        currentTabManagement
    } = useContext(HomeContext);
    const { register } = useForm();
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    const MEMBER_U = MEMBER.toUpperCase();
    const TEAM_U = TEAM.toUpperCase();
    const { templateId } = useParams();
    const newArray = selectedValues.map(item => {
        return item.partners;
    })
    const inviteByEmail = 'Invite by email';
    const getMembersTeams = (index, value) => {
        doGetPartners({name : value.trim()});
    }

    const handleAddPartner = (items, roleType) => {
        // doGetPartners();
        const partner = items.map(item => {
            return (item.description !== inviteByEmail) ? {
                id: item.id,
                type: item && item.type && item.type.toUpperCase(),
            } : {
                email: item.email,
                type: (item && item.type && item.type.toUpperCase()) || MEMBER.toUpperCase(),
            }
        });
        if (selectedValues.length > 0) {
            let isExisted = false;
            selectedValues.map((e) => {
                if (e.roleType === roleType) {
                    e.partners = e.partners.concat(partner);
                    isExisted = true;
                } 
                return e;
            });
            if (!isExisted) {
                let roleShipment  = { roleType: roleType, partners : partner };
                setSelectedValues(selectedValues.concat(roleShipment));
            }
        } else {
            let roleShipment  = { roleType: roleType, partners : partner };
            setSelectedValues(oldValue => [...oldValue, roleShipment]);
        }
    }

    const handleRemove = (item, roleType) => {
        selectedValues.map((e, index) => {
            if (e.roleType === roleType) {
                e.partners = e.partners.filter(o => o.id !== item.id);   
            }
            return e;
        });
        setSelectedValues(selectedValues);
    }

    const mappingPartners = source => {
        return source.map(m => {
            return {
                id: m.id,
                // icon: m.type === TEAM_U ? 'icon-users' : (m.avatar || avatar),
                icon: m.type === TEAM_U ? 'icon-users' : (m.avatar ? GET_FILE + m.avatar : avatar),
                title: m.username,
                description: m.type === TEAM_U ? m.organization.name : m.username,
                type: m.type
            }
        })
    }
  
    const handleAddCollaborator = () => {
        const payload = {
            rolesInShipment : selectedValues.filter(e => e.partners.length > 0)
        }

        const payloadTemplate = selectedValues.filter(e => e.partners.length > 0);

        if (isTemplate) {
            doAddCollabratorsTemplate(templateId, payloadTemplate, () => {
                setNotificationMessage(`
                <p> ${t("home.add-new-request.add_collabrators")}</p>
                `);
                doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                goBack();
            })
        } else {
            doCreateCollaborators(shipmentId, payload, () => {
                setNotificationMessage(`
                <p> ${t("home.add-new-request.add_collabrators")}</p>
                `);
                // doGetShipmentInfo(shipmentId);
                doGetActiveShipmentsDetails(shipmentId);
                doGetCollaborators(shipmentId, {
                });
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: currentTabManagement === ALL ? ALL : MYTASK
                });
                goBack();
               
            })
        }
       
    }

    const filteredOptions = (options, selectedOptions) => {
        const afterSelectedOptions = options.filter(item => !selectedOptions.some(i => i.id === item._id));
        return afterSelectedOptions;
    }

    /*eslint-disable */
    useEffect(() => {
        doGetPartners();
    }, [])

    // useEffect(() => {
    //     selectedValues.forEach(value => {
    //         if (value.roleType === SHIPMENT_ADMIN && !shipmentAdmin.length) setShipmentAdmin(value.partners);
    //         if (value.roleType === SHIPPER && !shipper.length) setShipper(value.partners);
    //         if (value.roleType === CONSIGNEE && !consignee.length) setConsignee(value.partners);
    //         if (value.roleType === EXPORT_CUSTOMS && !exportCustom.length) setExportCustom(value.partners);
    //         if (value.roleType === FREIGHT && !freightPartner.length) setFreightPartner(value.partners);
    //         if (value.roleType === EXPORT_LOGISTICS && !exportLogistics.length) setExportLogistics(value.partners);
    //         if (value.roleType === IMPORT_CUSTOMS && !importCustom.length) setImportCustom(value.partners);
    //         if (value.roleType === IMPORT_LOGISTICS && !importLogistics.length) setImportLogistics(value.partners);
    //     });
    // }, [selectedValues])
    
    useEffect(() => {
        if (markAsShipmentType !== MARK_NEW) {
            switch(markAsShipmentType) {
                case MARK_TRACK_SHIPMENT:
                    if (trackerTemplate) {
                        
                    }
                    break;
                case MARK_BOOKING_REQUEST:
                    if (markAsShipmentTemplate) {
                        const { memberInfo, freight, creatorRole, base } = markAsShipmentTemplate;
                        let freights = [];
                        let creators = [];
                        let collaborators = [];

                        memberInfo.forEach(m => {
                            if (m.id === freight.freightId) freights.push({
                                ...m,
                                type: freight.freightType
                            });
                            else if (m.id === base.createdBy) creators.push({
                                ...m,
                                type: MEMBER_U
                            });
                            else collaborators.push(m);
                        });

                        setFreightPartner(old => [...old, ...mappingPartners(freights)]);
                        handleAddPartner(mappingPartners(freights), FREIGHT);
                        switch(creatorRole) {
                            case CONSIGNEE:
                                setConsignee(old => [...old, ...mappingPartners(creators)]);
                                handleAddPartner(mappingPartners(creators), CONSIGNEE);
                                break;
                            case SHIPPER:
                                setShipper(old => [...old, ...mappingPartners(creators)]);
                                handleAddPartner(mappingPartners(creators), SHIPPER);
                                break;
                        };
                        setConsignee(old => [...old, ...mappingPartners(collaborators)]);
                        handleAddPartner(mappingPartners(collaborators), CONSIGNEE);
                        
                    }
                    break;
                default:
                    break;
            }
        }
    }, [markAsShipmentType, markAsShipmentTemplate, trackerTemplate])
    /*eslint-enable */

    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }
    return (
        <div
            className="tr__add-collaborator"
        >
            <div className="tr__add-collaborator--title d-flex align-items-center col-12">
                <p className="tr__link tag mrx2" onClick={goBack}>
                    <i className="icon-chevron-left" />
                    <span>{t("home.addcollaborator.back")}</span>
                </p>
                <h2 className="h2 f-medium">{t("home.addcollaborator.title")}</h2>
            </div>
            <div className="tr__add-collaborator--selects mtx3 col-12 col-sm-6 offset-sm-3">
            {(checkPermission(PERMISSIONS_LIST.SHIPMENT_ADD_SHIPMENT_ADMIN) || isTemplate) && (
                <MultiSelect
                    className="mbx4"
                    value={shipmentAdmin}
                    name="consignee"
                    label={t("home.addcollaborator.shipment_admin.label")}
                    // addButton={true}
                    options={partners ? filteredOptions(partners.filter(option => !(isTemplate ? collaboratorsTemp : collaborators).some(collaborator => collaborator.roles?.includes(SHIPMENT_ADMIN) && option._id === collaborator._id)), selectedOptionAdmin).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    // addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(0, value)}
                    // searchLoading={memberLoading[0] || teamLoading[0]}
                    labelIcon='icon-admin'
                    placeholder={t("home.addcollaborator.placeholder")}
                    register={register()}
                    onChange={items => {
                        handleAddPartner(items, SHIPMENT_ADMIN);
                        setShipmentAdmin(oldValue => [...oldValue, ...items]);
                        setSelectedOptionAdmin(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, SHIPMENT_ADMIN );
                                        setShipmentAdmin(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionAdmin(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
            )

            }
            
                <MultiSelect
                    className="mbx4"
                    value={consignee}
                    name="consignee"
                    label={t("home.addcollaborator.consignee.label")}
                    // addButton={true}
                    options={partners ? filteredOptions(partners.filter(option => !(isTemplate ? collaboratorsTemp : collaborators).some(collaborator => collaborator.roles?.includes(CONSIGNEE) && option._id === collaborator._id)), selectedOptionConsignee).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    // addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(0, value)}
                    // searchLoading={memberLoading[0] || teamLoading[0]}
                    labelIcon='icon-box'
                    placeholder={t("home.addcollaborator.placeholder")}
                    register={register()}
                    onChange={items => {
                        handleAddPartner(items, CONSIGNEE);
                        setConsignee(oldValue => [...oldValue, ...items]);
                        setSelectedOptionConsignee(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, CONSIGNEE );
                                        setConsignee(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionConsignee(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                 <MultiSelect
                    className="mbx4"
                    value={shipper}
                    name="shipper"
                    label={t("home.addcollaborator.shipper.label")}
                    // addButton={true}
                    options={partners ? filteredOptions(partners.filter(option => !(isTemplate ? collaboratorsTemp : collaborators).some(collaborator => collaborator.roles?.includes(SHIPPER) && option._id === collaborator._id)), selectedOptionShipper).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    // addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(1, value)}
                    // searchLoading={memberLoading[1] || teamLoading[1]}
                    labelIcon='icon-shipper'
                    placeholder={t("home.addcollaborator.placeholder")}
                    register={register()}
                    onChange={items => {
                        handleAddPartner(items, SHIPPER);
                        setShipper(oldValue => [...oldValue, ...items]);
                        setSelectedOptionShipper(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, SHIPPER);
                                        setShipper(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionShipper(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(option => !(isTemplate ? collaboratorsTemp : collaborators).some(collaborator => collaborator.roles?.includes(EXPORT_CUSTOMS) && option._id === collaborator._id)), selectedOptionExCustom).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={exportCustom}
                    name="exportCustom"
                    label={t("home.addcollaborator.exportCustom.label")}
                    // addButton={true}
                    // addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(2, value)}
                    // searchLoading={memberLoading[2] || teamLoading[2]}
                    labelIcon='icon-export'
                    placeholder={t("home.addcollaborator.placeholder")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, EXPORT_CUSTOMS);
                        setExportCustom(oldValue => [...oldValue, ...items]);
                        setSelectedOptionExCustom(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, EXPORT_CUSTOMS );
                                        setExportCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionExCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(option => !(isTemplate ? collaboratorsTemp : collaborators).some(collaborator => collaborator.roles?.includes(FREIGHT) && option._id === collaborator._id)), selectedOptionFreight).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={freightPartner}
                    // addButton={true}
                    // addButtonLabel="Add"
                    name="freightPartner"
                    label={t("home.addcollaborator.freightPartner.label")}
                    onInputChange={value => getMembersTeams(3, value)}
                    // searchLoading={memberLoading[3] || teamLoading[3]}
                    labelIcon='icon-truck'
                    placeholder={t("home.addcollaborator.placeholder")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, FREIGHT);
                        setFreightPartner(oldValue => [...oldValue, ...items]);
                        setSelectedOptionFreight(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    onRemove={item => handleRemove(item)}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, FREIGHT);
                                        setFreightPartner(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionFreight(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(option => !(isTemplate ? collaboratorsTemp : collaborators).some(collaborator => collaborator.roles?.includes(EXPORT_LOGISTICS) && option._id === collaborator._id)), selectedOptionExLogistic).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={exportLogistics}
                    // addButton={true}
                    // addButtonLabel="Add"
                    name="exportLogistic"
                    label={t("home.addcollaborator.exportLogistic.label")}
                    onInputChange={value => getMembersTeams(4, value)}
                    // searchLoading={memberLoading[4] || teamLoading[4]}
                    labelIcon='icon-box-export'
                    placeholder={t("home.addcollaborator.placeholder")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, EXPORT_LOGISTICS);
                        setExportLogistics(oldValue => [...oldValue, ...items]);
                        setSelectedOptionExLogistic(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={mems => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {mems.length > 0 && mems.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, EXPORT_LOGISTICS);
                                        setExportLogistics(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionExLogistic(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(option => !(isTemplate ? collaboratorsTemp : collaborators).some(collaborator => collaborator.roles?.includes(IMPORT_CUSTOMS) && option._id === collaborator._id)), selectedOptionImCustom).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={importCustom}
                    // addButton={true}
                    // addButtonLabel="Add"
                    name="importCustom"
                    onInputChange={value => getMembersTeams(5, value)}
                    // searchLoading={memberLoading[5] || teamLoading[5]}
                    label={t("home.addcollaborator.importCustom.label")}
                    labelIcon='icon-import'
                    placeholder={t("home.addcollaborator.placeholder")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, IMPORT_CUSTOMS);
                        setImportCustom(oldValue => [...oldValue, ...items]);
                        setSelectedOptionImCustom(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, IMPORT_CUSTOMS);
                                        setImportCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionImCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(option => !(isTemplate ? collaboratorsTemp : collaborators).some(collaborator => collaborator.roles?.includes(IMPORT_LOGISTICS) && option._id === collaborator._id)), selectedOptionImLogistic).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={importLogistics}
                    // addButton={true}
                    // addButtonLabel="Add"
                    name="importLogistic"
                    label={t("home.addcollaborator.importLogistic.label")}
                    onInputChange={value => getMembersTeams(6, value)}
                    // searchLoading={memberLoading[6] || teamLoading[6]}
                    labelIcon='icon-box-import'
                    placeholder={t("home.addcollaborator.placeholder")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, IMPORT_LOGISTICS);
                        setImportLogistics(oldValue => [...oldValue, ...items]);
                        setSelectedOptionImLogistic(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, IMPORT_LOGISTICS);
                                        setImportLogistics(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionImLogistic(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
            </div>
            <div className="tr__add-collaborator--footer d-flex align-items-center justify-content-between">
                <p className="f-medium">{t("home.addcollaborator.message-footer")}</p>
                <Button
                    onClick={handleAddCollaborator}
                    disabled={newArray.some( e => e.length > 0) ? false : true}
                >
                    {t("home.addcollaborator.add")}
                </Button>
            </div>
        </div>
    )
};

AddCollaborator.propTypes = {
    goBack: PropTypes.func
};
