import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    MapBox,
    SearchResults,
    Collapse,
    SearchForms,
    Button
} from 'components';
import {
    shipmentProcess, typeShipmentOptions
} from 'utils';
import { ActiveShipmentsContext, HomeContext, TrackShipmentContext } from 'contexts';
import { AIR, DONE, PERMISSIONS_LIST, TRACKER_BOOKING_BC, TRACKER_BOOKING_BK, TRACKER_BOOKING_BL } from 'actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ShipmentMap = ({ className = '', shipmentInfo }) => {
    const {t} = useTranslation();
    const { shipmentId } = useParams();
    const [markers, setMarkers] = useState([]);
    const [activeRoutes, setActiveRoutes] = useState([]);
    const [markersStatus, setMarkersStatus] = useState([]);
    const [searched, setSearched] = useState(false);
    const [markersInfo, setMarkersInfo] = useState([]);
    const [isHiddenSearchForm, setIsHiddenSearchForm] = useState(true);
    const [searchType,setSearchType] = useState();
    const shipmentMapId = 'active-shipment-map';
    const {
        selectedTracker,
        trackers,
        routes,
        setTrackers,
        setSelectedTracker,
        setRoutes,
    } = useContext(TrackShipmentContext);
    // const { hasSearchData } = useContext(ActiveShipmentsContext);
    const { doSaveSearchParams, doGetSearchParams } = useContext(ActiveShipmentsContext);
    const { searchParamsShipment, isApplyDataSearch, setNotificationMessage, isArchive, permission } = useContext(HomeContext);
    // console.log(searchParamsShipment, 'searchParamsShipment')
    const [
        typeShipment, 
        setTypeShipment
    ] = useState(typeShipmentOptions[0]);

     /*eslint-disable */
     useEffect(() => {
        return () => {
            setTrackers([]);
            setSelectedTracker(null);
            setRoutes(null);
        }
    }, [])

    useEffect(() => {
        setTypeShipment(shipmentInfo?.shippingDetails?.shipmentMode === AIR ? typeShipmentOptions[1] : typeShipmentOptions[0]);
    }, [shipmentInfo])
    
    useEffect(() => {
        if (selectedTracker) {
            const { prePol, postPod } = selectedTracker;
            const isRoutes = routes && routes.routeArray && routes.routeArray.length;
            
            let newActiveRoutes = [];
            let tempMarkers = [];
            let tempMarkerStatus = [];
            let tempMarkerInfo = [];

            if (isRoutes) 
            {
                newActiveRoutes = [
                    [prePol.location.lng, prePol.location.lat],
                    ...routes.routeArray
                ];
                tempMarkers = [
                    [prePol.location.lng, prePol.location.lat],
                    ...routes.routeArray,
                    [postPod.location.lng, postPod.location.lat]
                ];
                tempMarkerStatus = [
                    prePol.status === DONE,
                    ...Array(routes.routeArray.length).fill(true),
                    postPod.status === DONE
                ];
                tempMarkerInfo = [
                    `${prePol.location.name} (${prePol.location.locode})`,
                    ...Array(routes.routeArray.length).fill(''),
                    `${postPod.location.name} (${postPod.location.locode})`
                ];

                if (postPod.status === DONE) newActiveRoutes.push([postPod.location.lng, postPod.location.lat])
            } else {
                tempMarkers = [
                    [prePol.location.lng, prePol.location.lat],
                    [postPod.location.lng, postPod.location.lat]
                ];
                tempMarkerStatus = [
                    prePol.status === DONE,
                    postPod.status === DONE
                ];
                tempMarkerInfo = [
                    `${prePol.location.name} (${prePol.location.locode})`,
                    `${postPod.location.name} (${postPod.location.locode})`
                ];
            }

            setMarkers(tempMarkers);

            setActiveRoutes(newActiveRoutes);

            setMarkersStatus(tempMarkerStatus);

            setMarkersInfo(tempMarkerInfo);
        } else {
            setMarkers([]);
            setMarkersStatus([]);
            setMarkersInfo([]);
            setActiveRoutes([]);
        }
    }, [selectedTracker, routes])

    /*eslint-disable */
    useEffect(() => {
        setMarkers([]);
        setActiveRoutes([]);
        setMarkersInfo([]);
        setMarkersStatus([]);

        if (trackers && trackers.length > 0) {
            setIsHiddenSearchForm(true);
        }
    }, [trackers])
    /*eslint-enable */
    const handleApply = () => {
        const { type } = searchParamsShipment;
        let payload = {}
        switch (type) {
            case TRACKER_BOOKING_BK:
                payload = {
                    requestType: TRACKER_BOOKING_BK,
                    carrier: searchParamsShipment?.scac,
                    bookingConfirmNumber: searchParamsShipment?.trackingNo,
                }
                break;
            case TRACKER_BOOKING_BL:
                payload = {
                    requestType: TRACKER_BOOKING_BL,
                    carrier: searchParamsShipment?.scac,
                    billOfLadingNumber: searchParamsShipment?.trackingNo
                }
                break;
            case TRACKER_BOOKING_BC:
                payload = {
                    requestType: TRACKER_BOOKING_BC,
                    carrier: searchParamsShipment?.scac,
                    containerNumber: searchParamsShipment?.trackingNo,
                    // origin: searchParamsShipment?.origin,
                    // destination: searchParamsShipment?.destination
                }
                break;
            default:
                payload = {}
                break;
        }
        doSaveSearchParams(shipmentId, payload, () => { doGetSearchParams(shipmentId, () => {
            setNotificationMessage(`
                    <p> ${t("home.active-shipment.details.tabs.notification_search_params")}</p>
                `)
        })});
    }
    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }
    return (
        <>
        {!isArchive && checkPermission(PERMISSIONS_LIST.SHIPMENT_VIEW_SHIPMENT_ON_MAP) && (
        <Collapse
            className={`tr__shipment-map ${className}`}
            title={t("home.active-shipment.details.tabs.view-map")}
        >
            <div className={`d-flex`}>
                <div className='tr__shipment-map--container d-flex'>
                    <div className="tr__shipment-map--map">
                        <MapBox
                            id={shipmentMapId}
                            markers={markers}
                            routes={activeRoutes}
                            status={markersStatus}
                            infos={markersInfo}
                        />
                    </div>
                    <div className="tr__shipment-map--info">
                        <SearchResults
                            shipmentProcess={shipmentProcess}
                            shipmentInfo={shipmentInfo}
                            setSearchType={setSearchType}
                        />
                        {!isArchive && checkPermission(PERMISSIONS_LIST.SHIPMENT_SEARCH_AGAIN) && (
                            <div className="tr__shipment-map--search d-flex justify-content-between px1">
                            <Button 
                                className="" 
                                onClick={() => {setIsHiddenSearchForm(!isHiddenSearchForm); setTrackers([]);}}>{isHiddenSearchForm ? t("home.shipment-map.show") : t("home.shipment-map.hide")} {t("home.shipment-map.search_form")}
                            </Button>
                            <Button 
                                onClick={handleApply} 
                                className="secondary outline" 
                                disabled={((trackers && trackers.length > 0 && isApplyDataSearch) && shipmentInfo?.shippingDetails?.shipmentMode !== AIR) ? false : true}
                            >
                                {t("home.shipment-map.apply")}
                            </Button>
                        </div>
                        )}
                        
                    </div>
                    
                </div>
                     {!isHiddenSearchForm &&
                        <div className="mlx3">
                            <SearchForms 
                                searchType={searchType}
                                typeShipment={typeShipment}
                                setTypeShipment={setTypeShipment}
                                disabledForm={true}
                                searched={searched}
                                setSearched={setSearched}
                             />
                            {/* {selectedTracker && !hasSearchData && ( */}
                           
                        
                        {/* } */}
                        </div> 
                    }
                
            </div>
        </Collapse>
        )}
        </>
    )
};

ShipmentMap.propTypes = {
    className: PropTypes.string
};
