import axios from 'axios';
import { authHeaders } from './auth';
import {
    TRACKER_MARK_ACTIVE_SHIPMENT,
    ACTIVE_SHIPMENT_ADD_SECTION,
    ACTIVE_SHIPMENT_EDIT_SECTION,
    ACTIVE_SHIPMENT_ADD_TASK,
    ACTIVE_SHIPMENT_INFO,
    ACTIVE_SHIPMENT_UPDATE_TASK,
    ACTIVE_SHIPMENTS,
    ACTIVE_SHIPMENT_NOTE,
    ACTIVE_SHIPMENT_HSCODE,
    ACTIVE_SHIPMENT_FTA_DETAILS,
    ACTIVE_SHIPMENT_LINKED,
    ACTIVE_SHIPMENT_SEARCH_PARAMS,
    ACTIVE_SHIPMENT_LOGS,
    ACTIVE_SHIPMENT_BK_DETAILS,
    ACTIVE_SHIPMENT_STAR,
    ACTIVE_SHIPMENT_FIELDS,
    ACTIVE_SHIPMENT_DEATILS,
    ACTIVE_SHIPMENT_TASK_ORDER,    
    PROJECT_FIELD,
    ACTIVE_SHIPMENT_AIR_PORT,
    ACTIVE_SHIPMENT_AIR_LINE,
    ACTIVE_SHIPMENT_ADD_EVENT,
    ACTIVE_SHIPMENT_DELETE_TASK,
    DOCUSIGN,
    ACTIVE_SHIPMENT_DOCUMENT_SIGNATURE,
    ACTIVE_SHIPMENT_DOCUMENT_SINGATURE_STATUS,
    WALLETS,
    DOCUMENT_SIGNATURE_CREATE
} from './constants';

/** Get active shipments
 * @param {
*      page: int
*      limit: int
*      tab: string ['ALL', 'MYTASK']
* } params
*/
export const getActiveShipments = (params, token) => {
    return axios.get(ACTIVE_SHIPMENTS, params ? {
        params,
        ...authHeaders(token)
    }: authHeaders(token));
};

/** Create a mark as active shipment
*  @param {
*   orgId : string,
*   creatorRole: string
*   status:  string
*   linkedShipmentId: string
*   shippingDetails : {
*       shipmentMode: string
*       shipmentType: string
*   }
*   containerTracking: string,
*   routes: {
*       shipperPickupAdd: strings
*       consigneeDeliveryAdd: strings
*       prepol: {
*           location: {
*               name: string
*               country: string
*               locode: string
*           },
*           date: string,
*           actual : boolean
*       }
*       pol: {
*           location: {
*               name: string
*               country: string
*               locode: string
*           },
*           date: string,
*           actual : boolean
*       }
*       pod: {
*           location: {
*               name: string
*               country: string
*               locode: string
*           },
*           date: string,
*           actual : boolean
*       }
*       postpod: {
*           location: {
*               name: string
*               country: string
*               locode: string
*           },
*           date: string,
*           actual : boolean
*       }
*   }
*   vessels : [
*      {
*          name: string
*          imo: int
*          voyage: null,
*          departure: string
*          arrival: string
*          departureDate: string
*          arrivalDate: string
*      }
*   ]
*   bookingDetail: {
*       cardName: string
*       scacCode: string
*       confirmationNo: string
*       masterBoL: string
*       houseBoL: []
*   }
*   invoices : []
*   roleInShipments : [
*       {
*           roleType: string,
*           partners: [
*               {
*                   partnerId: string,
*                   type: string
*               }
*           ]
*       }
*   ]
*} payload
*/
export const createMarkAsActiveShipment = (payload, token) => {
    if(!payload) return;
    return axios.post(TRACKER_MARK_ACTIVE_SHIPMENT, payload, authHeaders(token));
};

/** Add new section to a shipment
* @param {
*      shipmentId: string
* } payload
*/
export const addShipmentSection = (shipmentId, payload, token) => {
    if (!payload) return;
    return axios.post(ACTIVE_SHIPMENT_ADD_SECTION.replace(':shipmentId', shipmentId), payload, authHeaders(token));
};

/** Update section of a shipment
* @param {
*      sectionId: string
* } payload
*/
export const updateShipmentSection = (sectionId, payload, token) => {
    if (!payload) return;
    return axios.put(ACTIVE_SHIPMENT_EDIT_SECTION.replace(':sectionId', sectionId), payload, authHeaders(token));
};

/** Delete a section from a shipment
* @param {
*      sectionId: string
* } payload
*/
export const deleteShipmentSection = (sectionId, token) => {
    if (!sectionId) return;
    return axios.delete(ACTIVE_SHIPMENT_EDIT_SECTION.replace(':sectionId', sectionId), {
        ...authHeaders(token)
    });
};

/** Add new task to a section of a shipment
* @param {
*      sectionId: string
* } payload
*/
export const addShipmentTask = (sectionId, payload, token) => {
    if (!payload) return;
    return axios.post(ACTIVE_SHIPMENT_ADD_TASK.replace(':sectionId', sectionId), payload, authHeaders(token));
};

/** delete task to a section of a shipment
* @param {
    *      taskId: string
    * } payload
    */
    export const deleteShipmentTask = (taskId, token) => {
        if (!taskId) return;
        return axios.delete(ACTIVE_SHIPMENT_DELETE_TASK.replace(':taskId', taskId), authHeaders(token));
    };

/** Get an shipment information
* @param {
*      shipmentId: string
* } payload
*/
export const getShipmentInfo = (shipmentId, token) => {
    if (!shipmentId) return;
    return axios.get(ACTIVE_SHIPMENT_INFO.replace(':shipmentId', shipmentId), authHeaders(token));
};

export const updateShipmentTask = (taskId, payload, token) => {
    if(!payload) return;
    return axios.put(ACTIVE_SHIPMENT_UPDATE_TASK.replace(':taskId', taskId), payload, authHeaders(token));
}

export const addEventTask = (taskId, payload, token) => {
    if(!payload) return;
    return axios.put(ACTIVE_SHIPMENT_ADD_EVENT.replace(':taskId', taskId), payload, authHeaders(token));
}

export const updateShipmentInfo = (shipmentId, payload, token) => {
    if (!shipmentId) return;
    return axios.put(ACTIVE_SHIPMENT_INFO.replace(':shipmentId', shipmentId), payload, authHeaders(token));
};

/**
 * Update shipment note.
 *
 * @param {string} shipmentId
 * @param {{
 *     note: string
 * }} payload
 * @param {string} token
 */
export const updateShipmentNote = (shipmentId, payload, token) => {
    if (!shipmentId) return;
    return axios.put(ACTIVE_SHIPMENT_NOTE.replace(':shipmentId', shipmentId), payload, authHeaders(token));
};

/**
 * Update shipment note.
 *
 * @param {string} shipmentId
 * @param {{
 *      origin: string,
 *      destination: string,
 *      goodsType: string
 * }} params
 * @param {string} token
 */
export const getHsCodes = (shipmentId, params, token) => {
    if (!shipmentId) return;
    return axios.get(ACTIVE_SHIPMENT_HSCODE.replace(':shipmentId', shipmentId), params ? {
        params,
        ...authHeaders(token)
    }: authHeaders(token));
};

/**
 * Update shipment note.
 *
 * @param {string} shipmentId
 * @param {{
    *      origin: string,
    *      destination: string,
    * }} params
    * @param {string} token
    */
   export const getFtaDetails = (shipmentId, params, payload, token) => {
       if (!shipmentId) return;
       return axios.post(ACTIVE_SHIPMENT_FTA_DETAILS.replace(':shipmentId', shipmentId), payload, params ? {
           params,
           ...authHeaders(token)
       }: authHeaders(token));
   };

/** Add a linked of a shipment
* @param {
*      shipmentID: string
* } payload
*/
export const addLinkedShipmentTask = (shipmentId, payload, token) => {
    if (!payload) return;
    return axios.post(ACTIVE_SHIPMENT_LINKED.replace(':shipmentId', shipmentId), payload, authHeaders(token));
};
/** save search param *
*@param {
    *   carrier: string,
    *   bookingConfirmNumber: string,
    *   billOfLadingNumber: string,
    *   containerNumber: string,
    *   origin: string,
    *   destination: string
    *} payload
    */
    export const saveSearchParams = (shipmentId, payload, token) => {
        if (!shipmentId) return;
        return axios.put(ACTIVE_SHIPMENT_SEARCH_PARAMS.replace(':shipmentId', shipmentId), payload, authHeaders(token));
    }
/** Get search param*/
export const getSearchParams = (shipmentId, token) => {
    if (!shipmentId) return;
    return axios.get(ACTIVE_SHIPMENT_SEARCH_PARAMS.replace(':shipmentId', shipmentId),  authHeaders(token));
}

/** Get All Shipment Activity Logs
* @param {
*      page: string,
*      limit: string
* } params
*/

export const getLogsShipment = (shipmentId, params, token) => {
    if (!shipmentId) return;
    return axios.get(ACTIVE_SHIPMENT_LOGS.replace(':shipmentId', shipmentId), params ? {
        params,
        ...authHeaders(token)
    }: authHeaders(token));
}

export const getBookingDetails = (token) => {
    return axios.get(ACTIVE_SHIPMENT_BK_DETAILS, authHeaders(token))
}

/** add star a shipment
 * @param {
 *      id: string
 * }
*/
export const addStarShipment = (shipmentId, currentTab, token) => {
    if (!shipmentId) return;
    return axios.put(ACTIVE_SHIPMENT_STAR.replace(':shipmentId', shipmentId), {}, {
         params: { tab: currentTab },
         ...authHeaders(token)
    });
}

/** remove star a shipment
 * @param {
 *      id: string
 * }
*/
export const removeStarShipment = (shipmentId, currentTab, token) => {
    if (!shipmentId) return;
    return axios.delete(ACTIVE_SHIPMENT_STAR.replace(':shipmentId', shipmentId), {
        data: { tab: currentTab },
        ...authHeaders(token)
    });
}

// get shipment fields
export const getShipmentFields = (token) => {
    return axios.get(ACTIVE_SHIPMENT_FIELDS, authHeaders(token));
}

/** Get active shipments details
 * @param {
 *       id: string
 * } params
 */
 export const getActiveShipmentsDetails = (shipmentId, token) => {
    if (!shipmentId) return;
    return axios.get(ACTIVE_SHIPMENT_DEATILS.replace(':shipmentId', shipmentId),  authHeaders(token));
 };

 /** Update order task active shipment
 * @param {
  *      order: string
  * } payload
  */
 export const editOrderTaskActiveShipment = (shipmentId, payload, token) => {
    if(!shipmentId) return;
    return axios.put(ACTIVE_SHIPMENT_TASK_ORDER.replace(':shipmentId', shipmentId), payload, authHeaders(token))
}
/** Get active shipments details
 * @param {
 *       page:number,
         limit:number,
         status:string,
         projectField:string
 * } params
 */
 export const getValueFormActiveShipment = (params, token) => {
    return axios.get(PROJECT_FIELD, params ? {
        params,
        ...authHeaders(token)
    }: authHeaders(token));
 }

 /** Get air port 
 * @param {
  *       page: string,
  *       limit: number,
  *       search: string
  * } params
  */
  export const getAirPort = (params, token) => {
     return axios.get(ACTIVE_SHIPMENT_AIR_PORT, params ? {
        params,
        ...authHeaders(token)
    }: authHeaders(token));
  };

   /** Get air port 
 * @param {
    *       page: string,
    *       limit: number,
    *       search: string
    * } params
    */
    export const getAirLine = (params, token) => {
       return axios.get(ACTIVE_SHIPMENT_AIR_LINE, params ? {
          params,
          ...authHeaders(token)
      }: authHeaders(token));
    };

    export const postToDocuSign = (docId, payload, token) => {
        return axios.post(DOCUSIGN.replace(':docId', docId),payload, authHeaders(token));
    }
    export const createDocumentSignature = (payload, token) => {
        return axios.post(DOCUMENT_SIGNATURE_CREATE, payload, authHeaders(token));
    }
    export const getWallets = (token) => {
        return axios.get(WALLETS, authHeaders(token));
    }
     /** Get documents signature 
 * @param {
    *       shipmentId: string,
    * } payload
    */
      export const getDocumentSignatureList = (params, token) => {
        return axios.get(ACTIVE_SHIPMENT_DOCUMENT_SIGNATURE, params ? {params, ...authHeaders(token)} : authHeaders(token))
      }
    
           /** Get documents signature status
 * @param {
    *       documentId: string,
    * } payload
    */
      export const getDocumentSignatureStatuses = (documentId, token) => {
        if (!documentId) return;
          return axios.get(ACTIVE_SHIPMENT_DOCUMENT_SINGATURE_STATUS.replace(':documentId', documentId),  authHeaders(token));
    }

