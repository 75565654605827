import React, { createContext, useEffect, useState } from 'react';
import { AuthContextProvider } from './AuthContext';
import { TrackShipmentContextProvider } from './TrackShipmentContext';
import { BookingRequestContextProvider } from './BookingRequestContext';
import { NotificationContextProvider } from './NotificationContext';
import {
    ALL,
    CREATED,
    CREATOR,
    ERROR,
    MARK_NEW,
    ORGANIZATIONS_DETAILS_URL,
    SUCCESS,
    TEAMS_URL
} from 'actions';
import { ActiveShipmentsContextProvider } from './ActiveShipmentsContext';
import { typeShipmentOptions } from 'utils';

export const HomeContext = createContext();

export const HomeContextProvider = ({ children }) => {
    const [loggedInUser, setLoggedInUser] = useState();
    const [loading, setLoading] = useState(false);
    const [isArchive, setIsArchive] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [teamMember, setTeamMember] = useState(false);
    const [isEditTask, setIsEditTask] = useState(false);
    const [breadcrums, setBreadcrums] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationDuration, setNotificationDuration] = useState(3000);
    const [toastmessDuration, setToastmessDuration] = useState(5000);
    const [notificationType, setNotificationType] = useState(SUCCESS);
    const [confirmMessage, setConfirmMessage] = useState('');
    const [isOrgTeamDetails, setIsOrgTeamDetails] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [searchMessage, setSearchMessage] = useState('');
    const [authErrorActive, setAuthErrorActive] = useState(false);
    const [authErrorMessage, setAuthErrorMessage] = useState('');
    const [authErrorType, setAuthErrorType] = useState(ERROR);
    const [bookingRequestTab, setBookingRequestTab] = useState(CREATED);
    const [brType, setBrType] = useState(CREATOR);
    const [markAsShipmentType, setMarkAsShipmentType] = useState(MARK_NEW);
    const [markAsShipmentTemplate, setMarkAsShipmentTemplate] = useState();
    const [trackerTemplate, setTrackerTemplate] = useState();
    const [sectionId, setSectionId] = useState();
    const [sectionName, setSectionName] = useState();
    const [sectionOrder, setSectionOrder] = useState();
    const [taskId, setTaskId] = useState('');
    const [eventTask, setEventTask] = useState();
    const [documentTask, setDocumentTask] = useState();
    const [selectTask, setSelectTask] = useState();
    const [taskName, setTaskName] = useState();
    const [taskRoles, setTaskRoles] = useState([]);
    const [collaboratorId, setCollaboratorId] = useState('');
    const [fileId, setFileId] = useState('');
    const [shipment, setShipment] = useState();
    const [idTemplate, setIdTemplate] = useState('');
    const [seachParamActive, setSeachParamActive] = useState("");
    const [searchTerm, setSearchTerm] = useState();
    const [isSearchingShipment, setIsSearchingShipment] = useState(true);
    const [currentTabManagement, setCurrentTabManagement] = useState(ALL);
    const [isAddTaskFta, setIsAddTaskFta] = useState(false);
    const [isExtractedFta, setIsExtractedFta] = useState(false);
    const [payloadConfirm, setPayloadConfirm] = useState();
    const [searchParamsShipment, setSearchParamsShipment] = useState();
    const [isApplyDataSearch, setIsApplyDataSearch] = useState(false);
    const [isDocument, setIsDocument] = useState(false);
    const [isDocumentTab, setIsDocumentTab] = useState(false);
    const [logType, setLogType] = useState("");
    const [linkedVessel, setLinkedVessel] = useState(false);
    const [couponMessage, setCouponMessage] = useState(false);
    const [bookingId, setBookingid] = useState("");
    const [chooseLinkedVessel, setChooseLinkedVessel] = useState();
    const [unlinkVessel, setUnLinkVessel] = useState(false);
    const [selectLinkVessel, setSelectLinkVessel] = useState(false);
    const [filterParamsShipment, setFilterParamsShipment] = useState(null);
    const [notificationId, setNotificationId] = useState();
    const [permission, setPermission] = useState(null);
    const [issuePermission, setIssuePermission] = useState();
    const [permissionEditTask, setPermissionEditTask] = useState(false);
    const [idShipment, setIdShipment] = useState();
    const [isRemoveCollabs, setIsRemoveCollabs] = useState(false);
    const [isRemovePartners, setIsRemovePartners] = useState(false);
    const [typeDocumentTask, setTypeDocumentTask] = useState("");
    const [typeOfShipment, setTypeOfShipment] = useState(typeShipmentOptions[0]);
    const [addNewDocument, setAddNewDocument] = useState(false);
    const [generateTTDocument, setGenerateTTDocument] = useState(false);
    const [urlFile, setUrlFile] = useState();
    const [idDocumentSignature, setIdDocumentSignature] = useState();
    const [currentWallest, setCurrentWallest] = useState();
    const [reloadDocument, setReloadDocument] = useState();

    const [signer, setSigner] = useState({});
    let extraTimeout = 1000;
    let timeout = null;
    let notificationTimeout = null;

    /*eslint-disable */
    useEffect(() => {
        setAuthErrorActive(!!authErrorMessage);
    }, [authErrorMessage])

    useEffect(() => {
        if (authErrorActive && !timeout) {
            timeout = setTimeout(() => {
                setAuthErrorActive(false);
                setTimeout(() => {
                    setAuthErrorMessage('');
                    setAuthErrorType(ERROR);
                }, extraTimeout);
            }, notificationDuration);
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        }
    }, [authErrorActive])

    useEffect(() => {
        if (notificationType === ERROR && !notificationTimeout) {
            notificationTimeout = setTimeout(() => {
                setNotificationType(SUCCESS);
            }, notificationDuration + extraTimeout)
        }

        return () => {
            if (notificationTimeout) clearTimeout(notificationTimeout);
        }
    }, [notificationType])
    /*eslint-enable */

    const backToTeamListUrl = (history, location) => {
        if (isOrgTeamDetails) {
            const { orgId } = location.state;
            if (orgId) {
                history.push({
                    pathname: ORGANIZATIONS_DETAILS_URL.replace(':orgId', orgId),
                    state: {
                        activeTab: 1
                    }
                });
            }
        } else {
            history.push(TEAMS_URL);
        }
    }

    return (
        <HomeContext.Provider
            value={{
                idShipment,
                loading,
                isTemplate,
                teamMember,
                isEditTask,
                isArchive,
                breadcrums,
                bookingRequestTab,
                notificationMessage,
                notificationDuration,
                toastmessDuration,
                notificationType,
                isOrgTeamDetails,
                confirmMessage,
                isOwner,
                authErrorActive,
                authErrorMessage,
                authErrorType,
                brType,
                idDocumentSignature,
                searchMessage,
                searchTerm,
                isSearchingShipment,
                seachParamActive,
                markAsShipmentType,
                markAsShipmentTemplate,
                trackerTemplate,
                sectionId,
                sectionName,
                documentTask,
                eventTask,
                selectTask,
                taskName,
                taskRoles,
                reloadDocument,
                shipment,
                idTemplate,
                taskId,
                collaboratorId,
                fileId,
                urlFile,
                currentTabManagement,
                sectionOrder,
                isAddTaskFta,
                isExtractedFta,
                payloadConfirm,
                searchParamsShipment,
                isApplyDataSearch,
                isDocument,
                isDocumentTab,
                logType,
                chooseLinkedVessel,
                unlinkVessel,
                bookingId,
                selectLinkVessel,
                linkedVessel,
                couponMessage,
                filterParamsShipment,
                notificationId,
                currentWallest,
                permission,
                issuePermission,
                permissionEditTask,
                isRemoveCollabs,
                isRemovePartners,
                typeDocumentTask,
                loggedInUser,
                typeOfShipment,
                addNewDocument,
                generateTTDocument,
                signer,
                setLoggedInUser,
                setTypeDocumentTask,
                setIsRemovePartners,
                setIsRemoveCollabs,
                setPermissionEditTask,
                setIssuePermission,
                setIdShipment,
                setPermission,
                setNotificationId,
                setFilterParamsShipment,
                setCouponMessage,
                setLogType,
                setIdDocumentSignature,
                setChooseLinkedVessel,
                setUnLinkVessel,
                setBookingid,
                setSelectLinkVessel,
                setLinkedVessel,
                setIsDocumentTab,
                setIsDocument,
                setIsApplyDataSearch,
                setReloadDocument,
                setSearchParamsShipment,
                setPayloadConfirm,
                setIsExtractedFta,
                setIsAddTaskFta,
                setSectionOrder,
                setShipment,
                setIdTemplate,
                setTaskRoles,
                setCurrentWallest,
                setTaskName,
                setEventTask,
                setSectionName,
                setSectionId,
                setDocumentTask,
                setSelectTask,
                setLoading,
                setIsTemplate,
                setTeamMember,
                setIsEditTask,
                setIsArchive,
                setBreadcrums,
                setNotificationMessage,
                setNotificationDuration,
                setToastmessDuration,
                setNotificationType,
                setIsOrgTeamDetails,
                backToTeamListUrl,
                setConfirmMessage,
                setIsOwner,
                setSearchMessage,
                setSearchTerm,
                setSeachParamActive,
                setIsSearchingShipment,
                setAuthErrorActive,
                setAuthErrorMessage,
                setAuthErrorType,
                setBookingRequestTab,
                setBrType,
                setMarkAsShipmentType,
                setMarkAsShipmentTemplate,
                setTrackerTemplate,
                setTaskId,
                setCollaboratorId,
                setFileId,
                setCurrentTabManagement,
                setTypeOfShipment,
                setAddNewDocument,
                setGenerateTTDocument,
                setUrlFile,
                setSigner
            }}
        >
           
                <AuthContextProvider>
                <NotificationContextProvider>
                    <TrackShipmentContextProvider>
                        <BookingRequestContextProvider>
                            <ActiveShipmentsContextProvider>
                                {children}
                            </ActiveShipmentsContextProvider>
                        </BookingRequestContextProvider>
                    </TrackShipmentContextProvider>
               
            </NotificationContextProvider>
            </AuthContextProvider>
        </HomeContext.Provider>
    );
};
