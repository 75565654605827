import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './index.scss';
import { HomeContext, TeamContext } from 'contexts';
import {
    TeamDetails,
    DetailsTableHeader
} from 'components';
import { TEAM_LOGS } from 'actions';
import { useTranslation } from 'react-i18next';


export const TeamDetailsForm = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { handleSubmit, register, errors, reset } = useForm();
    const { team, doUpdateTeam } = useContext(TeamContext);
    const { setNotificationMessage, backToTeamListUrl, setLogType } = useContext(HomeContext);

    const [isEdit, setIsEdit] = useState(false);

    /*eslint-disable */
    useEffect(() => {
        setLogType(TEAM_LOGS);
    },[])
    /* eslint-enable */

    const doEditTeam = data => {
        if (team) {
            const {
                teamName,
                // teamOrg,
                teamDescription
            } = data;

            const { _id } = team;

            const payload = {
                _id,
                name: teamName,
                // orgId: teamOrg,
                description: teamDescription
            }

            doUpdateTeam(payload, async () => {
                await setIsEdit(true);
                await setIsEdit(false);
                setNotificationMessage(`
                    <p><strong>${payload.name}</strong> ${t("organization.message_update_organization")}</p>
                `);
            })
        }
    }

    return (
        <form className="tr__team-details-form" onSubmit={handleSubmit(doEditTeam)}>
            <TeamDetails
                isModalForm={false}
                title="Team Details"
                register={register}
                errors={errors}
                reset={reset}
                isEditable={isEdit}
                isNew={true}
            >
                <DetailsTableHeader
                    backUrl={() => backToTeamListUrl(history, location)}
                    actionButton={false}
                />
            </TeamDetails>
        </form>
    )
};