import React, { useState } from 'react';
import './index.scss';
import { InfoCell } from '../InfoCell';

// import { useForm } from 'react-hook-form';
import { dataDocument, contactInformation, grossWeight, shippingInformation, transhipmentInformation, containerDocument, weightOfDocument, cutoffDocument } from 'utils';
// import { Button } from 'components/common';
import { EditDocumentData } from '../EditDocumentData';

export const DocumentData = () => {
    const [editDocumentData, setEditDocumentData] = useState(false);
    const checkCol = (key) => {
        let col = 6;
        switch (key) {
            case "Shipper Contact": case "Shipper Fax": case "Consignee Contact": case "Notify Contact": case "Notify Fax": case "T S Port" : case "T S Next Port": case "T S Vessel": case "T S Vessel I M O": case "T S E T D": case "T S E T A": case "Number Of Containers": case "V G M Submission Timeline": case "S I Submission": case "Date": case "Time":
                col = 12;
                break;
            default:
                col = 6;
                break;
        }
        return col;
    }
    return editDocumentData ? (
        <EditDocumentData
            goBack={() => {
                setEditDocumentData(false);
            }}
        />
    ) : (
        <>
            <div className="doc--content">
                <div class="tr__document-details--title d-flex align-items-center justify-content-between">
                    <h2 className="h2 f-medium">Document Data</h2>
                    {/* <div class="MuiBox-root jss145">
                    <Button
                        type="secondary"
                        className="outline"
                        onClick={() => setEditDocumentData(true)}
                    >
                    Edit</Button>
                </div> */}
                </div>
                <div className="document-content">
                    <div className='row'>
                        {dataDocument.map((data, index) => (
                            <div className="col-6 mbx2" key={index}>
                                <InfoCell
                                    className="col-sm-6"
                                    label={data.key}
                                    value={data.value}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Contact Information</h2>
                        <div className='row'>
                            {contactInformation.map((data, index) => (
                                <InfoCell
                                    className={`col-sm-${checkCol(data.key)} rowName`}
                                    label={data.key}
                                    value={data.value}
                                />
                            ))}
                        </div>

                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Gross Weight</h2>
                        <div className='row'>
                            {grossWeight.map((data, index) => (
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={data.key}
                                    value={data.value}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Shipping Information</h2>
                        <div className='row'>
                            {shippingInformation.map((data, index) => (
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={data.key}
                                    value={data.value}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Transhipment Information</h2>
                        <div className='row'>
                            {transhipmentInformation.map((data, index) => (
                                <InfoCell
                                    className={`col-sm-${checkCol(data.key)} rowName`}
                                    label={data.key}
                                    value={data.value}
                                />
                            ))}
                        </div>

                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Container</h2>
                        <div className='row'>
                            {containerDocument.map((data, index) => (
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={data.key}
                                    value={data.value}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Weight</h2>
                        <div className='row'>
                            {weightOfDocument.map((data, index) => (
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={data.key}
                                    value={data.value}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Volume</h2>
                        <div className='row'>
                            {weightOfDocument.map((data, index) => (
                                <InfoCell
                                    className={`col-sm-${checkCol(data.key)} rowName`}
                                    label={data.key}
                                    value={data.value}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Cut Off</h2>
                        <div className='row'>
                            {cutoffDocument.map((data, index) => (
                                <InfoCell
                                    className={`col-sm-${checkCol(data.key)} rowName`}
                                    label={data.key}
                                    value={data.value}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

DocumentData.propTypes = {
};
