import { Modal } from "components/common"
import { InfoRow } from "../components";
import './index.scss';

export const EndorsementChainModal = ({ open, onCancel }) => {
    return (
        <Modal
            className="tr__EndorsementChainModal"
            open={open}
            title="Endorsement chain"
            onCancel={onCancel}
        >
            <div className="content">
                <div className="item_no_owner">
                    <InfoRow className="infoRow">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="f-medium">Document has been issued</p>
                                <div className="f-medium role">Owner account</div>
                                <div className="id_account">0x30c992f70fe8a96ab9e977774d5c5b7e27e7d080</div>
                                <div className="org f-medium">(supplier)</div>
                            </div>
                            <div>
                                <p className="f-medium time">14 Aug 2021, 04:21 pm</p>
                                <div className="f-medium role">Holder account</div>
                                <div className="id_account">0x30c992f70fe8a96ab9e977774d5c5b7e27e7d080</div>
                                <div className="org f-medium">(supplier)</div>
                            </div>
                        </div>
                    </InfoRow>
                    <InfoRow className="infoRow">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="f-medium">Document has been issued</p>
                                <div className="f-medium role">Owner account</div>
                                <div className="id_account">0x30c992f70fe8a96ab9e977774d5c5b7e27e7d080</div>
                                <div className="org f-medium">(supplier)</div>
                            </div>
                            <div>
                                <p className="f-medium time">14 Aug 2021, 04:21 pm</p>
                                <div className="f-medium role">Holder account</div>
                                <div className="id_account">0x30c992f70fe8a96ab9e977774d5c5b7e27e7d080</div>
                                <div className="org f-medium">(supplier)</div>
                            </div>
                        </div>
                    </InfoRow>
                </div>
            </div>

        </Modal>
    )
}