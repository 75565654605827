import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Tab,
    TabPane,
    ContainerForm,
    BLNumberForm,
    BookingNoForm,
    SearchResults,
    NoResultSearch,
    // Dropdown,
    AirwayBillForm,
    CourierNumberForm
} from 'components';
import { 
    searchParams, 
    typeShipmentOptions
} from 'utils';
import {
    TAB_BL_NUMBER,
    TAB_CONTAINER,
    TAB_BOOKING_NO,
    SHIPMENT_DETAILS,
    MARK_NEW,
    TRACKER_BOOKING_BK,
    TRACKER_BOOKING_BL,
    TRACKER_BOOKING_BC,
    OCEAN,
    AIR,
    TAB_AIRWAY_BILL,
    TAB_COURIER_NO
} from 'actions';
import { HomeContext, ModalContext, TrackShipmentContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'components/common';

export const SearchForms = ({ searched, setSearched = () => {}, searchType, typeShipment, setTypeShipment, disabledForm = false }) => {
    const { setMarkAsShipmentType, setIsApplyDataSearch, typeOfShipment } = useContext(HomeContext);
    const {
        trackers,
        firstCarriers,
        firstLocode,
        setCarriers,
        setOrigins,
        setDestinations
    } = useContext(TrackShipmentContext);
    const { noResultSearch, setNoResultSearch } = useContext(ModalContext);
    const [resultShipment, setResultShipment] = useState('');
    const [isTrackerSubmitted, setIsTrackerSubmitted] = useState(false);
    const [bkParams, setBkParams] = useState({});
    const [blParams, setBlParams] = useState({});
    const [containerParams, setContainerParams] = useState({});
    const [currentTab, setCurrentTab] = useState(0);
    const { t } = useTranslation();

    const history = useHistory();

    /* eslint-disable */
    useEffect(() => {
        if(noResultSearch) setNoResultSearch(false);
    },[])

    useEffect(() => {
        if(trackers && trackers.length > 0) { 
            setSearched(true);
        }
        if (isTrackerSubmitted && trackers.length === 0) {
            setNoResultSearch(true);
        }
    }, [isTrackerSubmitted])

    useEffect(() => {
        switch (searchType?.requestType) {
            case TRACKER_BOOKING_BK:
                setCurrentTab(0);
                setBkParams(searchType);
                break;
            case TRACKER_BOOKING_BL:
                setCurrentTab(1);
                setBlParams(searchType);
                break;
            case TRACKER_BOOKING_BC:
                setCurrentTab(2);
                setContainerParams(searchType);
                break;
            default:
                setCurrentTab(0);
                break;
        }
    })
    /* eslint-enable */

    const handleShipment = (flag) => {
        setIsTrackerSubmitted(true);
        setIsApplyDataSearch(true);
        switch (flag) {
            case TAB_CONTAINER:
                setResultShipment("Container no");
                break;
            case TAB_BL_NUMBER:
                setResultShipment("MBL no");
                break;
            case TAB_BOOKING_NO:
                setResultShipment("Booking no");
                break;
            default:    
        }
    }

    const handleSwitchTab = (tab) => {
        setCarriers(firstCarriers);
        if (tab === TAB_CONTAINER) {
            setOrigins(firstLocode);
            setDestinations(firstLocode);
        }
    }

    return (
        <div className="tr__search-forms">
            <h4 className="box-header d-flex justify-content-between align-items-center">
                <p className="h4"> {t('trackshipment.title')} </p>
                <Dropdown
                    className="tr__shipment-options"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="select"
                    name="scac"
                    options={typeShipmentOptions}
                    onChange={value => setTypeShipment(value)}
                    value={typeShipment}
                    // defaultValue={typeShipment}
                    disabled={searched || disabledForm}
                    placeholder="Choose Type Shipment"
                    dropdownPosition="center"
                />
                </h4>
           
            {searched ? (
                <SearchResults
                    searchParams={searchParams}
                    setSearched={setSearched}
                    result={resultShipment}
                    setIsTrackerSubmitted={setIsTrackerSubmitted}
                    className='result-track-shipment'
                    typeShipment={typeShipment}
                />
            ) : (
                    <>
                        {typeShipment && typeShipment.key === OCEAN && (
                            <Tab initialTab={currentTab} onTabClick={handleSwitchTab}>
                                <TabPane tabName={t("trackshipment.bookingNumber")} tabSlug={TAB_BOOKING_NO}>
                                    <BookingNoForm onSubmit={handleShipment} trackerParams={bkParams} />
                                </TabPane>
                                <TabPane tabName={t("trackshipment.BLnum")} tabSlug={TAB_BL_NUMBER}>
                                    <BLNumberForm onSubmit={handleShipment} trackerParams={blParams} />
                                </TabPane>
                                <TabPane tabName={t("trackshipment.container")} tabSlug={TAB_CONTAINER}>
                                    <ContainerForm onSubmit={handleShipment} trackerParams={containerParams} />
                                </TabPane>
                            </Tab>
                        )}
                        
                        {typeShipment && typeShipment.key === AIR && (
                            <Tab initialTab={currentTab} onTabClick={handleSwitchTab}>
                                <TabPane tabName={TAB_AIRWAY_BILL} tabSlug={TAB_AIRWAY_BILL}>
                                    <AirwayBillForm onSubmit={handleShipment}/>
                                </TabPane>
                                <TabPane tabName={TAB_COURIER_NO} tabSlug={TAB_COURIER_NO}>
                                    <CourierNumberForm onSubmit={handleShipment}/>
                                </TabPane>
                            </Tab>
                        )}
                        
                    </>
                    
                )}
            <NoResultSearch
                open={noResultSearch}
                onCancel={() => {
                    setNoResultSearch(false)
                    setIsTrackerSubmitted(false)
                }}
                title={t("trackshipment.title")}
                submitButton={true}
                submitButtonLabel={t("trackshipment.create-new-shipment")}
                submitButtonType="primary"
                onSubmitClick={() => {
                    setNoResultSearch(false);
                    setMarkAsShipmentType(MARK_NEW);
                    history.push({
                        pathname: SHIPMENT_DETAILS,
                        state: { typeOfShipment }
                    });
                }}
            />
        </div>
    )
};

SearchForms.propTypes = {
    searched: PropTypes.bool,
    setMarkers: PropTypes.array
};