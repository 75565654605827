import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
    Modal,
} from 'components';
import { HomeContext, MemberContext, ModalContext, OrganizationContext } from 'contexts';
import { MEMBER_PAGE_SIZE } from 'actions';
import { useTranslation } from 'react-i18next';

export const ConfirmApproveReuqest = ({ open = false, onCancel, onSubmit }) => {
    const [t] = useTranslation();
    const { payloadConfirm, setNotificationMessage } = useContext(HomeContext);
    const { requesterInfo, doApproveJoinRequest } = useContext(OrganizationContext);
    const { setViewRequestSetting } = useContext(ModalContext);
    const { doGetOrgMembers } = useContext(MemberContext);
    const handleApprove = () => {
        const { receiverId, requester } = requesterInfo;
        const orgId = receiverId;
        doApproveJoinRequest(receiverId, requester._id, payloadConfirm, () => {
            onCancel();
            setViewRequestSetting(false);
            setNotificationMessage(`<p> ${t("organization.message_approve_request")}</p>`)
            doGetOrgMembers({
              orgId,
              page: 0,
              limit: MEMBER_PAGE_SIZE,
              isSearching: true
            })
          }, true)
    }
    
    return (
        <Modal
            open={open}
            className="tr__confirm-approve"
            onCancel={onCancel}
            title={t("organization.change_role")}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel={t("member.yes")}
            onSubmitClick={handleApprove}
            cancelButton={true}
            cancelButtonLabel={t("member.no")}
            cancelButtonType=""
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">
                    {`${t("member.changeOwner.making")} ${requesterInfo && requesterInfo.requester?.name} ${t("member.changeOwner.messege-change-owner")}`}
                </h6>
            </div>
        </Modal>
    )
};

ConfirmApproveReuqest.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
};