import React, { useContext, useState, useEffect } from 'react';
import {
    Table,
    Dropdown,
    Button,
    Tooltip
} from 'components';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { HomeContext, ModalContext, ShipmentInfoContext, ActiveShipmentsContext } from 'contexts';
import { typeAddDocument, convertDate, } from 'utils';
import { DATE_FORMAT } from 'actions';
import { useLocation } from 'react-router-dom';


export const DocumentSignature = () => {
    const [t] = useTranslation();
    // const history = useHistory();
    const { setUploadSignatureDocuments, setPreviewSignatureDocument, uploadSignatureDocuments, setEthereumDocument, setVerifyWalletModal } = useContext(ModalContext);
    const { setIdDocumentSignature, setFileId, shipment, currentWallest, reloadDocument } = useContext(HomeContext);
    const { doGetDocumentsSignatureList } = useContext(ShipmentInfoContext);
    const { doGetAllEthereumDetail } = useContext(ActiveShipmentsContext);


    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 2];

    const [manage] = useState(false);
    const [documents, setDocuments] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        if (!uploadSignatureDocuments && currentWallest) {
            const param = {
                shipmentId: shipmentId,
                page:0,
                limit:20
            }
            doGetDocumentsSignatureList(param, (data) => {
                doGetAllEthereumDetail(data, currentWallest?.address, 'Ethereum', shipment?.orgId, (documentDetails) => {
                    const result = data?.map(item => {
                        let tradeTrust = documentDetails?.find(x => x.fileRecordId === item.documentId)?.tradeTrust;
                        if (tradeTrust) {
                            item.blockchain = tradeTrust?.network?.blockchain;
                            item.status = tradeTrust.status
                        }
                        return item;
                    });
                    setDocuments(result);
                })
            })
        }
    }, [uploadSignatureDocuments, currentWallest])

    // useEffect(() => {
    //     setVerifyWalletModal(true);
    // },[]);

    useEffect(() => {
        if (reloadDocument) {
            const arrayDoc = [...documents];
            let docIndex = arrayDoc.findIndex(x => x.documentId === reloadDocument.ret._id);
            let doc = arrayDoc.find(x => x.documentId === reloadDocument.ret._id);
            doc.blockchain = reloadDocument?.ret?.tradeTrust?.network?.blockchain;
            doc.status = reloadDocument?.ret?.tradeTrust?.status;
            arrayDoc[docIndex] = doc;
            setDocuments(arrayDoc); 
        }
    },[reloadDocument]);
    /*eslint-enable */

    const chooseTypeGenerateDoc = (type, id) => {
        setIdDocumentSignature(id);
        if (type.key === "2") {
            setEthereumDocument(true);
        }
    }

    const handleRequestSignature = (documentId, idDocumentSignature) => {
        setFileId(documentId);
        setIdDocumentSignature(idDocumentSignature);
        setPreviewSignatureDocument(true);
    }

    const columns = [
        {
            dataIndex: 'documentName,documentExtension',
            render: (documentName, documentExtension) =>
                <>
                    <div className="d-flex align-items-center justify-content-between">
                        <i className="icon-paperclip tr__document-signature--icon-name" />
                        <p>{documentName}</p>
                        <h4>{documentExtension}</h4>
                    </div>
                </>
        },
        {
            dataIndex: 'type',
            render: (type) => (
                <div>
                    <h6 className="h6 mbx1">{type}</h6>
                </div>
            )
        },
        {
            dataIndex: 'createdBy,createdDate',
            render: (createdBy, createdDate) => (
                <div>
                    <Tooltip renderContent={() => (
                        <div>
                            <p>{createdBy.name}</p>
                            <p>{createdBy.email}</p>
                            <p>{convertDate(createdDate, DATE_FORMAT)}</p>
                        </div>
                    )}>
                        <h6 className="h6 mbx1">{createdBy.name}</h6>
                        <p>{convertDate(createdDate, DATE_FORMAT)}</p>
                    </Tooltip>
                </div>
            )
        },
        {
            dataIndex: 'status,blockchain',
            render: (status, blockchain) => (
                <>
                    <div className="flex">
                        <div className={`h6 text-capitalize ${status}`} >{status}</div>
                        <div className={`h6 text-capitalize ${blockchain}`}>{blockchain}</div>
                    </div>
                </>
            )
        },
        {
            dataIndex: 'documentId,_id',
            render: (documentId, _id) => (
                <>
                    <Button
                        type="secondary"
                        className="outline icon mrx1 tr__document-signature--btn-download"
                        onClick={() => handleRequestSignature(documentId, _id)}
                    >
                        Request signature
                    </Button>
                    <Button
                        type="secondary"
                        className="outline icon mrx1 tr__document-signature--btn-download"
                        icon="icon-download"
                    >
                    </Button>
                    <div className="col-12 col-sm-5">
                        {!manage ? <Dropdown
                            className="select icon tr__document-signature--select-generate"
                            icon="icon-chevron-down"
                            iconPosition="right"
                            mode="select"
                            name="scac"
                            placeholder="Generate TT"
                            options={typeAddDocument.map((l) => {
                                return {
                                    ...l,
                                    value: l.value
                                }
                            })}
                            onChange={value => chooseTypeGenerateDoc(value, documentId)} /> : <Button
                                type="secondary"
                                className="outline"
                                onClick={showDetails}
                            >
                            Manage</Button>}
                    </div>
                </>
            )
        }
    ]

    const showDetails = (id) => {
        setVerifyWalletModal(true);
    }

    const handleUploadDocument = (type) => {
        setUploadSignatureDocuments(true);
    }

    return (
        <div className="tr__document-signature">
            <div className="tr__document-signature--title d-flex align-items-center justify-content-between">
                <h2 className="h2 f-medium">{t("home.active-shipment.details.tabs.document-signature")}</h2>
                <div className="tr__document-signature--btn-add-doc">
                    <Button
                        type="secondary"
                        className="outline"
                        onClick={handleUploadDocument}
                    >Upload document
                    </Button>
                </div>
            </div>
            <div className="tr__document-signature--content" id="document-signature">
                <Table
                    className="document-signature"
                    columns={columns}
                    dataSource={documents}
                >
                    <div className="tr__document-signature--table-header flex align-items-center justify-content-between table-header">
                        <div className="column name">
                            <p>{t("home.active-shipment.details.tabs.signatures.name")}</p>
                        </div>
                        <div className="column type">
                            <p>{t("home.active-shipment.details.tabs.signatures.type")}</p>
                        </div>
                        <div className="column members">
                            <p>{t("home.active-shipment.details.tabs.signatures.members")}</p>
                        </div>
                        <div className="column status">
                            <p>{t("home.active-shipment.details.tabs.signatures.status")}</p>
                        </div>
                        <div className="column actions">
                            <p>{t("home.active-shipment.details.tabs.signatures.actions")}</p>
                        </div>
                    </div>
                </Table>
            </div>
        </div>
    )
}
