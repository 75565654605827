import { Dropdown, Input, Modal } from "components/common";
import { useState } from "react";
import { useForm } from "react-hook-form";
import './index.scss';

export const NominateOwnerShip = ({open, onCancel}) => {
    const { handleSubmit } = useForm();
    const [nominateAccount] = useState([{key:1, value: "Hanh", address: "0x42f4D648b4731B5CDE8dddD87BaD2B9B3854fB8b"}]);
    const [accountSelect, setAccountSelect] = useState();
    const [loading, setLoading] = useState(false);

    const hanldSelectOption = (option) => {
        setAccountSelect(option);
    }

    const handlSubmit = () => {
        console.log("Hanh Nguyen");
    }

    const hanldSearch = (value) => {
        setLoading(true);
        console.log(value);
    }

    return (
        <Modal
            open={open}
            title="Nominate OwnerShip"
            className="tr__Nominate_OwnerShip"
            onCancel={onCancel}
            submitButton
            submitButtonLabel="Confirm"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(handlSubmit)}>
                    {children}
                </form>
            )}
        >
            <Dropdown
                label="Nominate account *"
                options={nominateAccount.map(type => {
                    return {
                        key: type.key,
                        value: (type.value),
                        address: type.address
                    }
                })}
                placeholder="Select account"
                iconPosition = 'right'
                icon='icon-chevron-down'
                defaultValue=''
                onChange={option => hanldSelectOption(option)}
                searchLoading={loading}
                onInputChange={hanldSearch}
                mode="input"
            />
            <Input
                label="Nominate Account address *"
                placeholder="Nominate Account address *"
                disabled={true}
                defaultValue={accountSelect ? accountSelect.address : "string" }
                className="input_value_default"
            />

        </Modal>
    )
}