import { Table, Modal } from "components/common";
import { TrackShipmentContext } from "contexts";
import { useContext } from "react";
import './index.scss';

export const PortInfoModal = ({open, onCancel}) => {
    const { trackerEvent } = useContext(TrackShipmentContext);
    const columns = [
        {
            dataIndex: 'MMSI',
            render: MMSI => (
                <p>{MMSI}</p>
            )
        },
        {
            dataIndex: 'PORT',
            render: PORT => (
                <p>{PORT}</p>
            )
        },
		{
			dataIndex: 'COUNTRY',
			render: COUNTRY => (
                <p>{COUNTRY}</p>
            )
        },
        {
			dataIndex: 'TIMESTAMP',
			render: TIMESTAMP => (
                <p>{TIMESTAMP}</p>
            )
        },
        {
			dataIndex: 'LOCODE',
			render: LOCODE => (
                <p>{LOCODE}</p>
            )
        },
        {
			dataIndex: 'IMO',
			render: IMO => (
                <p>{IMO}</p>
            )
        },
        {
			dataIndex: 'EVENT',
			render: EVENT => (
                <p>{EVENT}</p>
            )
        }
    ];
   
    return (
        <Modal
            open={open}
            title="Port Info"
            className="tr__port-info"
            onCancel={onCancel}
        >
             <Table
                    className="shipment-logs"
                    dataSource={trackerEvent?.portInfo}
                    columns={columns}
                >
                   <div className="tr__shipment-log--table-header flex align-items-center justify-content-between table-header">
                        <div className="column">
                            <p>Mmsi</p>
                        </div>
                        <div className="column">
                            <p>Port</p>
                        </div>
                        <div className="column">
                            <p>Country</p>
                        </div>
                        <div className="column">
                            <p>Timestamp</p>
                        </div>
                        <div className="column">
                            <p>Locode</p>
                        </div>
                        <div className="column">
                            <p>Imo</p>
                        </div>
                        <div className="column">
                            <p>Event</p>
                        </div>
                    </div>
                </Table>
        </Modal>
    )
}