import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown } from 'components';
import './index.scss';
import { DocumentData } from '../DocumentData';
import { InfoCell } from '../InfoCell';
import { typeAssetDocument } from 'utils';
import { ModalContext } from 'contexts';

export const DocumentSignatureDetails = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const { setDirectEndorsement, setTransferModal, setVerifyWalletModal, setEndorsementChainModal, setNominateOwnerShip, setEndorseOwnership } = useContext(ModalContext);
    const handleGoBack = () => {
        history.goBack()
    }

    const chooseTypeGenerateDoc = (value) => {
        console.log(value);
        switch (value.key) {
            case 1:
                setDirectEndorsement(true);
                break;
            case 2:
                console.log(value);
                break;
            case 3:
                setTransferModal(true);
                break;
            case 4:
                setNominateOwnerShip(true);
                break;
            case 5:
                setEndorseOwnership(true);
                break;
            default:
                break;
        }
    }

    const hanldDowload = () => {

    }

    const hanldChangeWallet = () => {
        setVerifyWalletModal(true);
    }

    const hanldMoveToCorda = () => {

    }

    const hanldViewTradeTrust = () => {

    }

    const hanldViewRegistry = () => {

    }

    const hanldViewEndosementChain  = () => {
        setEndorsementChainModal(true);
    }

    return (
        <div className="tr__shipment-doc">
            <div className="tr__shipment-doc--header d-flex align-items-center justify-content-between">
                <p className="tr__link tag mrx2" onClick={handleGoBack}>
                    <i className="icon-chevron-left" />
                    <span>{t("home.active-shipment.details.tabs.issues-tab.back")}</span>
                </p>
                <div className="doc-actions d-flex align-items-center">
                    <Button
                        className="outline"
                        type="secondary"
                        onClick={() => hanldDowload()}
                    >
                        {t("Download")}
                    </Button>
                    <Button
                        className="outline"
                        type="secondary"
                        onClick={() => hanldChangeWallet()}
                    >
                        {t("Change wallet")}
                    </Button>
                    <Button
                        className="outline"
                        type="secondary"
                        onClick={() => hanldMoveToCorda()}
                    >
                        {t("Move to Corda")}
                    </Button>
                    <Button
                        className="outline"
                        type="secondary"
                        onClick={() => hanldViewTradeTrust()}
                    >
                        {t("View at TradeTrust")}
                    </Button>
                </div>
            </div>
            <div className="tr__shipment-doc--dropdown-assets">
                <Dropdown
                    className="select icon manage-assets"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="select"
                    name="scac"
                    placeholder="Manage Assets"
                    options={typeAssetDocument.map((l) => {
                        return {
                            ...l,
                            value: l.value
                        }
                    })}
                    onChange={value => chooseTypeGenerateDoc(value)}
                />
                <div className="view-endosementchain">
                    <Button className="outline" onClick={() => hanldViewRegistry()}>View BL Registry</Button>
                    <Button className="outline" onClick={() => hanldViewEndosementChain()}>View Endorsement chain</Button>
                </div>
            </div>
            <div className="tr__shipment-doc--body d-flex align-items-center justify-content-between">
                <DocumentData />
                <div className="doc--comment">
                    <div className="jss1922">
                        <h2 className="h2 f-medium">File details</h2>
                        <div className="col-12">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"File name"}
                                    value={"master-bill-of-lading_ab1d3379-46be-4653-b776-eb69bc559405.pdf"}
                                />
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"Version"}
                                    value={"https://schema.openattestation.com/2.0/schema.json"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Issuers</h2>
                        <div className="col-12">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"Name"}
                                    value={"Bonnie_"}
                                />
                                <InfoCell
                                    className="col-sm-6"
                                    label={"Type"}
                                    value={"DNS-TXT"}
                                />
                                <InfoCell
                                    className="col-sm-6"
                                    label={"Location"}
                                    value={"tt-verify.blockchain.trames-engineering.com"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Owner</h2>
                        <div className="col-12">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"Account address"}
                                    value={"0x2b5e5966e5d799c149ba1a8610817134a2813522 (buyer test account)"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Holder</h2>
                        <div className="col-12">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"Account address"}
                                    value={"0x30c992f70fe8a96ab9e977774d5c5b7e27e7d080 (supplier)"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="jss1921">
                        <h2 className="h2 f-medium">Signature</h2>
                        <div className="col-12">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"Type"}
                                    value={"SHA3MerkleProof"}
                                />
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"Target hash"}
                                    value={"83948edf58a71844cf03187be04a09d44ed331ed57fe77f927f56159f195c955"}
                                />
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"Merkie Root"}
                                    value={"83948edf58a71844cf03187be04a09d44ed331ed57fe77f927f56159f195c955"}
                                />
                                <InfoCell
                                    className="col-sm-12 rowName"
                                    label={"TT hash"}
                                    value={"a45304a66bf3bf19e1937ae6a4741d2d3cb4c2f6"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

DocumentSignatureDetails.propTypes = {
};
