import { Dropdown, Input, Modal } from "components/common";
import { useState } from "react";
import { useForm } from "react-hook-form";
import './index.scss';


export const DirectEndorsement = ({open, onCancel}) => {
    const { handleSubmit } = useForm();
    const [loadingOwner] = useState(false);
    const [loadingHolder] = useState(false);
    const [account] = useState([{key:1, value:"Hanh", address:"0x42f4D648b4731B5CDE8dddD87BaD2B9B3854fB8a"}, {key: 2, value: "Nguyen", address:"0x42f4D648b4731B5CDE8dddD87BaD2B9B3854fB8b"}]);
    const [ownerSelect, setOwnerSelect] = useState();
    const [holderSelect, setHolderSelect] = useState();

    const handlDirectEndorsement = () => {
        console.log("Hanh Nguyen");
    }

    const handleSearchOwner = () => {

    }

    const handlSelectOwner = (option) => {
        console.log(option);
        setOwnerSelect(option);
    }

    const handleSearchHolder = () => {

    }

    const handlSelectHolder = (option) => {
        setHolderSelect(option);
    }

    return (
        <Modal
            className="tr__Modal_DirectEndorsement"
            open={open}
            title="Direct endorsement"
            onCancel={onCancel}
            submitButton
            submitButtonLabel="Confirm"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(handlDirectEndorsement)}>
                    {children}
                </form>
            )}
        >
            <Dropdown
                className="select"
                icon="icon-chevron-down"
                iconPosition="right"
                mode="input"
                name="owner's account"
                label={"Select owner's account *"}
                options={account.map(type => {
                    return {
                        key: type.key,
                        value: (type.value),
                        address: type.address
                    }
                })}
                onChange={option => handlSelectOwner(option)}
                onInputChange={handleSearchOwner}
                placeholder="Select owner's account"
                dropdownPosition="center"
                searchLoading={loadingOwner}
                defaultValue={ownerSelect ? ownerSelect : account[0]}
            />
            <Input
                label="Owner account address *"
                placeholder="Owner account address"
                disabled={true}
                defaultValue={ownerSelect? ownerSelect?.address : account[0].address}
                className="input_value_address"
            />
            <Dropdown
                className="select_holder"
                icon="icon-chevron-down"
                iconPosition="right"
                mode="input"
                name="owner's account"
                label={"Select owner's account *"}
                options={account.map(type => {
                    return {
                        key: type.key,
                        value: (type.value),
                        address: type.address
                    }
                })}
                onChange={option => handlSelectHolder(option)}
                onInputChange={handleSearchHolder}
                placeholder="Select owner's account"
                dropdownPosition="center"
                searchLoading={loadingHolder}
                defaultValue={holderSelect ? holderSelect : account[1]}
            />
            <Input
                label="Owner account address *"
                placeholder="Owner account address"
                disabled={true}
                defaultValue={holderSelect? holderSelect?.address : account[1].address}
                className="input_value_address"
            />
        </Modal>
    )
}