import React, { useContext } from 'react'

import './index.scss';
import moment from 'moment';
import { ModalContext } from 'contexts';
export const LatestEvent = ({ trackerEvent = {} }) => {
    const { setPortInfo } = useContext(ModalContext);
    return (
        <div className="tr__latest-event">
            <div className="tr__latest-event--title d-flex justify-content-between">
                <div className="h4 f-medium">
                    Latest Events
                </div>
                <div>
                    <p>Planned Transit Time: <span className="f-medium">{trackerEvent?.plannedTransitTime} day{trackerEvent?.plannedTransitTime > 1 ? 's' : ''}</span></p>
                    <p>Carrier Transit Time: <span className="f-medium">{trackerEvent?.carrierTransitTime} day{trackerEvent?.plannedTransitTime > 1 ? 's' : ''} (Actual)</span></p>
                </div>
            </div>
            
            <div className="step d-flex">
                <div className='step--icon'>
                    <i className="icon-anchor" />
                </div>
                <div className="step--info">
                <h6 className="h6 mtx05 mbx3 f-medium">{trackerEvent?.pol?.state ? `${trackerEvent?.pol?.state},`: ''}{trackerEvent?.pol?.country} {trackerEvent?.pol?.locode ? `(${trackerEvent?.pol?.locode})` : '-'} </h6>
                    <div className="step--info__times mbx2">
                        <div >
                            <div >
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <h6 className="h6 f-medium">Carrier: -</h6>
                                    <h6 className="h6 f-medium" >-</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <h6 className="h6 f-medium">Vessel: {trackerEvent?.vesselInfo?.name}</h6>
                                    <h6 className="h6 f-medium" >Voyage:-</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <h6 className="h6 f-medium">Arrival</h6>
                                    <h6 className="h6 f-medium">-</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-calendar mr-2"/>
                                        <h6 className="f-medium">-</h6>
                                    </div>
                                    <h6 className="h6 f-medium" >Estimated</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-plane mr-2"/>
                                        {trackerEvent?.pol?.vesselDeparture ? '' : <i className="icon-warning mr-2" onClick={() => {setPortInfo(true)}} /> } 
                                        <h6 className="h6 f-medium">{trackerEvent?.pol?.vesselDeparture ? trackerEvent?.pol?.vesselDeparture : 'N/A' }</h6>
                                    </div>
                                    <h6 className="h6 f-medium" >Actual</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-ship mr-2"/>
                                        <h6 className="h6 f-medium">-</h6>
                                    </div>
                                    <h6 className="f-medium" >{trackerEvent?.status === false ? 'Estimated' : 'Actual' }</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="step d-flex">
                <div className='step--icon'>
                    <i className="icon-ship" />
                </div>
                <div className="step--info">
                    <div className="step--info__times mbx2">
                        <div >
                            <p className="f-medium mtbx1">Departure</p>
                            <div >
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-calendar mr-2"/>
                                        <h6 className="f-medium">{moment(trackerEvent?.etd).format('DD MMM YYYY | hh:mm A')}</h6>
                                    </div>
                                    <h6 className="h6 f-medium" >Estimated</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-plane mr-2"/>
                                        {trackerEvent?.pol?.vesselArriver ? '' : <i className="icon-warning mr-2" onClick={() => {setPortInfo(true)}}/> } 
                                        <h6 className="h6 f-medium">{trackerEvent?.pol?.vesselArriver ? trackerEvent?.pol?.vesselArriver : 'N/A' }</h6>
                                    </div>
                                    <h6 className="h6 f-medium" >Actual</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-ship mr-2"/>
                                        <h6 className="f-medium">{moment(trackerEvent?.pol?.originDeparture).format('DD MMM YYYY | hh:mm A')}</h6>
                                    </div>
                                    <h6 className="f-medium" >{trackerEvent?.status === false ? 'Estimated' : 'Actual' }</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="step d-flex">
                <div className='step--icon'>
                    <i className="icon-anchor" />
                </div>
                <div className="step--info">
                    <h6 className="h6 mtx05 mbx3 f-medium">{trackerEvent?.pod?.state ? `${trackerEvent?.pod?.state},`: ''}{trackerEvent?.pod?.country} {trackerEvent?.pod?.locode ? `(${trackerEvent?.pod?.locode})` : '-'} </h6>
                    <div className="step--info__times mbx2">
                        <div >
                            <p className="f-medium mtbx1" >Arrival</p>
                            <div >
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-calendar mr-2"/>
                                        <h6 className="f-medium">{moment(trackerEvent?.eta).format('DD MMM YYYY | hh:mm A')}</h6>
                                    </div>
                                    <h6 className="h6 f-medium" >Estimated</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-plane mr-2"/>
                                        <h6 className="f-medium">N/A</h6>
                                    </div>
                                    <h6 className="h6 f-medium" >-</h6>
                                </div>
                                <div className="step--info__times--item d-flex align-center justify-content-between" >
                                    <div className="d-flex">
                                        <i className="icon-ship mr-2"/>
                                        <h6 className="f-medium">{moment(trackerEvent?.pol?.originArriver).format('DD MMM YYYY | hh:mm A')}</h6>
                                    </div>
                                    <h6 className="h6 f-medium">Actual</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}