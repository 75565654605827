import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import {
    Modal,
} from 'components';
import { Dropdown, FileUploader } from 'components/common';
import { HomeContext, ModalContext, ActiveShipmentsContext } from 'contexts';
import {
    documentsSubTypes,
    FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS,
    MAX_SIZE_SHIPMENT_DETAIL_DOC
} from 'actions';
import { useLocation } from 'react-router-dom';

export const UploadSignatureDocuments = ({
    open = false,
    onCancel,
}) => {
    const [files, setFiles] = useState([]);
    const { setUploadSignatureDocuments } = useContext(ModalContext);
    const { addNewDocument, setUrlFile } = useContext(HomeContext);
    const { doCreateDocumentSignature } = useContext(ActiveShipmentsContext);

    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 2];


    /*eslint-disable */
    useEffect(() => {
        if (!addNewDocument) {
            setFiles([]);
        }
    }, [addNewDocument])
    /*eslint-enable */

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        setUrlFile(uploadFiles[0].url)
        for (var i = 0; i < uploadFiles.length; i++) {
            if (!uploadFiles[i].name) return
            fileList.push(uploadFiles[i])
        }
        setFiles(oldFiles => [...oldFiles, ...fileList]);
    }

    const handleFileRemove = file => {
        if (!file) return;
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }
    const handleCloseModal = () => {
        onCancel();
        setFiles([]);
    }
    const handleSubmit = () => {
        // create document-signature
        doCreateDocumentSignature({
            documentId: files[0]?.id,
            documentName: files[0].fileName,
            documentExtension: files[0].fileExtension,
            type: files[0].type,
            subType: files[0].subType,
            shipmentId
        }, data => {
            if (data._id) {
                setUploadSignatureDocuments(false);
            }
        })
    }

    return (
        <Modal
            open={open}
            className="tr__upload-document-modal"
            onCancel={handleCloseModal}
            title={"Upload document"}
            onCancelClick={handleCloseModal}
            isBackDropClickable={false}
            cancelButton={true}
            cancelButtonClassNames="outline"
            cancelButtonType="primary"
            submitButton={true}
            submitButtonLabel={"Save"}
            onSubmitClick={handleSubmit}
            btnClasses={'justify-content-between'}
        >
            <div className="tr__view-documents page-box">
                <FileUploader
                    handleDrop={handleFileUpload}
                    handleRemove={handleFileRemove}
                    uploadedFiles={files}
                    limitNumberUploadFile={9}
                    outputFormat='base64'
                    name="uploadDocuments"
                    mode="list"
                    limitSizePerFile={MAX_SIZE_SHIPMENT_DETAIL_DOC}
                    fileTypeApproved={['image', 'pdf']}
                    uploadInner={true}
                    uploadFileType={'shipping-document'}
                    uploadFileSubType={FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS}
                // ocrRequired={true}
                >
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destination"
                        options={documentsSubTypes.map(l => {
                            return {
                                ...l,
                                value: l.value
                            }
                        })}
                        dropdownPosition="right"
                        placeholder="select type of document"
                    // onChange={value => changeLanguage(null, value.code)}
                    >
                        <span className="mr-2">Select Type of documents</span>
                        <i className="icon-chevron-down" />
                    </Dropdown>
                </FileUploader>
            </div>
        </Modal>
    )
}
