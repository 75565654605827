import axios from 'axios';
import { authHeaders, Header, HeaderDocumentDetail } from './auth';
import {
    ACTIVE_SHIPMENT_DOCUMENTS,
    ACTIVE_SHIPMENT_DOCUMENTS_TASK,
    ACTIVE_SHIPMENT_DOCUMENTS_REMOVE_TASK,
    ACTIVE_SHIPMENT_DOCUMENTS_REMOVE_DEFAULT,
    GENERATE_TRADE_TRUST,
    GET_TRADE_TRUST,
    GET_DOCUMENT_TASK_DEFAULT,
    ACTIVE_SHIPMENT_DOCUMENTS_DEFAULT_TASK,
    ETHEREUM,
    ETHEREUM_DETAIL
} from './constants';

/** Get all documents
 * @param {
 * 		page: number
 * } params
 */

export const getDocuments = (shipmentId, params, token) => {
    if (!params) return;
    return axios.get(ACTIVE_SHIPMENT_DOCUMENTS.replace(':shipmentId', shipmentId), params ? {
        params,
        ...authHeaders(token)
    } : authHeaders(token));
}

/** remove documents of a task
 * @param {
 * 		ids: []
 * } payload
 */
export const removeDocumentsTask = (taskId, payload, token) => {
    if (!payload) return;
    return axios.delete(ACTIVE_SHIPMENT_DOCUMENTS_REMOVE_TASK.replace(':taskId', taskId), {
        data: payload,
        ...authHeaders(token)
    });
}

export const removeDocumentsDefault = (taskId, payload, token) => {
    if (!payload) return;
    return axios.delete(ACTIVE_SHIPMENT_DOCUMENTS_REMOVE_DEFAULT.replace(':taskId', taskId), {
        data: payload,
        ...authHeaders(token)
    });
}

/** upload documents of a task
 * @param {
 * 		ids: []
 * } payload
 */
export const uploadDocumentsTask = (taskId, payload, token) => {
    if (!payload) return;
    return axios.put(ACTIVE_SHIPMENT_DOCUMENTS_TASK.replace(':taskId', taskId), payload, authHeaders(token));
}

/** upload documents of a task in task daskboard
* @param {
* 		ids: []
* } payload
*/
export const uploadTaskDashboard = (taskId, payload, token) => {
    if (!payload) return;
    return axios.post(ACTIVE_SHIPMENT_DOCUMENTS_TASK.replace(':taskId', taskId), payload, authHeaders(token));
}

/** generate tradeTrust
    */
export const generateTradeTrust = (taskId, fileName, shipmentId, token) => {
    return axios.post(`${GENERATE_TRADE_TRUST.replace(":taskId", taskId)}?fileName=${fileName}&shipmentId=${shipmentId}`, null, authHeaders(token));
}


/** get tradeTrust
    */
export const getTradeTrust = (taskId, ids, token) => {
    return axios.post(`${GET_TRADE_TRUST.replace(":taskId", taskId)}`, ids, authHeaders(token));
}


/** get document task default
    */
 export const getDocumentTaskDefault = (shipmentId, token) => {
    return axios.get(`${GET_DOCUMENT_TASK_DEFAULT.replace(":shipmentId", shipmentId)}`, authHeaders(token));
}

/** upload documents of a task default
* @param {
* 		ids: []
* } payload
*/
export const uploadDocumentTaskDefault = (taskId, payload, token) => {
    if (!payload) return;
    return axios.put(ACTIVE_SHIPMENT_DOCUMENTS_DEFAULT_TASK.replace(':taskId', taskId), payload, authHeaders(token));
}

export const generateDocument = (documentId, payload, partyAccount, type, token) => {
    return axios.post(ETHEREUM.replace(':documentId', documentId), payload, Header(partyAccount, type, token));
}

export const generateDocumentDetail = (documentId, partyAccount, type, orgId, token) => {
    return axios.get(ETHEREUM_DETAIL.replace(':documentId', documentId), HeaderDocumentDetail(partyAccount, type, orgId, token));
}
