import { Button } from 'components/common';
import React from 'react';
import { EditShipmentInfoInput } from '../EditShipmentInfoInput';
import { dataDocument } from 'utils';
import './index.scss';

export const EditDocumentData = ({ goBack }) => {
    return (
        <div className="doc--content">
            <div class="tr__document-details--title d-flex align-items-center justify-content-between">
                <h2 className="h2 f-medium">Document Data</h2>
                <div className="doc-actions d-flex align-items-center">
                    <Button
                        className="outline"
                        type="secondary"
                        onClick={goBack}
                    >
                        {"Cancel"}
                    </Button>
                    <Button
                        className="outline"
                        type="secondary"
                    >
                        {"Save"}
                    </Button>
                </div>
            </div>
            <div className="document-content">
                <div className='row'>
                {dataDocument.map((data, index) => (
                    <div className="col-12 col-sm-6" key={index}>
                        <EditShipmentInfoInput
                            className="mtx2"
                            name="bookingConfirmation"
                            label={data.key}
                            placeholder={data.value}
                            maxLength={50}
                            newValue={"a"}
                        />
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

EditDocumentData.propTypes = {
};
