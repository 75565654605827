import React, {createContext, useContext, useState} from 'react';

import {
    getBase64FromUrl,
    getUploadSignedURL,
    uploadPreSignedFile,
    getUploadedSignedFileUrl, OPERATION_FAILED_MESSAGE,
} from 'actions';
import { HomeContext } from "./HomeContext";
import { getToken } from 'utils';

export const UploadFileContext = createContext();

export const UploadFileContextProvider = ({ children }) => {
    const {
        setLoading,
        setNotificationMessage,
        setNotificationType,
    } = useContext(HomeContext);

    const [fileData, setFileData] = useState({});

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doUploadFile = async (params, file, loggedInUser) => {
        setLoading(true);

        try {
            const uploadSignedUrl = await getUploadSignedURL(params, loggedInUser);
            await uploadPreSignedFile(uploadSignedUrl.data.itemURI, uploadSignedUrl.data.method, file);
            const uploadedSignedFileUrl = await getUploadedSignedFileUrl(uploadSignedUrl.data.id, loggedInUser);
            return {
                id: uploadSignedUrl.data.id,
                name: `${uploadSignedUrl.config.params.fileName}.${uploadSignedUrl.config.params.fileExtension}`,
                url: uploadedSignedFileUrl.data.ret.downloadSignedURI.itemURI,
            }
        } catch (error) {
            handleException(error);
        }
    }

    const doGetFileData = async (docId, callback) => {
        try {
            setLoading(true);

            const responseUrl = await getUploadedSignedFileUrl(docId, getToken());
            if (responseUrl.status === 200) {
                const base64 = await getBase64FromUrl(responseUrl.data?.ret?.downloadSignedURI?.itemURI);
                let data = {};
                if (responseUrl.data?.ret?.fileExtension === 'pdf') {
                    data = {
                        contentType: 'application/pdf',
                        base64Url: `data:application/pdf;base64,${base64}`,
                        base64: base64,
                        ...responseUrl.data?.ret
                    };
                } else {
                    data = {
                        contentType: `image/${responseUrl.data?.ret?.fileExtension}`,
                        base64Url: `data:image/${responseUrl.data?.ret?.fileExtension};base64,${base64}`,
                        base64: base64,
                        ...responseUrl.data?.ret
                    };
                }
                setFileData(data);
                if (callback)
                    callback(data);
            }
        } catch (error) {
        }
        setLoading(false);
    }

    return(
        <UploadFileContext.Provider
            value={{
                fileData,
                setFileData,
                doUploadFile,
                doGetFileData
            }}
        >
            { children }
        </UploadFileContext.Provider>
    );
}
